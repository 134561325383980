import ArrowDown from "Icons/ArrowDown"
import { FC, useCallback } from "react"
import { twMerge } from "tailwind-merge"

export const TableExpand: FC<{
	isHeader?: boolean
	onToggleExpanded: () => void
	isExpanded: boolean
}> = ({ isHeader = false, onToggleExpanded, isExpanded }) => {
	const toggleOpen = useCallback(
		(e: React.MouseEvent) => {
			e.stopPropagation()
			e.preventDefault()
			onToggleExpanded()
		},
		[onToggleExpanded]
	)

	return (
		<button className={twMerge(isHeader && "p-[2px] mt-1")} onClick={toggleOpen}>
			<ArrowDown className={twMerge("stroke-2", isExpanded && "rotate-180")} />
		</button>
	)
}
