import { Popover } from "@headlessui/react"
import ArrowDown from "Icons/ArrowDown"
import { LoadingIndicator } from "Icons/loadingIndicator"
import classNames from "classnames"
import { CLIPPED_BUTTON_STYLE } from "constants/general"
import { useCallback, useMemo } from "react"
import { twMerge } from "tailwind-merge"
import { useTrans } from "translations"
import { useConfirmationDialog } from "./Overlays/ConfirmationDialog"
import { useConfig } from "api/hooks/useConfig"

type Props = {
	nrSelected: number
	nrTotal?: number
	areAllSelected?: boolean
	isLoading?: boolean
	disabled?: boolean
	disabledText?: string
	exportExcelFunction: () => void
	exportCsvFunction: () => void
	showConfirmationDialog?: boolean
}

export const ExportButton = ({
	nrSelected,
	nrTotal,
	areAllSelected,
	isLoading,
	disabled,
	disabledText,
	exportExcelFunction,
	exportCsvFunction,
	showConfirmationDialog,
}: Props) => {
	const { t } = useTrans()
	const { isMWM } = useConfig()
	const { openConfirmationDialog } = useConfirmationDialog()

	const buttonText = useMemo(() => {
		if (!nrSelected || areAllSelected) {
			return t("actions:exportAll")
		}

		if (nrSelected === 1) {
			return t("actions:exportRow", { nrRows: nrSelected })
		}

		if (nrSelected) {
			return t("actions:exportRows", { nrRows: nrSelected })
		}
	}, [nrSelected, areAllSelected, t])

	const handleOptionClick = useCallback(
		(close: () => void, onClickFunction: () => void) => {
			const exportFunction = () => {
				close()
				onClickFunction()
			}

			if (showConfirmationDialog) {
				openConfirmationDialog(
					t("exportLabels:slowExportWarningTitle"),
					exportFunction,
					t("exportLabels:slowExportWarningDescription", {
						timespan: t(isMWM ? "periods:month" : "periods:year"),
					})
				)
			} else {
				exportFunction()
			}
		},
		[showConfirmationDialog, openConfirmationDialog, isMWM, t]
	)

	const options = useMemo(
		() => [
			{
				key: "excel",
				label: t("actions:exportExcel"),
				onClickFunction: exportExcelFunction,
			},
			{
				key: "csv",
				label: t("actions:exportCsv"),
				onClickFunction: exportCsvFunction,
			},
		],
		[exportExcelFunction, exportCsvFunction, t]
	)

	const inactive = useMemo(() => isLoading || disabled, [isLoading, disabled])

	return (
		<Popover className="relative">
			{({ open, close }) => (
				<>
					<Popover.Button
						className={twMerge(
							"w-fit bg-lime flex h-9 items-center px-8 text-sm gap-2",
							CLIPPED_BUTTON_STYLE,
							disabled && "bg-grey1"
						)}
						disabled={inactive}
					>
						{isLoading && <LoadingIndicator />}
						{!isLoading && disabled ? disabledText : buttonText}
						{!inactive && (
							<div className="ml-4">
								<ArrowDown className={classNames(open && "rotate-180 transition-transform")} />
							</div>
						)}
					</Popover.Button>
					<Popover.Panel className="absolute z-10 right-0">
						<div className="bg-white border shadow-md min-w-[150px]">
							{options.map(el => (
								<button
									key={el.key}
									className="h-12 flex items-center px-4 text-sm hover:bg-grey1 w-full"
									onClick={() => handleOptionClick(close, el.onClickFunction)}
								>
									{el.label}
								</button>
							))}
						</div>
					</Popover.Panel>
				</>
			)}
		</Popover>
	)
}
