import useCustomers from "api/hooks/useCustomers"
import ErrorNotice from "components/ErrorNotice"
import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import isEmpty from "lodash/isEmpty"
import { useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { CreateTenantFormValues } from "../../types"
import { useSlackNotifications } from "api/hooks/useSlackNotifications"
import { CreateSingleTenant } from "./CreateSingleTenant"
import { useGlobalAlert } from "States/globalAlert"
import { useNavigate } from "Utils/useNavigate"
import { useAccessParentsWithPoints } from "pages/infrastructure/manage/useAccessParentsWithPoints"

export const CreateTenantModal = () => {
	const navigate = useNavigate()
	const { hideModal } = useModal()
	const { accessParents, isLoading: isLoadingAccessParents } = useAccessParentsWithPoints()
	const {
		refetchCustomers,
		createCustomer,
		updateCustomer,
		isSavingCustomer,
		isUpdatingCustomer,
		isLoadingCustomers,
		isError,
	} = useCustomers()
	const { sendNewCustomerSlackNotification } = useSlackNotifications()
	const { setGlobalAlert } = useGlobalAlert()

	const [isSubmitAttempted, setIsSubmitAttempted] = useState(false)

	const isLoading = isLoadingAccessParents || isSavingCustomer

	const formHandler = useForm<CreateTenantFormValues>({
		defaultValues: { contactPeople: [{}] },
	})

	const {
		handleSubmit,
		formState: { errors, isValid, isDirty },
	} = formHandler

	const setupCustomer = (CustomerData: CreateTenantFormValues) => {
		const { contactPeople, ...customer } = CustomerData
		const accessParentIds = accessParents.map(el => ({ id: el.id })) // Filter out empty accessParents

		if (contactPeople && contactPeople.length > 0) {
			const firstContactPerson = contactPeople[0]
			const otherContactPeople = contactPeople.slice(1)

			const updatedCustomer = {
				...customer,
				contactPerson: firstContactPerson.name,
				contactMobile: firstContactPerson.phone,
				contactEmail: firstContactPerson.email,
			}

			createCustomer(
				{
					customer: {
						...updatedCustomer,
						accessParents: accessParentIds,
						contactPeople: JSON.stringify(otherContactPeople),
					},
				},
				{
					onSuccess: createdCustomer => {
						new Promise((resolve, reject) => {
							if (customer.businessChainId)
								// TODO: on error customer is still created, but cannot see if chain assignation failed (consider changing the core API to support adding businessChainId on createCustomer)
								updateCustomer(
									{
										customer: {
											id: createdCustomer.id,
											businessChainId: customer.businessChainId,
										},
									},
									{ onSuccess: resolve, onError: reject }
								)
							else resolve(undefined)
						}).finally(() =>
							refetchCustomers().then(() => {
								hideModal()
								setGlobalAlert({
									type: "success",
									message: "systemMessages:tenantAdded",
								})

								sendNewCustomerSlackNotification(createdCustomer.name, createdCustomer.id)
								navigate(`/customer/manage/${createdCustomer.id}`)
							})
						)
					},
					onError: () => {
						setGlobalAlert({
							type: "error",
							message: "errors:failedSave",
						})
					},
				}
			)
		}
	}

	const onSubmit = handleSubmit(customerWithAccessParents => {
		setupCustomer(customerWithAccessParents)
	})
	const submitDisabled = useMemo(
		() => isError || (isSubmitAttempted && (!isEmpty(errors) || !isValid)),
		[isError, isSubmitAttempted, errors, isValid]
	)

	return (
		<ModalContainer
			title="actions:createCustomer"
			onConfirm={() => {
				setIsSubmitAttempted(true)
				onSubmit()
			}}
			onCancel={hideModal}
			onConfirmLoading={isLoading || isSavingCustomer || isUpdatingCustomer || isLoadingCustomers}
			onConfirmDisabled={submitDisabled}
			className="w-11/12 lg:w-3/5"
			showDiscardModal={isDirty}
		>
			{isError ? (
				<ErrorNotice />
			) : (
				<div className="flex-col gap-6 h-full">
					<CreateSingleTenant formHandler={formHandler} />
				</div>
			)}
		</ModalContainer>
	)
}
