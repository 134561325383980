import useCustomers from "api/hooks/useCustomers"
import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { useNavigate } from "Utils/useNavigate"
import { useGlobalAlert } from "States/globalAlert"
import { useTrans } from "translations"
import { trpc } from "Utils/trpc"
import { useMultiSelectContext } from "components/pageCards/multiListTable/multiSelectContext"

export const DeleteTenantModal: React.FC<{ customerId: string; customerName: string }> = ({
	customerId,
	customerName,
}) => {
	const navigate = useNavigate()
	const { t } = useTrans()
	const { hideModal } = useModal()
	const { refetchCustomers } = useCustomers()
	const { setGlobalAlert } = useGlobalAlert()
	const { setSelectedIds } = useMultiSelectContext()

	const { mutateAsync, isLoading } = trpc.customers.delete.useMutation({
		onSuccess: () => {
			setGlobalAlert({
				type: "success",
				message: "systemMessages:customerDeleted",
			})

			refetchCustomers()
			setSelectedIds([])
			hideModal()
		},

		onError: error => {
			setGlobalAlert({
				type: "error",
				message: error.message,
			})
		},
	})

	const onConfirm = async () => {
		await mutateAsync({ id: customerId })
		navigate("/customer/manage")
	}

	return (
		<ModalContainer
			title={"actions:deleteCustomer"}
			onConfirm={onConfirm}
			onCancel={hideModal}
			onConfirmLoading={isLoading}
			onConfirmDisabled={isLoading}
			onConfirmText="actions:confirm"
		>
			<div className="pt-3">
				<p>
					{t("hints:deleteTenantConfirmation")} {customerName}?
				</p>
			</div>
		</ModalContainer>
	)
}
