import { uniq } from "lodash"
import { AccessParentWithSortedContainers } from "pages/infrastructure/functions"
import {
	AggregatedTerminalResponse,
	TerminalResponse,
	WasteType,
} from "Utils/api/datawarehouse/responseTypes"
import { sanityGetUrlFor } from "Utils/api/sanity/request"
import { FetchedWasteType, FetchedWasteTypes } from "Utils/api/sanity/types"
import { sortDescByField } from "Utils/general"
import { tooltip1ByWasteType, tooltip2ByWasteType } from "./constants"
import { WasteTypeElement } from "./WasteTypesInUserTable"

/* TODO: Need an other placeholder here */
const iconPlaceholder = "https://via.placeholder.com/40x40"

/** Local type used when grouping and sorting waste types */
type WasteTypeIntermediary = {
	occurrences: number
	share: number
	tooltip?: string
	code: string
} & FetchedWasteType

/**
 * Groups waste types by categories from Sanity.
 * The resulting list can contain a mix of categories and waste types, all structured as WasteTypeIntermediary.
 */
export const groupWasteTypes = (
	wasteTypes: WasteType[],
	sanityWasteTypes: FetchedWasteTypes
): WasteTypeIntermediary[] => {
	type ExcludesFalse = <T>(x: T | false) => x is T
	// Merge waste types including occurences with sanity data
	const mergedWasteTypes: WasteTypeIntermediary[] = wasteTypes
		.map(wt => {
			const foundWasteType = sanityWasteTypes.find(
				sanityWasteType => sanityWasteType.id === wt.code
			)
			if (!foundWasteType) {
				return false
			}

			let tooltip = undefined
			const needsTooltip1 = tooltip1ByWasteType.some(twt => twt === wt.code)
			const needsTooltip2 = tooltip2ByWasteType.some(twt => twt === wt.code)

			if (needsTooltip1) {
				tooltip = "hints:commonWasteType"
			}

			if (needsTooltip2) {
				tooltip = "hints:usefulWasteType"
			}

			return {
				...foundWasteType,
				tooltip,
				occurrences: wt.occurrences,
				share: wt.share,
				code: wt.code,
			}
		})
		.filter(Boolean as any as ExcludesFalse) // Remove any types not found in Sanity

	// Group waste types by category while summing occurrences..
	const groupedWasteTypes = mergedWasteTypes.reduce((accumulator, current) => {
		// If the type is not categorized, show it by itself in the list

		if (!current.wasteTypeCategory) {
			return [...accumulator, current]
		}

		// Check if the category is already in the list
		const existing = accumulator.find(
			(wte: FetchedWasteType) => wte.id === current.wasteTypeCategory?.id
		)

		if (existing) {
			existing.occurrences += current.occurrences
			existing.share += current.share
			const index = accumulator.indexOf(existing)
			accumulator[index] = existing
		} else {
			accumulator.push({
				...current.wasteTypeCategory,
				_id: current.wasteTypeCategory.id,
				name: current.name,
				occurrences: current.occurrences,
				tooltip: current.tooltip,
				englishName: current.englishName,
				wasteTypeCategory: null,
				classificationSystem: current.classificationSystem,
				share: current.share,
				code: current.code,
			})
		}
		return accumulator
	}, [] as WasteTypeIntermediary[])

	return groupedWasteTypes
}

/**
 * Transforms the intermediary waste type to the final waste type element used for display. Gets the final icon url from Sanity.
 * @param wasteTypes The waste type intermediaries to transform
 * @returns The waste type elements
 */
const transformWasteTypes = (wasteTypes: WasteTypeIntermediary[]): WasteTypeElement[] => {
	return wasteTypes.map(wt => ({
		id: wt.id,
		name: wt.name,
		icon: wt.iconGrayscale ? sanityGetUrlFor.image(wt.iconGrayscale).url() : iconPlaceholder,
		occurrences: wt.occurrences,
		tooltip: wt.tooltip,
	}))
}

/**
 * Gets display ready waste types in use with names and icons from sanity normalized by category.
 * @param data Data for the currenct center from the data warehouse from the previous x days
 * @param wasteRoomData Data for the waste rooms in the current center
 * @param sanityWasteTypes Waste types from Sanity
 * @returns The waste types in use with icons and tooltips
 */
export const getWastetypesInUse = (
	data: TerminalResponse,
	wasteRoomData: AccessParentWithSortedContainers[],
	sanityWasteTypes: FetchedWasteTypes
): WasteTypeElement[] => {
	const wasteTypeCodesInUse = uniq(
		wasteRoomData.flatMap(wr => wr.containers).flatMap(c => c.wasteCode)
	)

	const { terminal } = data
	const wasteTypesInUse =
		wasteTypeCodesInUse
			.map(wtCode => {
				return (
					terminal?.wasteTypes?.find(wt => wt.code === wtCode) ?? {
						code: `${wtCode}`,
						occurrences: 0,
						weight: { quantity: 0, unit: "kg" }, // Unused in this context
						share: 0, // Unused in this context
						occurrenceBasedShare: 0, // Unused in this context
					}
				)
			})
			.sort(sortDescByField("occurrences"))
			.filter(wt => !!wt) || []

	const groupedWasteTypesInUse = groupWasteTypes(wasteTypesInUse, sanityWasteTypes)
	return transformWasteTypes(groupedWasteTypesInUse)
}

/**
 * Gets display ready waste types not in use with names and icons from sanity normalized by category.
 * @param aggregatedData Data for all centers from the data warehouse
 * @param data Data for the currenct center from the data warehouse
 * @param wasteRoomData Data for the waste rooms in the current center
 * @param sanityWasteTypes Waste types from Sanity
 * @returns The waste types not in use with icons and tooltips
 */
export const getWastetypesNotUsed = (
	aggregatedData: AggregatedTerminalResponse,
	data: TerminalResponse,
	wasteRoomData: AccessParentWithSortedContainers[],
	sanityWasteTypes: FetchedWasteTypes,
	wasteTypeClassificationSystem: string
) => {
	const wasteTypeCodesInUse = uniq(
		wasteRoomData.flatMap(wr => wr.containers).flatMap(c => c.wasteCode)
	)
	const { terminal } = data
	const wasteTypesInUse =
		wasteTypeCodesInUse
			.map(wtCode => {
				return (
					terminal?.wasteTypes?.find(wt => wt.code === wtCode) ?? {
						code: `${wtCode}`,
						occurrences: 0,
						weight: { quantity: 0, unit: "kg" }, // Unused in this context
						share: 0, // Unused in this context
						occurrenceBasedShare: 0, // Unused in this context
					}
				)
			})
			.sort(sortDescByField("occurrences"))
			.filter(wt => !!wt) || []

	const allCentersWasteTypes =
		aggregatedData.terminalAggregates?.wasteTypes?.filter(wt => !!wt) ?? []

	const groupedWasteTypesInUse = groupWasteTypes(wasteTypesInUse, sanityWasteTypes)

	const groupedAllWasteTypes = groupWasteTypes(allCentersWasteTypes, sanityWasteTypes)

	const wasteTypesNotUsed = groupedAllWasteTypes.filter(
		awt => !groupedWasteTypesInUse.some(wtiu => wtiu.id === awt.id)
	)

	const filteredWasteTypesNotUsed = wasteTypesNotUsed.filter(
		wt =>
			!wasteTypeClassificationSystem ||
			wt.classificationSystem?.id === wasteTypeClassificationSystem
	)

	const withTooltip = transformWasteTypes(filteredWasteTypesNotUsed)

	// Return wastetypes with tooltip first
	return withTooltip.sort((a, b) => (a.tooltip ? -1 : b.tooltip ? 1 : 0))
}
