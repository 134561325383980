import { CSSProperties } from "react"
import { Column } from "@tanstack/react-table"

const getCommonPinningStyles = (column: Column<any>): CSSProperties => {
	const isPinned = column.getIsPinned()

	return {
		left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
		right: isPinned === "right" ? `${column.getAfter("right")}px` : undefined,
		position: isPinned ? "sticky" : "relative",
		backgroundColor: "inherit",
		maxWidth: column.columnDef.maxSize,
		zIndex: isPinned ? 1 : undefined,
	}
}

export default getCommonPinningStyles
