import { useTrans } from "translations"
import { WasteTypeItem } from "./wasteTypeItem"

export type WasteTypeCategory = { id: string; icon: string; name: string }
export type WasteTypeElement = {
	id: string
	icon: string
	name: string
	tooltip?: string
}

export const WasteTypesInUserTable: React.FC<{
	typesInUse: WasteTypeElement[]
	futurePotential: WasteTypeElement[]
}> = ({ typesInUse, futurePotential }) => {
	const { t } = useTrans()

	return (
		<div className="min-h-full">
			<p className="text-base font-bold text-black mb-4">
				{t("circularityLabels:typesAlreadyInUse", { count: typesInUse.length })}
			</p>

			<div className="flex flex-wrap gap-4 min-h-full">
				{typesInUse.map(({ icon, id, name }) => (
					<WasteTypeItem key={id} icon={icon} name={name} id={id} />
				))}

				{futurePotential.map(({ icon, id, name }) => (
					<WasteTypeItem key={id} icon={icon} name={name} id={id} isRecommended />
				))}
			</div>
		</div>
	)
}
