import { FilterSort, FilterSortMenuType, Option } from "./types"
import { FetchedTenantTypes, FetchedWasteTypes } from "Utils/api/sanity/types"
import { orderBy } from "lodash"
import { OPTION_ALL } from "./constants"
import { WasteTypeConfig } from "pages/configuration/useConfigService"
import { WasteType } from "admin-client-server/src/coreApi/accessPoints/types"
import { FilterTimeFrameValue } from "admin-client-server/src/config-api"

export const createTimeFilters = (
	filterProps?: Partial<Omit<FilterSort, "options"> & { defaultValue: FilterTimeFrameValue }>,
	options?: FilterTimeFrameValue[]
): FilterSort => {
	const availableOptions = [
		{ option: "periods:today", value: FilterTimeFrameValue.Today, translate: true },
		{ option: "periods:7days", value: FilterTimeFrameValue.LastWeek, translate: true },
		{ option: "periods:last30days", value: FilterTimeFrameValue.Last30Days, translate: true },
		{ option: "periods:last90days", value: FilterTimeFrameValue.Last90Days, translate: true },
		{ option: "periods:lastMonth", value: FilterTimeFrameValue.LastMonth, translate: true },
		{ option: "periods:yearToDate", value: FilterTimeFrameValue.YearToDate, translate: true },
		{ option: "periods:lastYear", value: FilterTimeFrameValue.LastYear, translate: true },
		{
			option: "periods:last6FullMonths",
			value: FilterTimeFrameValue.Last6FullMonths,
			translate: true,
		},
		{ option: "periods:lastFullYear", value: FilterTimeFrameValue.LastFullYear, translate: true },
		{
			option: "periods:sinceBeginning",
			value: FilterTimeFrameValue.SinceBeginning,
			translate: true,
		},
	] as { option: string; value: FilterTimeFrameValue; translate?: boolean }[]

	const filteredOptions = options?.length
		? availableOptions.filter(({ value }) => options.includes(value))
		: availableOptions

	return {
		id: "period",
		type: "filter",
		menuType: FilterSortMenuType.Select,
		title: "filterLabels:period",
		searchEnabled: false,
		...filterProps,
		options: filteredOptions,
	}
}

export const createFractionFilter = (
	fractionList: WasteType[],
	wasteTypes: FetchedWasteTypes | null,
	wasteTypeConfig: WasteTypeConfig[]
): FilterSort => {
	const mappedFractions =
		fractionList?.map(fraction => {
			const wasteType = wasteTypes?.find(({ id }: { id: any }) => id === fraction.code)
			return {
				option: wasteType
					? `${
							wasteTypeConfig.find(el => el.wasteTypeCode === fraction.code)?.name || wasteType.name
						} (${fraction.code})`
					: fraction.code,
				value: fraction.code,
			}
		}) ?? []

	const sortedFractions = orderBy(mappedFractions, "option")

	return {
		id: "fractionId",
		type: "filter",
		menuType: FilterSortMenuType.Select,
		options: [
			{ option: "filterLabels:allWasteTypes", value: "all", translate: true },
			...sortedFractions,
		],
		title: "entities:wasteTypes",
		defaultValue: OPTION_ALL,
	}
}

export const getTenantCategoryOptions = (tenantCategories: FetchedTenantTypes | null): Option[] => [
	{ option: "filterLabels:allCustomerTypes", value: "all", translate: true },
	...(tenantCategories?.map(({ name, id }) => ({ value: id, option: name })) || []),
]
