import React, { useCallback, useEffect, useMemo, useState } from "react"
import { trpc } from "Utils/trpc"
import { WasteStreamsTable } from "./wasteStreamsTable"
import { sortBy, uniqBy } from "lodash"
import { ACCESS_POINT } from "admin-client-server/src/coreApi/accessPoints/types"
import { WasteTypeData, useConfigService } from "./useConfigService"
import { Translate, useTrans } from "translations"
import { useTerminalsStateRE } from "States/Terminals"
import { useAccessParents } from "pages/infrastructure/functions"
import { COLOR_CONFIG, COLOR_KEY, CUSTOM_COLORS, ColorsTable, DEFAULT_CONFIG } from "./colorsTable"
import { Button } from "components/button"
import { StyledSelect } from "components/StyledSelect"
import { FilterTimeFrameValue } from "admin-client-server/src/config-api"
import { createTimeFilters } from "components/pageCards/filterSort/filterCreators"

type Props = {
	hasUnsavedChanges: boolean
	setHasUnsavedChanges: (hasChanges: boolean) => void
}

// Intentionally run only once before the component is rendered
const timeFilters = createTimeFilters()

export const WasteStreamsBuilding: React.FC<Props> = ({
	hasUnsavedChanges,
	setHasUnsavedChanges,
}: Props) => {
	const { t, language } = useTrans()
	const { accessParents } = useAccessParents()
	const { currentTerminal } = useTerminalsStateRE()
	const {
		terminalWasteTypeConfig: data,
		refetchTerminalWasteTypeConfig: refetch,
		terminalConfig,
		isLoadingTerminalConfig,
		updateTerminalConfig,
	} = useConfigService()

	const timeFrameOptions = useMemo(() => {
		return timeFilters.options.map(({ option, value }) => ({
			label: t(option),
			value,
		}))
	}, [t])

	const [updatedWasteTypes, setUpdatedWasteTypes] = useState<WasteTypeData[]>([])
	const [updatedColors, setUpdatedColors] = useState<COLOR_CONFIG>(DEFAULT_CONFIG)
	const [selectedTimeFrame, setSelectedTimeFrame] = useState<{
		label: string
		value: string
	} | null>(null)

	// Set initial time frame
	useEffect(() => {
		if (isLoadingTerminalConfig) {
			return
		}
		if (terminalConfig?.defaultTimeFrame) {
			setSelectedTimeFrame(
				timeFrameOptions.find(to => to.value === terminalConfig.defaultTimeFrame) || null
			)
		} else {
			setSelectedTimeFrame(null)
		}
	}, [isLoadingTerminalConfig, terminalConfig, timeFrameOptions])

	useEffect(() => {
		const hasChangedColors = Object.keys(updatedColors).some(
			c => (updatedColors[c as COLOR_KEY] || null) !== (terminalConfig?.[c as COLOR_KEY] || null)
		)

		const hasChangedWasteTypes = !!updatedWasteTypes.some(wt => {
			const originalWT = data?.find(d => d.wasteTypeCode === wt.wasteTypeCode)

			if (!originalWT) {
				return wt.names.some(n => n.displayName !== "") || wt.color !== ""
			} else {
				return (
					wt.names.some(
						n =>
							n.displayName !== originalWT.names.find(owt => owt.locale === n.locale)?.displayName
					) ||
					wt.color !== originalWT.color ||
					wt.downstreamHandlingId !== originalWT.downstreamHandlingId ||
					wt.co2factor !== originalWT.co2factor
				)
			}
		})

		setHasUnsavedChanges(hasChangedColors || hasChangedWasteTypes)
	}, [terminalConfig, data, updatedColors, updatedWasteTypes, setHasUnsavedChanges])

	useEffect(() => {
		let updatedColors: { [k in COLOR_KEY]: string } = { ...DEFAULT_CONFIG }

		CUSTOM_COLORS.forEach(color => {
			updatedColors[color.key] = terminalConfig?.[color.key] || ""
		})

		setUpdatedColors(updatedColors)
	}, [terminalConfig, setUpdatedColors])

	const { mutate: updateTerminalWasteTypeConfig, isLoading } =
		trpc.config.updateTerminalWasteTypeConfig.useMutation({
			onSuccess: () => {
				refetch()
				setHasUnsavedChanges(false)
			},
		})

	const containers = useMemo(() => {
		return sortBy(
			uniqBy(
				accessParents?.flatMap(ap => ap.containers as ACCESS_POINT[]),
				"wasteType.code"
			),
			"wasteType.code"
		)
	}, [accessParents])

	const onSave = useCallback(() => {
		if (updatedWasteTypes.length) {
			updateTerminalWasteTypeConfig({
				updates: updatedWasteTypes.map(uwt => ({ ...uwt, locale: language })),
				terminalId: currentTerminal.id,
			})
		}

		if (
			Object.values(updatedColors).filter(Boolean).length ||
			(!!terminalConfig && Object.values(terminalConfig).filter(Boolean).length)
		) {
			updateTerminalConfig({
				...updatedColors,
				terminalId: currentTerminal.id,
				defaultTimeFrame: (selectedTimeFrame?.value || null) as FilterTimeFrameValue | null,
			})
		}
	}, [
		updatedWasteTypes,
		updatedColors,
		terminalConfig,
		updateTerminalWasteTypeConfig,
		currentTerminal?.id,
		language,
		updateTerminalConfig,
		selectedTimeFrame?.value,
	])

	const onResetAll = useCallback(() => {
		setUpdatedWasteTypes(
			data?.map(el => ({
				...el,
				names: el.names.map(n => ({ ...n, displayName: "" })),
				color: "",
				downstreamHandlingId: null,
				co2factor: null,
			})) || []
		)
		setUpdatedColors(DEFAULT_CONFIG)
		setSelectedTimeFrame(null)
		setHasUnsavedChanges(true)
	}, [setUpdatedWasteTypes, setHasUnsavedChanges, data])

	const onSelectedTimeFrameChange = useCallback(
		(selectedTimeFrame: { label: string; value: string }) => {
			setSelectedTimeFrame(selectedTimeFrame)
			setHasUnsavedChanges(selectedTimeFrame.value !== terminalConfig?.defaultTimeFrame)
		},
		[setHasUnsavedChanges, terminalConfig?.defaultTimeFrame]
	)

	const onResetTimeFrame = useCallback(() => {
		setSelectedTimeFrame(null)
		setHasUnsavedChanges(!!terminalConfig?.defaultTimeFrame)
	}, [setSelectedTimeFrame, setHasUnsavedChanges, terminalConfig?.defaultTimeFrame])

	return (
		<>
			<div className="mt-4">
				<ul className="list-disc list-inside ml-2">
					<Translate
						i18nKey="hints:wasteStreamBuildingConfig"
						children={[
							<li />,
							<li>
								{
									// eslint-disable-next-line jsx-a11y/anchor-has-content
									<a
										href="/infrastructure/manage"
										style={{ textDecoration: "underline" }}
										rel="noreferrer"
									/>
								}
							</li>,
							<li />,
						]}
					/>
				</ul>
			</div>
			<div className="my-8">
				<label>{t("configLabels:selectDefaultTimeFrame")}</label>
				<div className="flex gap-4 items-end">
					<StyledSelect
						className="mt-1 max-w-xs w-60"
						options={timeFrameOptions}
						value={selectedTimeFrame}
						onChange={onSelectedTimeFrameChange}
					/>
					<button className="underline" onClick={onResetTimeFrame}>
						{t("actions:reset")}
					</button>
				</div>
			</div>
			<ColorsTable
				updatedColors={updatedColors}
				setUpdatedColors={setUpdatedColors}
				setHasUnsavedChanges={setHasUnsavedChanges}
			/>
			<WasteStreamsTable
				containers={containers}
				data={data}
				setHasUnsavedChanges={setHasUnsavedChanges}
				onSave={onSave}
				updateLoading={isLoading}
				updatedWasteTypes={updatedWasteTypes}
				setUpdatedWasteTypes={setUpdatedWasteTypes}
				hasResetOption
			/>
			<div className="bg-white w-full sticky bottom-0 pb-4">
				<hr></hr>
				<div className="mt-4 text-sm flex justify-between">
					<Button
						label={t("actions:save")}
						onClick={onSave}
						loading={false}
						disabled={!hasUnsavedChanges}
					/>
					<div className="mr-4 self-center underline cursor-pointer" onClick={onResetAll}>
						{t("actions:resetAll")}
					</div>
				</div>
			</div>
		</>
	)
}
