import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { isEmpty, omit } from "lodash"
import { useMemo } from "react"
import { useForm } from "react-hook-form"
import { AccessParentContainerData } from "../functions"
import { ContainerForm, ContainerFormData } from "../manage/containerForm"
import { useCommonEntitiesStore } from "States/commonEntities"
import { findClassificationSystemForWasteCode } from "Utils/api/sanity/functions"
import { useGlobalAlert } from "States/globalAlert"
import { useAccessParentsWithPoints } from "../manage/useAccessParentsWithPoints"
import { useAccessPoints } from "../manage/useAccessPoints"
import { PointStatus } from "Utils/terminal"
import { POINT_STATUSES } from "admin-client-server/src/coreApi/common/constants"

export const EditContainerModal: React.FC<{
	accessParentId: string
	container: AccessParentContainerData
}> = ({ container, accessParentId }) => {
	const { hideModal } = useModal()
	const { getAccessParent } = useAccessParentsWithPoints()
	const { isLoading, refetch, updateAccessPoint } = useAccessPoints({ accessParentId })
	const { wasteTypes } = useCommonEntitiesStore()
	const { setGlobalAlert } = useGlobalAlert()

	const accessParent = getAccessParent(accessParentId)

	const formHandler = useForm<ContainerFormData>({
		defaultValues: {
			status: POINT_STATUSES[0],
			...omit(container || {}, "name"),
			containerName: container?.name || "",
			wasteTypeClassificationSystem: container?.fractionDiscriminator,
		},
	})

	const {
		formState: { errors, isValid },
		handleSubmit,
	} = formHandler

	const onSubmitSingle = (containerFormData: ContainerFormData) => {
		if (!accessParent) return

		const { containerName, wasteCode, status } = containerFormData

		const fractionDesc = wasteTypes?.find(({ id }) => id === wasteCode)?.name || ""

		updateAccessPoint({
			id: container.id,
			name: containerName,
			status: status as PointStatus,
			wasteCode,
			fractionDesc: fractionDesc,
			fractionDiscriminator: findClassificationSystemForWasteCode(wasteTypes, wasteCode),
		}).then(() => {
			setGlobalAlert({
				type: "success",
				message: "systemMessages:changesSaved",
			})
			refetch().then(hideModal)
		})
	}

	const onSubmit = handleSubmit(containerFormData => {
		onSubmitSingle(containerFormData)
	})

	const onConfirmDisabled = useMemo(() => !isEmpty(errors) || !isValid, [errors, isValid])

	if (!accessParentId) return null

	return (
		<ModalContainer
			title="drawerLabels:containerDetails"
			onConfirmText={"actions:save"}
			onConfirm={() => onSubmit()}
			onCancel={hideModal}
			onConfirmLoading={isLoading}
			onConfirmDisabled={onConfirmDisabled}
			className="w-3/4 sm:w-1/2 lg:w-1/3"
		>
			<div className="flex-col gap-4">
				<ContainerForm {...{ formHandler, onSubmit, isEdit: true }} />
			</div>
		</ModalContainer>
	)
}
