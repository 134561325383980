import React, { useCallback, useMemo, useState } from "react"
import { twMerge } from "tailwind-merge"
import { ColumnType } from "Utils/tableColumns/functions"
import TrashIcon from "Icons/Trash"
import { useModal } from "Contexts"
import { ConfirmModal } from "components/ConfirmModal"

type Props = {
	values: { [key: string]: any }
	columns: ColumnType[]
	isCollapsible: boolean
	getExpandedRowView?: (values: { [key: string]: any }) => React.ReactNode
	rowClassName: string
	hasDelete: boolean
	deleteFunction: (id: string) => void
	isLast: boolean
}

export const TableRow: React.FC<Props> = ({
	values,
	columns,
	getExpandedRowView,
	isCollapsible,
	rowClassName,
	hasDelete,
	deleteFunction,
	isLast,
}) => {
	const [isOpen, setOpen] = useState<boolean>(false)
	const { showModal } = useModal()
	const toggleOpen = useCallback(() => {
		if (isCollapsible) {
			setOpen(prevState => !prevState)
		}
	}, [isCollapsible, setOpen])

	const cellsView = useMemo(() => {
		const cells = columns.map((column: ColumnType) => {
			const value = values[column.name]

			// https://react.dev/reference/react/cloneElement#caveats
			// adding key to avoid warning about non unique key
			return React.cloneElement(column.getView(value), { key: column.name })
		})

		return cells
	}, [values, columns])

	const onDeleteClick = useCallback(() => {
		showModal(<ConfirmModal entityName={values.name} onConfirm={() => deleteFunction(values.id)} />)
	}, [deleteFunction, values, showModal])

	return (
		<div className={twMerge("border-b border-grey2", isLast && "border-b-0")}>
			<div
				className={twMerge(
					`relative grid min-h-[48px] grid-cols-${columns.length} hover:bg-hoverColor`,
					isCollapsible &&
						`cursor-pointer before:absolute before:flex before:h-12 before:pl-2 before:items-center before:content-arrowDownIcon before:color-black ${
							!isOpen && "before:justify-end"
						}`,
					isOpen && "bg-selectedColor hover:bg-selectedColor border-b border-grey2",
					isOpen && isCollapsible && "before:rotate-180 before:justify-start before:pr-2",
					hasDelete && "pr-10",
					isLast && "border-b-0",
					rowClassName
				)}
				onClick={toggleOpen}
			>
				{cellsView}
				{hasDelete && (
					<button onClick={onDeleteClick} className="absolute right-2 top-2" type="button">
						<TrashIcon />
					</button>
				)}
			</div>

			{isOpen && isCollapsible && getExpandedRowView && getExpandedRowView(values)}
		</div>
	)
}
