import { trpc } from "Utils/trpc"
import { StyledSelect } from "components/StyledSelect"
import { Toggle } from "components/Toggle"
import { Dispatch, FC, SetStateAction, useEffect, useMemo } from "react"
import { useTrans } from "translations"
import Skeleton from "react-loading-skeleton"

export type PricingType = {
	currency: string
	price: string
}

type Props = {
	currentTerminalId: string
	updatedPricing: PricingType
	setHasUnsavedChanges: (hasChanges: boolean) => void
	setPricing: Dispatch<SetStateAction<PricingType>>
	isSaving: boolean
}

const currencies = [
	{ label: "GBP", value: "gbp" },
	{ label: "NOK", value: "nok" },
]

export const Payments: FC<Props> = ({
	currentTerminalId,
	updatedPricing,
	setHasUnsavedChanges,
	setPricing,
	isSaving,
}) => {
	const { t } = useTrans()
	const { currency: selectedCurrency, price } = updatedPricing

	const { data: originalPricing, isLoading } = trpc.payments.getPricing.useQuery({
		terminalId: currentTerminalId,
	})

	const { data: terminalConfig, refetch: refetchTerminalConfig } =
		trpc.config.getTerminalConfig.useQuery({
			terminalId: currentTerminalId,
		})

	useEffect(() => {
		if (originalPricing) {
			setPricing({
				currency: originalPricing.currency,
				price: `${originalPricing.unit_amount / 100}`,
			})
		}
	}, [setPricing, originalPricing])

	useEffect(() => {
		if (originalPricing) {
			setHasUnsavedChanges(
				originalPricing.currency !== selectedCurrency ||
					`${originalPricing.unit_amount / 100}` !== price
			)
		}
	}, [originalPricing, selectedCurrency, price, setHasUnsavedChanges])

	const { mutate: mutateTerminalConfig } = trpc.config.updateTerminalConfig.useMutation({
		onSettled: () => refetchTerminalConfig(),
	})

	const togglePayments = (enableTenantPayments: boolean) => {
		mutateTerminalConfig({
			terminalId: currentTerminalId,
			enableTenantPayments,
		})
	}

	const disabled = isLoading || isSaving

	const content = useMemo(() => {
		if (!terminalConfig?.enableTenantPayments) {
			return null
		}

		return (
			<div>
				<div className="mt-4">
					<label className="select-none">{t("configLabels:pricing")}</label>
					<div className="flex items-center mt-2">
						<input
							type="text"
							value={price}
							onChange={e => setPricing(prev => ({ ...prev, price: e.target.value }))}
							className="rounded-none h-10 w-full mr-2 box-border border border-black p-3 font-dmSans text-sm placeholder:text-grey6 disabled:border-grey3 disabled:text-grey6 focus:outline focus:outline-2 focus:outline-offset-[-2px]"
							disabled={disabled}
							data-1p-ignore
						/>
						<StyledSelect
							name={"configLabels:currency"}
							options={currencies}
							value={currencies.find(c => c.value === selectedCurrency)}
							onChange={v => setPricing(prev => ({ ...prev, currency: v.value }))}
							isDisabled={disabled}
							className="min-w-20"
						/>
					</div>
				</div>
			</div>
		)
	}, [terminalConfig?.enableTenantPayments, t, price, disabled, setPricing, selectedCurrency])

	return (
		<div className="mt-4">
			{isLoading ? (
				<Skeleton count={1} height={26} />
			) : terminalConfig ? (
				<>
					<Toggle
						label={t("configLabels:paymentsEnabled")}
						checked={terminalConfig.enableTenantPayments}
						onChange={checked => togglePayments(checked)}
					/>
					{content}
				</>
			) : null}
		</div>
	)
}
