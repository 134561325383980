import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { isEmpty } from "lodash"
import { useForm } from "react-hook-form"
import { useTerminals } from "api/hooks/useTerminals"
import { BuildingForm, BuildingFormData } from "./BuildingForm"
import { useNavigate } from "Utils/useNavigate"
import { useGlobalAlert } from "States/globalAlert"
import { useMemo, useState } from "react"
import { useEmailJob } from "pages/configuration/components/useEmailJob"
import { EmailJobType } from "admin-client-server/src/config-api"
import { trpc } from "Utils/trpc"

export const CreateBuildingModal = () => {
	const { hideModal } = useModal()
	const navigate = useNavigate()
	const { refetchTerminals, isLoadingTerminals } = useTerminals()
	const { addEmailJob } = useEmailJob()
	const { setGlobalAlert } = useGlobalAlert()
	const [isSubmitAttempted, setIsSubmitAttempted] = useState(false)

	const formHandler = useForm<BuildingFormData>({})

	const {
		formState: { errors, isValid, isDirty },
		handleSubmit,
	} = formHandler

	const { mutate, isLoading: isSaving } = trpc.terminals.create.useMutation({
		onSuccess: async terminal => {
			const terminalId = terminal.id
			const contactEmail = terminal.operations.email
			await addEmailJob({
				terminalId,
				email: contactEmail,
				type: EmailJobType.ActionReport,
			})
			await refetchTerminals()

			setGlobalAlert({
				type: "success",
				message: "systemMessages:terminalAdded",
			})
			hideModal()
			navigate(`/infrastructure/manage/${terminalId}`)
		},
	})

	const isLoading = isSaving || isLoadingTerminals

	const setupBuilding = (formData: BuildingFormData) => {
		const terminalData = {
			name: formData.name,
			status: "ACTIVE",
			category: formData.category,
			contactEmail: formData.contactEmail,
			area: formData.area,
		}

		mutate(terminalData)
	}

	const onSubmit = handleSubmit(formData => {
		setIsSubmitAttempted(true)
		setupBuilding(formData)
	})

	const isSubmitDisabled = useMemo(
		() => isSubmitAttempted && (!isEmpty(errors) || !isValid),
		[isSubmitAttempted, errors, isValid]
	)

	return (
		<ModalContainer
			title="actions:createTerminal"
			onConfirm={() => onSubmit()}
			onConfirmLoading={isLoading}
			onCancel={hideModal}
			onConfirmDisabled={isSubmitDisabled}
			className="w-3/4 sm:w-1/2 lg:w-1/3"
			showDiscardModal={isDirty}
		>
			<BuildingForm formHandler={formHandler} />
		</ModalContainer>
	)
}
