import { IImportContext } from "components/ImportComponents/ImportContext"
import {
	ACCESS_PARENT_KEY,
	ACCESS_PARENT_NAME_KEY,
	CONTAINER_KEY,
	CONTAINER_NAME_KEY,
	DEPOT_KEY,
	DEPOT_NAME_KEY,
	DISCONTINUED_VALUE,
	TERMINAL_KEY,
	TERMINAL_NAME_KEY,
} from "./constants"
import { FetchedWasteTypes } from "Utils/api/sanity/types"
import { ValidationCell, ValidationState } from "components/ImportComponents/types"
import { get } from "lodash"
import {
	CreateTerminal,
	CreateTerminals,
} from "admin-client-server/src/coreApi/terminals/create/models"
import {
	CreateAccessPoint,
	CreateAccessPoints,
} from "admin-client-server/src/coreApi/accessPoints/create/models"
import { CreateDepot, CreateDepots } from "admin-client-server/src/coreApi/depots/create/models"
import {
	CreateAccessParent,
	CreateAccessParents,
} from "admin-client-server/src/coreApi/accessParents/create/models"
import {
	ModifiedPoint,
	ModifiedPointsResponse,
} from "admin-client-server/src/coreApi/common/models"
import { PointStatus } from "Utils/terminal"

export const FINALIZED_STATE = {
	importing: "",
	finalized: true,
}

export const ERROR_STATE = {
	importing: "",
	error: true,
}

const getStatusDbValue = (isMWM: boolean, statusCell?: ValidationCell[], index?: number) => {
	if (!isMWM || !statusCell || !index) return PointStatus.ACTIVE

	const cellValue = statusCell[index].value
	if (cellValue === DISCONTINUED_VALUE) {
		return PointStatus.DEPRECATED
	}

	return cellValue.toUpperCase()
}

type AddToSystemProps = {
	context: IImportContext
	wasteTypes: FetchedWasteTypes | null
	refetchTerminals: () => Promise<void>
	refetchAccessParents: () => any
	isMWM: boolean
	useWasteSuctionSystem: boolean
	addAllContactPersonsToActionReportForClient: ({
		terminalIds,
	}: {
		terminalIds: string[]
	}) => Promise<boolean>
	createFunctions: {
		bulkCreateTerminals: (input: CreateTerminals) => Promise<ModifiedPointsResponse>
		bulkCreateAccessParents: (input: CreateAccessParents) => Promise<ModifiedPointsResponse>
		bulkCreateAccessPoints: (input: CreateAccessPoints) => Promise<ModifiedPointsResponse>
		bulkCreateDepots: (input: CreateDepots) => Promise<ModifiedPointsResponse>
	}
}

const getTerminalsToAdd = ({
	validationState,
	terminalNameKey,
	isMWM,
}: {
	validationState: ValidationState
	terminalNameKey: string
	isMWM: boolean
}) => {
	const terminalPropertyData = validationState[TERMINAL_KEY]!
	const terminalNameRows = terminalPropertyData[terminalNameKey]

	return terminalNameRows.map((nameCell, i) => {
		const { contactEmail, buildingCategory, area } = terminalPropertyData

		const terminal: CreateTerminal = {
			name: nameCell.value,
		}

		if (!isMWM) {
			terminal.contactEmail = contactEmail[i].value
			terminal.category = buildingCategory[i].value

			if (area?.[i].value) {
				terminal.area = area[i].value.toString()
			}
		}

		return terminal
	})
}

const getContainersToAdd = (
	context: IImportContext,
	wasteTypes: FetchedWasteTypes | null,
	isMWM: boolean,
	createdAccessParents?: ModifiedPoint[]
) => {
	const { validationState, selectedParentIds } = context
	const containersPropertyData = validationState[CONTAINER_KEY]!
	const containerNameRows = containersPropertyData[CONTAINER_NAME_KEY]

	return containerNameRows.map((nameCell, i) => {
		const { accessParentName, depotName, wasteTypeClassification, wasteTypeCode, containerStatus } =
			containersPropertyData
		const parentId = createdAccessParents
			? createdAccessParents.find(
					({ name }: { name: string }) =>
						name === accessParentName?.[i].value || name === depotName?.[i].value
				)?.id
			: (selectedParentIds[DEPOT_KEY] ?? selectedParentIds[ACCESS_PARENT_KEY])

		const wasteCodeId = wasteTypeCode[i].value

		const container: CreateAccessPoint = {
			name: nameCell.value,
			status: getStatusDbValue(isMWM, containerStatus, i),
			fractionDiscriminator: wasteTypeClassification[i].value,
			fraction: wasteCodeId,
			fractionDesc: wasteTypes?.find(({ id }) => id === wasteCodeId)?.name,
			parentId: parentId || "",
		}

		return container
	})
}

const addContainers = async (props: AddToSystemProps, createdAccessParents?: ModifiedPoint[]) => {
	const {
		createFunctions: { bulkCreateAccessPoints },
		refetchAccessParents,
		context,
		wasteTypes,
		isMWM,
	} = props
	const { setLoadingState, fullReset } = context

	const containers = getContainersToAdd(context, wasteTypes, isMWM, createdAccessParents)

	setLoadingState({
		importing: "importLabels:importingContainers",
	})

	await bulkCreateAccessPoints({
		accessPoints: containers,
	})
		.then(() => {
			refetchAccessParents().then(() => {
				setLoadingState(FINALIZED_STATE)
				fullReset()
			})
		})
		.catch(() => setLoadingState(ERROR_STATE))
}

const addDepotContainers = async (props: AddToSystemProps, createdDepots?: ModifiedPoint[]) => {
	const {
		createFunctions: { bulkCreateAccessPoints },
		refetchAccessParents,
		context,
		wasteTypes,
		isMWM,
	} = props
	const { setLoadingState, fullReset } = context

	const containers = getContainersToAdd(context, wasteTypes, isMWM, createdDepots)

	setLoadingState({
		importing: "importLabels:importingContainers",
	})

	await bulkCreateAccessPoints({
		accessPoints: containers,
	})
		.then(() => {
			refetchAccessParents().then(() => {
				setLoadingState(FINALIZED_STATE)
				fullReset()
			})
		})
		.catch(() => setLoadingState(ERROR_STATE))
}

const getDepotsToAdd = (context: IImportContext, createdAccessParents?: ModifiedPoint[]) => {
	const { validationState, selectedParentIds } = context
	const depotsPropertyData = validationState[DEPOT_KEY]!
	const depotNameRows = depotsPropertyData[DEPOT_NAME_KEY]

	return depotNameRows.map((nameCell, i) => {
		const { accessParentName, depotStatus } = depotsPropertyData
		const parentId = createdAccessParents
			? createdAccessParents.find(
					({ name }: { name: string }) => name === accessParentName[i].value
				)?.id
			: selectedParentIds[ACCESS_PARENT_KEY]

		const container: CreateDepot = {
			name: nameCell.value,
			status: getStatusDbValue(true, depotStatus, i),
			parentId: parentId || "",
		}

		return container
	})
}

const addDepots = async (props: AddToSystemProps, createdAccessParents?: ModifiedPoint[]) => {
	const {
		createFunctions: { bulkCreateDepots },
		refetchAccessParents,
		context,
	} = props
	const { mappingState, setLoadingState, fullReset } = context

	const depots = getDepotsToAdd(context, createdAccessParents)

	setLoadingState({
		importing: "importLabels:importingDepots",
	})

	await bulkCreateDepots({
		depots,
	})
		.then(res => {
			refetchAccessParents().then(() => {
				if (mappingState[CONTAINER_KEY]?.selected) {
					addDepotContainers(props, [...res.modifiedPoints, ...(createdAccessParents ?? [])])
				} else {
					setLoadingState(FINALIZED_STATE)
					fullReset()
				}
			})
		})
		.catch(() => setLoadingState(ERROR_STATE))
}

const getAccessParentsToAdd = (
	context: IImportContext,
	isMWM: boolean,
	createdTerminals?: ModifiedPoint[]
) => {
	const { validationState, selectedParentIds } = context

	const accessParentsPropertyData = validationState[ACCESS_PARENT_KEY]!
	const accessParentNameRows = accessParentsPropertyData[ACCESS_PARENT_NAME_KEY]

	return accessParentNameRows.map((nameCell, i) => {
		const { terminalName, coordinates, municipality, client, accessParentStatus } =
			accessParentsPropertyData

		const parentId = createdTerminals
			? createdTerminals.find(({ name }: ModifiedPoint) => name === terminalName[i].value)?.id
			: selectedParentIds[TERMINAL_KEY]

		const accessParent: CreateAccessParent = {
			name: nameCell.value,
			status: getStatusDbValue(isMWM, accessParentStatus, i),
			parentId: parentId || "",
		}

		if (isMWM) {
			if (coordinates?.[i]?.value) {
				accessParent.coordinates = coordinates[i].value.toString()
			}
			accessParent.municipality = get(municipality, `[${i}].value`, "").toString()
			accessParent.clientName = get(client, `[${i}].value`, "").toString()
		}

		return accessParent
	})
}

const addAccessParents = async (props: AddToSystemProps, createdTerminals?: ModifiedPoint[]) => {
	const {
		createFunctions: { bulkCreateAccessParents },
		refetchAccessParents,
		context,
		isMWM,
		useWasteSuctionSystem,
	} = props
	const { mappingState, setLoadingState, fullReset } = context

	const accessParents = getAccessParentsToAdd(context, isMWM, createdTerminals)

	setLoadingState({
		importing: "importLabels:importingAccessParents",
	})

	await bulkCreateAccessParents({
		accessParents,
	})
		.then(res => {
			if (useWasteSuctionSystem) {
				if (mappingState[DEPOT_KEY]?.selected) {
					addDepots(props, res.modifiedPoints)
				} else if (mappingState[CONTAINER_KEY]?.selected) {
					addContainers(props, res.modifiedPoints)
				} else {
					refetchAccessParents().then(() => {
						setLoadingState(FINALIZED_STATE)
						fullReset()
					})
				}
			} else if (mappingState[CONTAINER_KEY]?.selected) {
				addContainers(props, res.modifiedPoints)
			} else {
				refetchAccessParents().then(() => {
					setLoadingState(FINALIZED_STATE)
					fullReset()
				})
			}
		})
		.catch(() => setLoadingState(ERROR_STATE))
}

const addTerminals = async (props: AddToSystemProps) => {
	const {
		createFunctions: { bulkCreateTerminals },
		refetchTerminals,
		addAllContactPersonsToActionReportForClient,
		context,
		isMWM,
	} = props
	const { mappingState, validationState, setLoadingState, fullReset } = context

	const terminals = getTerminalsToAdd({
		validationState,
		terminalNameKey: TERMINAL_NAME_KEY,
		isMWM: props.isMWM,
	})

	setLoadingState({
		importing: "importLabels:importingTerminals",
		finalized: false,
	})

	await bulkCreateTerminals({
		terminals,
	})
		.then(res => {
			refetchTerminals().then(() => {
				const terminalIds = res.modifiedPoints?.map(({ id }: { id: string }) => id)
				if (terminalIds.length && !isMWM) {
					addAllContactPersonsToActionReportForClient({ terminalIds })
				}

				if (mappingState[ACCESS_PARENT_KEY]?.selected) {
					addAccessParents(props, res.modifiedPoints)
				} else {
					setLoadingState(FINALIZED_STATE)
					fullReset()
				}
			})
		})
		.catch(() => setLoadingState(ERROR_STATE))
}

export const addToSystem = (props: AddToSystemProps) => {
	const { selectedTemplate } = props.context

	switch (selectedTemplate?.key) {
		case "terminals":
			addTerminals(props)
			break

		case "accessParents":
			addAccessParents(props)
			break

		case "depots":
			addDepots(props)
			break

		case "containers":
			addContainers(props)
			break

		case "containersOnDepots":
			addDepotContainers(props)
			break
		default:
			throw new Error(`Unknown template key ${selectedTemplate?.key}`)
	}
}
