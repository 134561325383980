import { Loading } from "App"
import { useAuth0 } from "Contexts"
import { usePostHog } from "posthog-js/react"
import { useEffect } from "react"

export const Logout = () => {
	const { logout } = useAuth0()!
	const posthog = usePostHog()

	useEffect(() => {
		posthog.reset()

		logout({
			logoutParams: {
				returnTo: window.location.origin,
			},
		})
	}, [logout, posthog])

	return <Loading />
}
