import ModalContainer from "components/modalContainer"
import { useEffect } from "react"
import { useModal } from "Contexts"
import { isEmpty } from "lodash"
import { useForm } from "react-hook-form"
import { StationForm, StationFormData } from "../manage/stationForm"
import { useGlobalAlert } from "States/globalAlert"
import { useAccessParentsWithPoints } from "../manage/useAccessParentsWithPoints"
import { POINT_STATUSES } from "admin-client-server/src/coreApi/common/constants"

export const EditStationModal: React.FC<{
	stationId: string
}> = ({ stationId }) => {
	const { hideModal } = useModal()
	const { getAccessParent, isLoading, refetch, updateAccessParent } = useAccessParentsWithPoints()
	const { setGlobalAlert } = useGlobalAlert()

	const station = getAccessParent(stationId)

	const formHandler = useForm<StationFormData>({
		defaultValues: { status: POINT_STATUSES[0], ...station, coordinates: station?.pos?.dd },
	})

	useEffect(() => {
		formHandler.trigger()
	}, [formHandler])

	const {
		formState: { errors, isValid },
		handleSubmit,
	} = formHandler

	const onSubmit = handleSubmit(formData => {
		const { name, status, coordinates, municipality, client } = formData

		updateAccessParent({
			id: stationId,
			name,
			status,
			coordinates,
			municipality,
			client,
		}).then(() => {
			setGlobalAlert({
				type: "success",
				message: "systemMessages:changesSaved",
			})
			refetch().then(hideModal)
		})
	})

	return (
		<ModalContainer
			title="drawerLabels:accessParentDetails"
			onConfirmText={"actions:confirm"}
			onConfirm={() => onSubmit()}
			onCancel={hideModal}
			onConfirmLoading={isLoading}
			onConfirmDisabled={!isEmpty(errors) || !isValid}
			className="w-3/4 sm:w-1/2 lg:w-1/3"
		>
			<StationForm {...{ formHandler, onSubmit }} />
		</ModalContainer>
	)
}
