import { useSelectedSite } from "api/hooks/useSelectedSite"
import React, { useEffect } from "react"
import { LoadingIndicator } from "Icons/loadingIndicator"
import { useTrans } from "translations"
import { capitalize, sortBy } from "lodash"
import { useSites } from "api/hooks/useSites"

const SitePicker: React.FC = () => {
	const { setSelectedSite } = useSelectedSite()
	const { t } = useTrans()

	const { sites, isLoading } = useSites()

	useEffect(() => {
		const urlSite = window.location.pathname.split("/")?.[1]
		const siteFromUrl = sites?.find(s => s.siteName === urlSite)
		if (siteFromUrl) {
			setSelectedSite(siteFromUrl)
		} else if (sites?.length === 1 && sites[0]) {
			setSelectedSite(sites[0])
		}
	}, [sites, setSelectedSite])

	if (isLoading) {
		return (
			<div className="flex justify-center items-center min-h-[300px]">
				<LoadingIndicator />
			</div>
		)
	}

	const sortedSites = sortBy(
		sites?.filter(s => !!s.siteName && !!s.displayName),
		s => s.displayName
	)

	return (
		<div className="flex flex-col justify-center h-screen bg-gray-100">
			<div className="text-center mt-8">
				<h1 className="text-2xl font-bold font-signifier mb-4">{t("genericLabels:selectSite")}</h1>
				<p className="mb-6 font-dmSans">{t("hints:youHaveMultipleSites")}</p>
			</div>
			<div className="flex-1 flex overflow-auto justify-center">
				<ul className="max-w-96 w-full space-y-4">
					{sortedSites.map(site => (
						<li
							key={site.id}
							className="border border-gray-300 p-4 shadow-sm hover:shadow-md hover:border-gray-400 transition"
							onClick={() => setSelectedSite(site)}
						>
							<h2 className="text-lg font-dmSans font-semibold">{capitalize(site.displayName!)}</h2>
						</li>
					))}
					<li className="h-8" /> {/* Spacer */}
				</ul>
			</div>
		</div>
	)
}

export default SitePicker
