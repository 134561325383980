import { useConfig } from "api/hooks/useConfig"
import { Button } from "components/button"
import { IInputOption, Input } from "components/GenericComponents/input"
import { Card, CardComponentVariant } from "components/pageCards/card"
import { useMultiSelectContext } from "components/pageCards/multiListTable/multiSelectContext"
import { useAccessParentsWithPoints } from "pages/infrastructure/manage/useAccessParentsWithPoints"
import { useAccessPoints } from "pages/infrastructure/manage/useAccessPoints"
import { FC, useCallback, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { useGlobalAlert } from "States/globalAlert"
import { useTerminalsState } from "States/Terminals"
import { useTrans } from "translations"
import { getInfrastructureUrl } from "Utils/getInfrastructureUrl"
import { trpc } from "Utils/trpc"
import { useNavigate } from "Utils/useNavigate"

export const ContainerBulkMove: FC<{}> = () => {
	const { t } = useTrans()
	const navigate = useNavigate()
	const { currentTerminal } = useTerminalsState()
	const { isMWM } = useConfig()

	const { accessParents } = useAccessParentsWithPoints(isMWM ? undefined : currentTerminal?.id)
	const { selectedContainers, setSelectedContainers } = useMultiSelectContext()
	const { accessParentId: urlAccessParentId, terminalId: urlTerminalId } = useParams<{
		accessParentId?: string
		terminalId?: string
	}>()
	const [newAccessParentId, setNewAccessParentId] = useState<string | undefined>()
	const { setGlobalAlert } = useGlobalAlert()
	const [isSaving, setIsSaving] = useState(false)
	const { refetchAll } = useAccessPoints({ accessParentId: urlAccessParentId! })
	const { refetch: movedToAccessParentRefresh } = useAccessPoints({
		accessParentId: newAccessParentId!,
	})

	const trpcUtils = trpc.useUtils()
	const { mutateAsync: bulkUpdateParent } = trpc.accessPoints.bulkUpdateParent.useMutation()

	const options: IInputOption[] = useMemo(
		() =>
			accessParents?.map(el => ({
				value: el.id || "",
				option: el.name,
				externalId: el.externalId || "",
			})) ?? [],
		[accessParents]
	)

	const navigateToAccessParent = useCallback(
		(id: string) => {
			const url = getInfrastructureUrl({
				terminalId: urlTerminalId,
				accessParentId: id,
			})

			navigate(url)
		},
		[urlTerminalId, navigate]
	)

	const onAccessParentChange = useCallback((option: IInputOption) => {
		setNewAccessParentId(option.value)
	}, [])

	const moveContainers = useCallback(() => {
		if (newAccessParentId && newAccessParentId !== urlAccessParentId) {
			setIsSaving(true)
			const payload = {
				points: selectedContainers.map(({ id, name }) => ({
					id,
					name,
				})),
				newParentId: newAccessParentId,
			}

			bulkUpdateParent(payload)
				.then(async () => {
					await trpcUtils.accessPoints.invalidate()
					await refetchAll()
					await movedToAccessParentRefresh()
					setIsSaving(false)
					setGlobalAlert({
						type: "success",
						message: `systemMessages:${
							selectedContainers.length === 1 ? "containerMoved" : "containersMoved"
						}`,
					})
					setSelectedContainers([])
					navigateToAccessParent(urlAccessParentId || "")
				})
				.catch(() => {
					setIsSaving(false)
					setGlobalAlert({
						type: "error",
						message: `systemMessages:${
							selectedContainers.length === 1 ? "containerMoveFailed" : "containersMoveFailed"
						}`,
					})
				})
		}
	}, [
		selectedContainers,
		movedToAccessParentRefresh,
		setSelectedContainers,
		newAccessParentId,
		urlAccessParentId,
		navigateToAccessParent,
		setGlobalAlert,
		refetchAll,
		trpcUtils,
		bulkUpdateParent,
	])

	const actionName = useMemo(
		() => (selectedContainers.length > 1 ? "actions:moveContainers" : "actions:moveContainer"),
		[selectedContainers]
	)

	return (
		<Card title={actionName} variant={CardComponentVariant.DRAWER}>
			<p className="text black mb-4 font-dmSans text-sm">
				{t("hints:pickEntityToMove", { entity: t("entities:accessParent").toLowerCase() })}
			</p>
			<div className="flex flex-col">
				<Input
					data={options || []}
					dropdownStyle={{ maxHeight: 190 }}
					dropdownIcon
					initialValue={urlAccessParentId || ""}
					onChange={onAccessParentChange}
					ignoreOnRenderChange
				/>
			</div>
			<Button
				className="float-right mt-8"
				label={actionName}
				loading={isSaving}
				color="secondary"
				onClick={moveContainers}
			/>
		</Card>
	)
}
