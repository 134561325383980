import { TFormatNumberSuffix, formatNumberForTable } from "Utils/formatFunctions"
import UnitDiff from "components/UnitDiff"
import { twMerge } from "tailwind-merge"
import { translate } from "translations/functions/hook"

export enum DeltaTrend {
	Positive = "positive",
	Negative = "negative",
	Neutral = "neutral",
}

export type ColumnType = {
	label: string
	name: string
	getView: (value: any) => JSX.Element
	headerClassNames?: string
	sortable?: boolean
	headerTooltipText?: string
	headerTooltipComponent?: React.ReactNode
}

export const RIGHT_ALIGN_CLASS = "justify-end text-end pr-8"

export const titleView = (value: string) => (
	<span className="block text-start leading-[48px] text-sm text-black pl-3 overflow-hidden text-ellipsis whitespace-nowrap">
		{value}
	</span>
)

export const percentageView = (
	value: string,
	suffix: TFormatNumberSuffix,
	cellClassNames: string = ""
) => (
	<span
		className={twMerge("text-sm text-black flex items-center justify-start pl-3", cellClassNames)}
	>
		{formatNumberForTable(value, { suffix })}
	</span>
)

const getChangeValue = (value: number) => {
	if (value < 0) return DeltaTrend.Negative

	if (value === 0) return DeltaTrend.Neutral

	return DeltaTrend.Positive
}

export const changeView = (
	value: number,
	unit: string,
	cellClassNames: string = "",
	invertedMarking = false
) => (
	<span className={twMerge("justify-start text-sm text-black flex items-center", cellClassNames)}>
		<UnitDiff diff={value} unit={unit} trend={getChangeValue(invertedMarking ? -value : value)} />
	</span>
)

export const textView = (value: string | number, cellClassNames: string = "") => (
	<span
		className={twMerge(
			"text-sm text-black text-start flex items-center px-3 overflow-hidden text-ellipsis whitespace-nowrap",
			cellClassNames
		)}
	>
		{value}
	</span>
)

export const listView = (values: string[] | number[], cellClassNames: string = "") => (
	<ul
		className={twMerge(
			"text-sm text-black text-start px-3 overflow-hidden text-ellipsis whitespace-nowrap list-disc list-inside",
			cellClassNames
		)}
	>
		{values.map((value, index) => (
			<li key={index}>{value}</li>
		))}
	</ul>
)

export const weightView = (value: number, cellClassNames: string = "") => (
	<span
		className={twMerge("text-sm text-black flex items-center justify-start px-3", cellClassNames)}
	>
		{formatNumberForTable(value, { suffix: " kg" })}
	</span>
)

export const titlePercentageView = (value: string, cellClassNames: string = "") => (
	<span
		className={twMerge("px-3 pr-8 text-sm text-black flex items-center text-start", cellClassNames)}
	>
		{formatNumberForTable(value, { suffix: " %" })}
	</span>
)

export const smartCapabilitiesView = (value: string) => {
	return textView(translate(`drawerLabels:smartCapabilities_${value}`))
}
