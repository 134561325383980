import { useQuery } from "@tanstack/react-query"
import { Site } from "admin-client-server/src/sites/index"
import { isLocalhost } from "Configs/config"
import { useAuth0 } from "Contexts"

export function useSites({ enabled = true }: { enabled?: boolean } = {}) {
	const { getTokenSilently } = useAuth0()!

	const serverPort = process.env.REACT_APP_SERVER_PORT ?? "4000"
	const host = isLocalhost ? `http://localhost:${serverPort}` : ""
	const { data: sites, isLoading } = useQuery({
		queryFn: async (): Promise<Site[]> => {
			const token = await getTokenSilently()
			const headers: HeadersInit = {
				authorization: token as string,
			}
			return fetch(`${host}/api/sites`, {
				headers,
			}).then(res => res.json())
		},
		enabled,
	})

	return { sites, isLoading }
}
