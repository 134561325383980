import { isStaging } from "Configs/config"

// Drafts are only fetched in staging environments
const draftFilter = isStaging ? "" : '&& !(_id in path("drafts.**"))'

export const wasteTypes = `*[_type == "wasteTypes" ${draftFilter}]
  | order(name asc) {
  "name": name[_key == $lang][0].value,
  _id,
  id,
  iconGrayscale,
  iconColor,
  "englishName": name[_key == "en_US"][0].value,
  "classificationSystem": classificationSystem->{
    id,
    name
  },
  "wasteTypeCategory": wasteTypeCategory->{
    id,
    "name": name[_key == $lang][0].value,
    iconGrayscale,
    iconColor
  }
}`

export const upgradesTypes = `*[_type == "upgradesContainers" ${draftFilter}]
  | order(name asc) {
    _id,
    name,
    "description": description[_key == $lang][0].value,
    "iconColor": icon,
    "vendor": upgradesVendor->{
      name,
      email
    },
    "manufacturer": upgradesManufacturer->{
      name
  },
  "category": upgradesCategories[0]->{
    "name": name[_key == $lang][0].value,
    "description": description[_key == $lang][0].value
  }
}`

export const tenantTypes = `*[_type == "tenantCategories" ${draftFilter}]
  | order(name asc) {
  "name": name[_key == $lang][0].value,
  _id,
  id,
  "expectedWasteTypes": expectedWasteTypes[]->{
    id
  }
}`

export const manufacturers = `*[_type == "upgradesManufacturer" ${draftFilter}] | order(name asc) {
  _id,
  name
}`

export const languages = `*[_type == "insightLanguages" ${draftFilter}]`

export const smartIntegrations = `*[_type == "smartIntegrations" ${draftFilter}] | order(name asc) {
  _id,
  name,
  type,
  "fields": fields[] {
    title,
    type,
    "label": label[_key == $lang][0].value,
    validation
  }
}`

export const wasteTypeClassificationSystems = `*[_type == "wasteTypeClassificationSystems" ${draftFilter}] | order(name asc) {
  _id,
  id,
  name
}`

export const helpSections = `*[_type == "helpSections" ${draftFilter}] | order(order asc) {
  _id,
  type,
  step,
  "description": description[_key == $lang][0].value,
  sections[] {
    "title": title[_key == $lang][0].value,
    "content": content[_key == $lang][0].value,
    subSections[] {
      "title": title[_key == $lang][0].value,
      "content": content[_key == $lang][0].value
    }
  }
}`

export const wasteTypeCategories = `*[_type == "wasteTypeCategories" ${draftFilter}] | order(order asc) {
  _id,
  id,
  "name": name[_key == $lang][0].value,
}`

export const downstreamHandlingTypes = `*[_type == "downstreamHandling" && !defined(parent)]{
  _id,
  "name": name[_key == $lang][0].value,
  "children": *[_type == "downstreamHandling" && parent._ref == ^._id]{
    _id,
    "name": name[_key == $lang][0].value,
    "children": *[_type == "downstreamHandling" && parent._ref == ^._id]{
      _id,
      "name": name[_key == $lang][0].value
    }
  }
}`
