import { getTimeFrame } from "components/pageCards/filterSort/filterTimeFrame"
import moment from "moment-timezone"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTerminalsState } from "States/Terminals"
import { useDataWareHouse } from "Utils/api/datawarehouse/request"
import {
	TerminalAggregatesPeriodsResponse,
	TerminalPeriod,
	TerminalPeriodsResponse,
} from "Utils/api/datawarehouse/responseTypes"
import { TerminalAggregatesPeriods, TerminalPeriods } from "Utils/api/datawarehouse/types"
import { getPeriodicity } from "./DevelopmentInWasteTypes"
import { EmptyView } from "components/EmptyView/EmptyView"
import { timezoneStore, useConfig } from "api/hooks/useConfig"
import { capitalize } from "lodash"
import { ActiveOptions } from "components/pageCards/filterSort/FilterSortContext"
import { FilterSort } from "components/pageCards/filterSort/types"
import { createTimeFilters } from "components/pageCards/filterSort/filterCreators"
import { FilterTimeFrameValue } from "admin-client-server/src/config-api"
import { CardComponentFilterLocation, Card } from "components/pageCards/card"
import { LoadingIndicator } from "Icons/loadingIndicator"
import { BarChart } from "components/BarChart"
import { useTrans } from "translations"
import { useConfigService } from "pages/configuration/useConfigService"
import { useHomeLoadingState } from "pages/home/loadingState"
import { useParams } from "react-router-dom"

const defaultPeriod = FilterTimeFrameValue.Last6FullMonths

export const RecyclingRateOverTime = () => {
	const { language } = useTrans()
	const { isMWM, config } = useConfig()
	const { terminalConfig } = useConfigService()
	const { client } = useParams()
	const { setRecyclingRateLoading } = useHomeLoadingState()
	const { timezone } = timezoneStore()
	const [{ period: [selectedPeriod] = [] }, setActiveOptions] = useState<ActiveOptions<"period">>(
		{} as ActiveOptions<any>
	)
	const filters: FilterSort[] = useMemo(
		() => [
			createTimeFilters({ defaultValue: defaultPeriod }, [
				FilterTimeFrameValue.Last6FullMonths,
				FilterTimeFrameValue.LastFullYear,
			]),
		],
		[]
	)

	const periodFilterValue = useMemo(
		() => getTimeFrame((selectedPeriod?.value as FilterTimeFrameValue) ?? defaultPeriod),
		[selectedPeriod]
	)

	const { currentTerminal } = useTerminalsState()

	const requestParams = useMemo(() => {
		const periodicity: "last6CompletedMonths" | "last12CompletedMonths" = getPeriodicity(
			periodFilterValue.startTimeISO
		)

		const filters = {
			periodicity,
			timeZone: timezone,
		}

		const props: TerminalAggregatesPeriods | TerminalPeriods = currentTerminal
			? {
					endpoint: "terminal/periods",
					terminalId: currentTerminal.id,
					filters,
				}
			: {
					endpoint: "terminal-aggregates-periods",
					filters,
				}

		return props
	}, [currentTerminal, periodFilterValue, timezone])

	const { data: currentData, isLoading, isError } = useDataWareHouse(requestParams)

	useEffect(() => {
		setRecyclingRateLoading(isLoading)
	}, [setRecyclingRateLoading, isLoading])

	const getPeriodValue = useCallback(
		(startTime: string) =>
			capitalize(moment(startTime).tz(timezone).locale(language).format("MMM")),
		[language, timezone]
	)

	const recyData = useMemo(() => {
		if (isMWM) {
			return (
				(currentData as TerminalAggregatesPeriodsResponse)?.periods.map((p: any) => {
					const {
						terminalAggregates: { occurrenceBasedSortingDegree },
					} = p
					const period = getPeriodValue(p.startTime)

					return {
						name: period,
						value: occurrenceBasedSortingDegree * 100,
					}
				}) ?? []
			)
		} else {
			return (
				(currentData as TerminalPeriodsResponse)?.periods.map((p: TerminalPeriod) => {
					const period = getPeriodValue(p.startTime)
					return {
						name: period,
						value: p.terminal.sortingDegree * 100,
					}
				}) || []
			)
		}
	}, [isMWM, currentData, getPeriodValue])

	const colors = useMemo(
		() => ({
			value: terminalConfig?.primaryColor || config?.primaryColor || "#72723B",
		}),
		[config, terminalConfig]
	)

	const customerName = isMWM
		? capitalize(client) ||
			(currentData as TerminalAggregatesPeriodsResponse)?.periods?.[0]?.terminalAggregates
				.clientName
		: ((currentData as TerminalPeriodsResponse)?.periods?.[0]?.terminal.terminalName ?? "-")

	const chart = useMemo(() => {
		if (isLoading) {
			return (
				<div className="flex justify-center items-center min-h-full h-full">
					<LoadingIndicator />
				</div>
			)
		}

		if (isError) {
			return <EmptyView type="failToFetch" className="min-h-[300px]" />
		}

		if (!currentData.metadata.eventsExist) {
			return <EmptyView type="noData" className="min-h-[300px]" />
		}

		const isEmpty = !recyData.find(d => d.value > 0)
		if (isEmpty) {
			return <EmptyView type="noDataCalculationPeriodUncomplete" className="min-h-[300px]" />
		}

		return (
			<BarChart
				legendName={customerName}
				data={recyData}
				unit=" %"
				showAverage={false}
				showLegend={false}
				enableGridY={true}
				axisLeft={{
					format: (val: string | number) => {
						const value = typeof val === "string" ? parseFloat(val) : val
						return value + " %"
					},
				}}
				margin={{ top: 5, right: 0, bottom: 25, left: 40 }}
				maxValue={100}
				colors={colors}
			/>
		)
	}, [recyData, isError, isLoading, customerName, colors, currentData])

	return (
		<Card
			title={"circularityLabels:recyclingRateOverTime"}
			filters={filters}
			onFilterOptionChange={setActiveOptions}
			filterLocation={CardComponentFilterLocation.RIGHT}
		>
			{chart}
		</Card>
	)
}
