import classNames from "classnames"
import { TOptions } from "i18next"
import { CrossIcon } from "Icons/cross"
import { ComponentProps, FC, useState, useEffect, useCallback } from "react"
import { useGlobalAlert } from "States/globalAlert"
import { useTrans } from "translations"
import { Button } from "./button/index"
import { CLIPPED_BUTTON_STYLE } from "constants/general"

type IModalContainer = {
	title?: string
	titleOptions?: TOptions
	onConfirm?: () => void
	onConfirmText?: string
	onConfirmType?: ComponentProps<typeof Button>["color"]
	onConfirmLoading?: boolean
	onConfirmDisabled?: boolean
	disableConfirmButton?: boolean
	onCancel?: () => void
	onCancelType?: ComponentProps<typeof Button>["color"]
	onCancelText?: string
	disableCloseButton?: boolean
	disableCancelButton?: boolean
	className?: string
	actionButton?: ComponentProps<typeof Button>
	contentContainerRef?: React.MutableRefObject<HTMLDivElement | null>
	showDiscardModal?: boolean
	disableOffModalClick?: boolean
	children?: React.ReactNode
	onScroll?: (e: React.UIEvent<HTMLDivElement>) => void
}

const ModalContainer: React.FC<IModalContainer> = ({
	onConfirm = () => null,
	onConfirmText = "actions:save",
	onConfirmType = "primary",
	onConfirmLoading,
	onConfirmDisabled,
	disableConfirmButton,
	onCancel = () => null,
	onCancelText = "actions:cancel",
	onCancelType = "secondary",
	disableCloseButton,
	disableCancelButton,
	title,
	titleOptions,
	children,
	className,
	actionButton,
	contentContainerRef: ref,
	showDiscardModal,
	disableOffModalClick,
	onScroll,
}) => {
	const { t } = useTrans()
	const { setGlobalAlert } = useGlobalAlert()
	const [discardModal, setDiscardModal] = useState(false)

	const onClose = useCallback(() => {
		if (showDiscardModal) return setDiscardModal(true)
		onCancel()
	}, [showDiscardModal, onCancel])

	const handleKeyDown = useCallback(
		(e: KeyboardEvent) => {
			switch (e.key) {
				case "Escape":
					onClose()
					break
				case "Enter":
					if (!disableConfirmButton && !onConfirmDisabled && !onConfirmLoading) {
						// we need to prevent 'submit behavior' in textareas:
						if (e.target instanceof HTMLElement && e.target.tagName === "TEXTAREA") {
							return
						}
						onConfirm()
					}
					break
			}
		},
		[onClose, onConfirm, disableConfirmButton, onConfirmDisabled, onConfirmLoading]
	)

	useEffect(() => {
		window.addEventListener("keydown", handleKeyDown)
		return () => window.removeEventListener("keydown", handleKeyDown)
	}, [handleKeyDown])

	const onDiscardConfirm = () => {
		setGlobalAlert({
			type: "success",
			message: "systemMessages:changesDiscarded",
		})
		onCancel()
	}

	const hideButtons = disableCancelButton && disableConfirmButton

	return (
		<div
			className="relative flex h-full w-full items-center justify-center"
			onMouseDown={e => !disableOffModalClick && e.currentTarget === e.target && onClose()}
			role="dialog"
			aria-modal="true"
			aria-labelledby="modal-title"
		>
			{discardModal && (
				<DiscardModal onCancel={() => setDiscardModal(false)} onConfirm={onDiscardConfirm} />
			)}
			<div
				className={classNames([
					"relative max-h-[calc(90vh-50px)] flex-col bg-white",
					CLIPPED_BUTTON_STYLE,
					className,
				])}
				onClick={(ev: any) => ev.stopPropagation()}
			>
				<div>
					<div className="flex h-16 items-center justify-between border-b-2 border-grey2 px-8 text-2xl">
						{title && <h1 id="modal-title">{t(title, titleOptions)}</h1>}
						{!disableCloseButton && (
							<CrossIcon
								className="cursor-pointer"
								onClick={onClose}
								role="button"
								aria-label={t("actions:close")}
								tabIndex={0}
							/>
						)}
					</div>
				</div>

				<div
					className="h-[calc(100%-138px)] overflow-y-scroll"
					ref={ref}
					onScroll={onScroll}
					tabIndex={0}
					role="region"
					aria-label={t(title || "common:content")}
				>
					<div className={`min-h-full px-8 py-6`}>{children}</div>
				</div>

				{!hideButtons && (
					<div className="flex justify-between gap-3 border-t-2 border-grey2 p-4">
						<div>{actionButton && <Button {...actionButton} className="justify-self-start" />}</div>
						<div className="flex gap-3">
							{!disableCancelButton && (
								<Button label={onCancelText} color={onCancelType} onClick={onClose} />
							)}
							{!disableConfirmButton && (
								<Button
									label={onConfirmText}
									color={onConfirmType}
									onClick={onConfirm}
									loading={onConfirmLoading}
									disabled={onConfirmDisabled}
								/>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default ModalContainer

export const DiscardModal: FC<{
	onCancel?: () => void
	onConfirm?: () => void
}> = ({ onCancel, onConfirm }) => {
	const { t } = useTrans()
	return (
		<div className="absolute top-0 z-10 flex h-full w-full items-center justify-center bg-black bg-opacity-20">
			<ModalContainer
				title="hints:discardChanges"
				showDiscardModal={false}
				onConfirmText="actions:confirm"
				{...{ onConfirm, onCancel }}
				disableOffModalClick
			>
				{t("hints:discardChangesMessage")}
			</ModalContainer>
		</div>
	)
}
