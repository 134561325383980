import { FC } from "react"
import { ContainerAdmin } from "./drawerCards/ContainerAdmin"
import { ContainerBulkMove } from "./drawerCards/ContainerBulkMove"
import { ContainerInformation } from "./drawerCards/ContainerInformation"
import { ContainerSmartCapabilities } from "./drawerCards/ContainerSmartCapabilities"
import { useConfig } from "api/hooks/useConfig"
import { PreFilteringCard } from "./drawerCards/PreFilteringCard"
import { PointChangeHistory } from "./drawerCards/PointChangeHistory"
import { ContainerFillLevel } from "./drawerCards/ContainerFillLevel"
import { useAccessPoints } from "pages/infrastructure/manage/useAccessPoints"
import { ContainerDrawerProps } from "./containerDrawer"
import { ContainerHatches } from "./drawerCards/ContainerHatches"

export const SingleContainerDrawer: FC<ContainerDrawerProps> = ({
	accessParentId,
	containerId,
	depotId,
}) => {
	const { isMWM } = useConfig()

	const { getContainer } = useAccessPoints({ accessParentId })
	const container = getContainer(containerId, depotId)

	return (
		<>
			<ContainerInformation {...{ accessParentId, containerId, depotId }} />
			{isMWM && container?.fillLevel && <ContainerFillLevel fillLevel={container?.fillLevel} />}
			<ContainerHatches {...{ accessParentId, containerId, depotId }} />
			<ContainerSmartCapabilities {...{ accessParentId, containerId, depotId }} />
			{!isMWM && <ContainerBulkMove />}
			{isMWM && (
				<>
					<PreFilteringCard
						entityKey="container"
						eventFilter={encodeURIComponent(JSON.stringify({ containerId }))}
					/>
					<PointChangeHistory id={containerId} name={container?.name || ""} entityKey="container" />
				</>
			)}
			<ContainerAdmin {...{ accessParentId, containerId, depotId }} />
		</>
	)
}
