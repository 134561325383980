import { trpc } from "Utils/trpc"
import { ACCESS_POINT } from "admin-client-server/src/coreApi/accessPoints/types"
import { sortBy, uniqBy } from "lodash"
import { useAccessParents } from "pages/infrastructure/functions"
import React, { useCallback, useEffect, useMemo } from "react"
import { Translate, useTrans } from "translations"
import { WasteTypeData, useConfigService } from "./useConfigService"
import { WasteStreamsTable } from "./wasteStreamsTable"
import { COLOR_CONFIG, COLOR_KEY, CUSTOM_COLORS, ColorsTable, DEFAULT_CONFIG } from "./colorsTable"
import { useConfig } from "api/hooks/useConfig"
import { Button } from "components/button"

interface Props {
	hasUnsavedChanges: boolean
	setHasUnsavedChanges: (hasChanges: boolean) => void
}

export const WasteStreams: React.FC<Props> = ({ hasUnsavedChanges, setHasUnsavedChanges }) => {
	const { t, language } = useTrans()
	const { clientWasteTypeConfig: data, refetchClientWasteTypeConfig: refetch } = useConfigService()
	const { accessParents } = useAccessParents()
	const { config, updateConfig } = useConfig()

	const [updatedWasteTypes, setUpdatedWasteTypes] = React.useState<WasteTypeData[]>([])
	const [updatedColors, setUpdatedColors] = React.useState<COLOR_CONFIG>(DEFAULT_CONFIG)

	useEffect(() => {
		const hasChangedColors = Object.keys(updatedColors).some(
			c => (updatedColors[c as COLOR_KEY] || null) !== (config?.[c as COLOR_KEY] || null)
		)

		const hasChangedWasteTypes = !!updatedWasteTypes.some(wt => {
			const originalWT = data?.find(d => d.wasteTypeCode === wt.wasteTypeCode)

			if (!originalWT) {
				return wt.names.some(n => n.displayName !== "") || wt.color !== ""
			} else {
				return (
					wt.names.some(
						n =>
							n.displayName !== originalWT.names.find(owt => owt.locale === n.locale)?.displayName
					) ||
					wt.color !== originalWT.color ||
					wt.downstreamHandlingId !== originalWT.downstreamHandlingId ||
					wt.co2factor !== originalWT.co2factor
				)
			}
		})

		setHasUnsavedChanges(hasChangedColors || hasChangedWasteTypes)
	}, [config, data, updatedColors, updatedWasteTypes, setHasUnsavedChanges])

	useEffect(() => {
		let updatedColors: { [k in COLOR_KEY]: string } = { ...DEFAULT_CONFIG }

		CUSTOM_COLORS.forEach(color => {
			updatedColors[color.key] = config[color.key] || ""
		})

		setUpdatedColors(updatedColors)
	}, [config, setUpdatedColors])

	const { mutate: updateWasteTypeConfig, isLoading } =
		trpc.config.updateWasteTypeConfig.useMutation({
			onSuccess: () => {
				refetch()
				setHasUnsavedChanges(false)
			},
		})
	const containers = useMemo(() => {
		return sortBy(
			uniqBy(
				accessParents.flatMap(ap => ap.containers as ACCESS_POINT[]),
				"wasteType.code"
			),
			"wasteType.code"
		)
	}, [accessParents])

	const onSave = useCallback(() => {
		if (updatedWasteTypes.length) {
			updateWasteTypeConfig(updatedWasteTypes.map(uwt => ({ ...uwt, locale: language })))
		}

		if (
			Object.values(updatedColors).filter(Boolean).length ||
			(!!config && Object.values(config).filter(Boolean).length)
		) {
			updateConfig(updatedColors)
		}
	}, [updateWasteTypeConfig, language, updatedWasteTypes, updatedColors, updateConfig, config])

	const onResetAll = useCallback(() => {
		setUpdatedWasteTypes(
			data?.map(el => ({
				...el,
				names: el.names.map(n => ({ ...n, displayName: "" })),
				color: "",
			})) || []
		)
		setUpdatedColors(DEFAULT_CONFIG)
	}, [setUpdatedWasteTypes, data])

	return (
		<>
			<div className="mt-4">
				<ul className="list-disc list-inside ml-2">
					<Translate
						i18nKey="hints:wasteStreamConfig"
						children={[
							<li />,
							<li>
								{
									// eslint-disable-next-line jsx-a11y/anchor-has-content
									<a
										href="/infrastructure/manage"
										style={{ textDecoration: "underline" }}
										rel="noreferrer"
									/>
								}
							</li>,
							<li />,
						]}
					/>
				</ul>
			</div>
			<ColorsTable
				updatedColors={updatedColors}
				setUpdatedColors={setUpdatedColors}
				setHasUnsavedChanges={setHasUnsavedChanges}
			/>
			<WasteStreamsTable
				containers={containers}
				data={data}
				setHasUnsavedChanges={setHasUnsavedChanges}
				onSave={onSave}
				updateLoading={isLoading}
				updatedWasteTypes={updatedWasteTypes}
				setUpdatedWasteTypes={setUpdatedWasteTypes}
			/>

			<div className="bg-white w-full sticky bottom-0 pb-4">
				<hr></hr>
				<div className="mt-4 text-sm flex justify-between">
					<Button
						label={t("actions:save")}
						onClick={onSave}
						loading={false}
						disabled={!hasUnsavedChanges}
					/>
					<div className="mr-4 self-center underline cursor-pointer" onClick={onResetAll}>
						{t("actions:resetAll")}
					</div>
				</div>
			</div>
		</>
	)
}
