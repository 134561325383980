import { Button } from "components/button/index"
import { Card, CardComponentVariant } from "components/pageCards/card"
import { useModal } from "Contexts"
import { AlertModal } from "pages/infrastructure/modals/alertModal"
import { DeleteContainerModal } from "pages/infrastructure/modals/deleteContainerModal"
import { FC, useMemo } from "react"
import { useParams } from "react-router-dom"
import { useGlobalAlert } from "States/globalAlert"
import { useTrans } from "translations"
import { getInfrastructureUrl } from "Utils/getInfrastructureUrl"
import { ContainerDrawerProps } from "../containerDrawer"
import { useAccessPoints } from "pages/infrastructure/manage/useAccessPoints"
import { useNavigate } from "Utils/useNavigate"

export const ContainerAdmin: FC<ContainerDrawerProps> = ({
	accessParentId,
	containerId,
	depotId,
}) => {
	const { t } = useTrans()
	const { showModal } = useModal()
	const navigate = useNavigate()
	const { setGlobalAlert } = useGlobalAlert()

	const { terminalId: urlTerminalId, accessParentId: urlAccessParentId } = useParams<{
		terminalId?: string
		accessParentId?: string
	}>()

	const { getContainer } = useAccessPoints({ accessParentId })
	const container = getContainer(containerId, depotId)

	const smartIntegrations = useMemo(
		() => JSON.parse(container?.smartIntegrations ?? "[]"),
		[container]
	)
	const hasSmartInfrastructure = useMemo(
		() => (smartIntegrations?.length ?? 0) > 0,
		[smartIntegrations]
	)

	if (!container) return null

	const openDeletionModal = () => {
		const alertModal = (
			<AlertModal
				title={t("actions:deleteContainer")}
				hint={t("hints:cannotDeleteSmartContainer")}
			/>
		)
		const deleteModal = (
			<DeleteContainerModal
				{...{
					containerId: container.id || "",
					accessParentId,
					containerName: container?.name,
					onSuccess: () => {
						setGlobalAlert({
							type: "success",
							message: "systemMessages:containerDeleted",
						})
						if (accessParentId) {
							const url = getInfrastructureUrl({
								terminalId: urlTerminalId,
								accessParentId: urlAccessParentId,
							})
							navigate(url)
						}
					},
				}}
			/>
		)
		showModal(hasSmartInfrastructure ? alertModal : deleteModal)
	}

	return (
		<Card title={"drawerLabels:admin"} variant={CardComponentVariant.DRAWER}>
			<Button
				label={"actions:deleteContainer"}
				onClick={openDeletionModal}
				color="black"
				className="float-right"
			/>
		</Card>
	)
}
