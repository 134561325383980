import { Button } from "components/button"
import moment from "moment"
import React from "react"
import { useTrans } from "translations"
import create from "zustand"

type UsePdfPreview = {
	pdfPreviewOpen: boolean
	setPdfPreviewOpen: (value: boolean) => void
}

export const usePdfPreview = create<UsePdfPreview>(set => ({
	pdfPreviewOpen: false,
	setPdfPreviewOpen: _pdfPreviewOpen => set(() => ({ pdfPreviewOpen: _pdfPreviewOpen })),
}))

type Props = {
	generatePdf: () => void
	close: () => void
	view: JSX.Element
}

export const PdfPreview: React.FC<Props> = ({ generatePdf, close, view }: Props) => {
	const { t } = useTrans()
	return (
		<div className="absolute z-20 left-0 top-0 bottom-0 right-0 w-full bg-grey1 justify-center">
			<div className="m-auto my-4 px-4 relative max-w-[1500px] flex justify-between items-center">
				<Button
					label="actions:close"
					className="mx-4 float-right"
					onClick={close}
					color="secondary"
				/>
				<h1 className="text-2xl absolute left-1/2 transform -translate-x-1/2">
					{t("genericLabels:pdfPreview")}
				</h1>
				<Button label="actions:generatePdf" className="mx-4 float-left" onClick={generatePdf} />
			</div>
			<div className="relative flex justify-center items-center w-full h-full overflow-scroll">
				<div className="absolute top-0 max-h-[2500px] w-[1500px] bg-grey1 pb-10" id="page-content">
					<h1 className="text-2xl w-full align-center text-center mb-4">
						Export {moment().format("YYYYMMDD")}
					</h1>
					<div className="grid grid-cols-2 gap-4 justify-items-stretch bg-grey1 pt-0 p-8 light text-black">
						{view}
					</div>
				</div>
			</div>
		</div>
	)
}
