import { CardFilterTimeFrameIso } from "components/pageCards/filterSort/filterTimeFrame"
import ExcelJS from "exceljs"
import { saveAs } from "file-saver"
import { translate } from "translations/functions/hook"
import { FetchedWasteTypes } from "Utils/api/sanity/types"
import { Customer } from "Utils/api/datawarehouse/responseTypes"
import moment from "moment-timezone"
import { getFileName, getWasteTypeName } from "Utils/commonExportFunctions"
import { formatWeight } from "Utils/formatFunctions"
import { sortAlphabeticallyByProperty } from "Utils/sorting"
import { WasteTypeConfig } from "pages/configuration/useConfigService"
import { timezoneStore } from "api/hooks/useConfig"
import { TenantType } from "."
import { FlatDownstreamData } from "Utils/downstreamHandlingUtils"

type CsvProps = {
	tenants: TenantType[]
	tenantDetails: Customer[]
	wasteTypes: string[]
	timeframe: CardFilterTimeFrameIso
	sanityWasteTypes: FetchedWasteTypes
	wasteTypeConfig: WasteTypeConfig[]
	showCustomerUnits: boolean
	flatDownstreamData: FlatDownstreamData
}

type ColumnType = {
	header: string
	key: string
}

const CSV_COLUMNS = (showCustomerUnits: boolean): ColumnType[] =>
	[
		{
			header: "exportLabels:dateFrom",
			key: "from",
		},
		{
			header: "exportLabels:dateTo",
			key: "to",
		},
		{
			header: "entities:customer",
			key: "tenantName",
		},
		showCustomerUnits
			? {
					header: "entities:customerUnit",
					key: "unitName",
				}
			: undefined,
		{
			header: "genericLabels:tenantId",
			key: "tenantId",
		},
		{
			header: "exportLabels:wasteType",
			key: "wasteTypeName",
		},
		{
			header: "exportLabels:wasteTypeCode",
			key: "wasteTypeCode",
		},
		{
			header: "exportLabels:amount",
			key: "amount",
		},
		{
			header: "exportLabels:amountCO2",
			key: "amountCO2",
		},
		{
			header: "exportLabels:downstreamHandling",
			key: "downstreamHandling",
		},
	].filter(Boolean) as ColumnType[]

type RowType = {
	from: string
	to: string
	tenantName: string
	unitName: string
	tenantId: string
	wasteTypeName: string
	wasteTypeCode: string
	amount: number
	amountCO2: number
	downstreamHandling: string
}

const getRowData = ({
	tenants,
	tenantDetails,
	wasteTypes,
	timeframe,
	sanityWasteTypes,
	wasteTypeConfig,
	flatDownstreamData,
}: CsvProps) => {
	const { timezone } = timezoneStore.getState()
	const rows: RowType[] = []

	tenants.forEach(tenant => {
		const id = tenant.parentId !== "" ? tenant.parentId : tenant.id
		const customer = tenantDetails.find(el => el.customerId === id)

		if (!customer) return

		customer.wasteTypes
			.filter(wt => wasteTypes.includes(wt.code))
			.map(wt => ({
				...wt,
				name:
					wasteTypeConfig.find(el => el.wasteTypeCode === wt.code)?.name ||
					getWasteTypeName(sanityWasteTypes, wt.code),
			}))
			.sort(sortAlphabeticallyByProperty)
			.forEach(wt => {
				const wasteConfig = wasteTypeConfig.find(el => el.wasteTypeCode === wt.code)
				const amount = Number(tenant[wt.code])

				rows.push({
					from: moment(timeframe.startTimeISO).tz(timezone).format("YYYY-MM-DD HH:mm:ss"),
					to: moment(timeframe.endTimeISO || undefined)
						.tz(timezone)
						.format("YYYY-MM-DD HH:mm:ss"),
					tenantName: tenant.name,
					unitName: tenant.unitName,
					tenantId: tenant.id,
					wasteTypeName: wt.name,
					wasteTypeCode: wt.code,
					amount: formatWeight(amount),
					amountCO2: wasteConfig?.co2factor ? formatWeight(wasteConfig?.co2factor! * amount) : 0,
					downstreamHandling: flatDownstreamData[wasteConfig?.downstreamHandlingId!]?.name || "",
				})
			})
	})

	return rows
}

const generateCsvFile = (props: CsvProps) => {
	const workbook = new ExcelJS.Workbook()
	const sheet = workbook.addWorksheet(
		getFileName("exportLabels:reportingSheetFileName", props.timeframe)
	)
	sheet.columns = CSV_COLUMNS(props.showCustomerUnits).map((column: ColumnType) => ({
		header: translate(column.header),
		key: column.key,
		width: 20,
		style: {
			font: {
				bold: true,
			},
		},
	}))

	const rowData: RowType[] = getRowData(props)
	sheet.addRows(rowData)

	return workbook
}

export const exportCsv = async (props: CsvProps) => {
	const fileName = getFileName("exportLabels:reportingFileName", props.timeframe)
	const workbook = generateCsvFile(props)

	const csv = await workbook.csv.writeBuffer()
	saveAs(new Blob([csv], { type: "text/csv;charset=UTF-8" }), fileName)
}
