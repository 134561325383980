import { useCommonEntitiesStore } from "States/commonEntities"
import { trpc } from "Utils/trpc"
import { isEmpty } from "lodash"
import { useCallback, useMemo } from "react"
import { buildContainerData, containerDataToProps, depotContainerDataToProps } from "../functions"
import { useAccessParentsWithPoints } from "./useAccessParentsWithPoints"

export const useAccessPoints = ({ accessParentId }: { accessParentId: string }) => {
	const { wasteTypes } = useCommonEntitiesStore()
	const { refetch: refetchAccessParents, isLoading: isLoadingAccesParents } =
		useAccessParentsWithPoints()

	const { mutateAsync: updateAccessPoint } = trpc.accessPoints.update.useMutation()

	const {
		data: accessParentChildren,
		isFetching: isLoading,
		isError,
		refetch,
	} = trpc.accessPoints.getAllWithParentId.useQuery(
		{ accessParentId },
		{ enabled: !isEmpty(accessParentId) }
	)

	const sortedContainers = useMemo(
		() =>
			buildContainerData(
				accessParentChildren?.containers,
				accessParentId,
				wasteTypes,
				containerDataToProps
			),
		[accessParentChildren?.containers, accessParentId, wasteTypes]
	)

	const sortedDepots = useMemo(
		() =>
			accessParentChildren?.depots?.map(depot => {
				const sortedDepotContainers = buildContainerData(
					depot.containers,
					accessParentId,
					wasteTypes,
					depotContainerDataToProps
				)
				return {
					...depot,
					status: depot.status?.toUpperCase(),
					containers: sortedDepotContainers,
				}
			}),
		[accessParentChildren?.depots, accessParentId, wasteTypes]
	)

	const getDepot = useCallback(
		(depotId: string) => sortedDepots?.find(d => d.id === depotId),
		[sortedDepots]
	)

	const getDepotContainers = useCallback(
		(depotId: string) => sortedDepots?.find(d => d.id === depotId)?.containers,
		[sortedDepots]
	)

	const getContainer = useCallback(
		(containerId: string, depotId?: string | null) => {
			return depotId
				? getDepotContainers(depotId)?.find(c => c.id === containerId)
				: sortedContainers.find(c => c.id === containerId)
		},
		[getDepotContainers, sortedContainers]
	)

	const refetchAll = useCallback(() => {
		return Promise.all([refetch(), refetchAccessParents()])
	}, [refetch, refetchAccessParents])

	return {
		containers: sortedContainers,
		depots: sortedDepots,
		isLoading,
		isError,
		getDepot,
		getDepotContainers,
		getContainer,
		refetch,
		refetchAll,
		isLoadingAll: isLoading || isLoadingAccesParents,
		updateAccessPoint,
	}
}
