import { Site as SelectedSite } from "admin-client-server/src/sites/index"
/** Sets the following headers for use in multi-tenant environments:
 * - x-client-identifier: selectedSite.siteName
 * - x-namespace: selectedSite.namespace
 */
export const setMultiTenantHeaders = (selectedSite: SelectedSite | undefined, headers: any) => {
	if (selectedSite) {
		if (selectedSite.siteName) {
			headers["x-client-identifier"] = selectedSite.siteName
		}
		if (selectedSite.namespace) {
			headers["x-namespace"] = selectedSite.namespace
		}
	}
}
