import classNames from "classnames"
import { ComponentProps, FC } from "react"
import { Link } from "react-router-dom"
import { useTrans } from "translations"
import { Button } from "./button/index"
import { isStaging } from "Configs/config"

interface PageProps {
	title?: string
	/** Maximum number of columns to show. Columns will collapse on smaller screens. */
	children?: React.ReactNode
	columns?: 1 | 2
	fullHeight?: boolean
	breadcrumbsPaths?: IPageBreadcrumbProps["prevPaths"]
	button?: ComponentProps<typeof Button>
	link?: {
		label: string
		uri: string
		className?: string
	}
}

export const Page: FC<PageProps> = ({
	title,
	children,
	columns = 1,
	fullHeight,
	breadcrumbsPaths,
	button,
	link,
}) => {
	const { t } = useTrans()

	const renderBreadcrumbs = !!breadcrumbsPaths

	return (
		<div className={classNames("w-full flex-col p-4 pt-5", fullHeight ? "h-mainPage" : "h-full")}>
			<div className="flex items-center justify-between">
				{title && (
					<div className="mt-4 flex flex-col-reverse lg:flex-row items-baseline gap-6 mb-4">
						<h1 className={classNames("mb-3 text-3xl sm:text-4xl", isStaging && "text-carrot")}>
							{t(title)}
						</h1>
						{renderBreadcrumbs && (
							<Breadcrumbs prevPaths={breadcrumbsPaths} currentPathName={t(title)} />
						)}
					</div>
				)}
				<div className="flex items-center gap-6 ml-4">
					{link && (
						<Link className={classNames("text-sm underline", link.className)} to={link.uri}>
							{t(link?.label)}
						</Link>
					)}
					{button && <Button {...button} />}
				</div>
			</div>
			<div
				className={classNames("grid grid-cols-1 bg-grey1", {
					"xl:grid-cols-2": columns === 2,
					"h-full": fullHeight,
					"overflow-hidden": fullHeight,
					"grid-rows-1": fullHeight,
				})}
			>
				{children}
			</div>
		</div>
	)
}

export interface IPageBreadcrumbProps {
	prevPaths?: {
		url: string
		name: string
	}[]
	currentPathName?: string
}

// TODO: create a navigation map and get breadcrumbs based on that
const Breadcrumbs: FC<IPageBreadcrumbProps> = ({ prevPaths, currentPathName }) => {
	return (
		<div className="flex h-9 gap-1">
			{prevPaths?.map(({ name, url }, index) => (
				<div key={`Breadcrumb_${index}`}>
					<Link className="underline" to={url}>
						{name}
					</Link>
					<span className="pl-1">/</span>
				</div>
			))}
			{!!currentPathName && currentPathName}
		</div>
	)
}
