import { Button } from "components/button"
import { useAuth0 } from "Contexts"
import { IconError } from "Icons/Error"
import { Translate, useTrans } from "translations"

type Props = {
	errorTitle?: string
	errorMessage?: string
}

export const ErrorPage: React.FC<Props> = ({ errorTitle = "errors:failToFetch", errorMessage }) => {
	const { t } = useTrans()
	const { logout } = useAuth0()!

	return (
		<div className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-grey1">
			<Button
				className="absolute top-4 right-4"
				onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
				label={t("actions:logout")}
			/>

			<h1 className="w-[920px] text-center font-signifier text-3xl">
				<div className="flex justify-center mb-5">
					<IconError width={60} height={60} />
				</div>
				<div className="font-semibold mb-3">
					<Translate i18nKey={errorTitle} />
				</div>
				{errorMessage && (
					<div className="mt-5 mb-8 text-xl text-carrotRed">
						<Translate i18nKey={errorMessage} />
					</div>
				)}
				<Translate
					i18nKey={"errors:somethingWentWrong"}
					components={[
						// Anchor content is added by the translation
						// eslint-disable-next-line jsx-a11y/anchor-has-content
						<a href="mailto:support@carrot.tech" className="underline" />,
					]}
				/>
			</h1>
		</div>
	)
}

export default ErrorPage
