import { useTerminals } from "api/hooks/useTerminals"
import { ImportView } from "components/ImportComponents/ImportView"
import { Template } from "components/ImportComponents/types"
import { Page } from "components/Page"
import React, { useCallback, useMemo } from "react"
import { useTrans } from "translations"
import { trpc } from "Utils/trpc"
import { IMPORT_STEPS } from "./importSteps"
import { useCommonEntitiesStore } from "States/commonEntities"
import { IImportContext } from "components/ImportComponents/ImportContext"
import { useAccessParents } from "../functions"
import { useNavigate } from "Utils/useNavigate"
import { getTemplates } from "./templates"
import { addToSystem } from "./addToSystem"
import { useConfig } from "api/hooks/useConfig"
import { useModal } from "Contexts"
import { LoadingIndicator } from "Icons/loadingIndicator"
import { useTerminalsState } from "States/Terminals"

export const ImportInfrastructure: React.FC = () => {
	const { t } = useTrans()
	const navigate = useNavigate()
	const { terminals } = useTerminalsState()
	const { refetchTerminals } = useTerminals()
	const { wasteTypes, wasteTypeClassificationSystems } = useCommonEntitiesStore()
	const { data: accessParents, isLoading: isLoadingAccessParents } =
		trpc.accessParents.getAllWithParentId.useQuery()
	const { refetch: refetchAccessParents } = useAccessParents()
	const { config, isMWM } = useConfig()
	const { showModal } = useModal()
	const { data: depots, isLoading: isLoadingDepots } = trpc.depots.getAllWithParentIds.useQuery()
	const { mutateAsync: addAllContactPersonsToActionReportForClient } =
		trpc.config.addAllContactPersonsToActionReportForClient.useMutation()

	const { mutateAsync: bulkCreateTerminals } = trpc.terminals.bulkCreate.useMutation()
	const { mutateAsync: bulkCreateAccessParents } = trpc.accessParents.bulkCreate.useMutation()
	const { mutateAsync: bulkCreateDepots } = trpc.depots.bulkCreate.useMutation()
	const { mutateAsync: bulkCreateAccessPoints } = trpc.accessPoints.bulkCreate.useMutation()

	const isLoading = isLoadingAccessParents || isLoadingDepots

	const breadcrumbs = useMemo(
		() => [{ url: "/infrastructure/manage", name: t("sidebarLabels:manageInfrastructure") }],
		[t]
	)

	const templates: Template[] = useMemo(
		() =>
			getTemplates({
				terminals,
				wasteTypeClassificationSystems,
				wasteTypes,
				accessParents,
				defaultWTCS: config?.wasteTypeClassificationSystemId,
				isMWM,
				showModal,
				useWasteSuctionSystem: !!config?.useWasteSuctionSystem,
				depots,
			}),
		[
			terminals,
			wasteTypeClassificationSystems,
			wasteTypes,
			accessParents,
			config,
			isMWM,
			showModal,
			depots,
		]
	)

	const addToSystemFunction = useCallback(
		(context: IImportContext) =>
			addToSystem({
				context,
				wasteTypes,
				refetchTerminals,
				refetchAccessParents,
				isMWM,
				useWasteSuctionSystem: !!config?.useWasteSuctionSystem,
				addAllContactPersonsToActionReportForClient,
				createFunctions: {
					bulkCreateTerminals,
					bulkCreateAccessParents,
					bulkCreateDepots,
					bulkCreateAccessPoints,
				},
			}),
		[
			wasteTypes,
			refetchTerminals,
			refetchAccessParents,
			isMWM,
			config,
			addAllContactPersonsToActionReportForClient,
			bulkCreateTerminals,
			bulkCreateAccessParents,
			bulkCreateDepots,
			bulkCreateAccessPoints,
		]
	)

	const onCancel = useCallback(() => {
		navigate("/infrastructure/manage")
	}, [navigate])

	return (
		<Page title="importLabels:importInfrastructure" fullHeight breadcrumbsPaths={breadcrumbs}>
			{isLoading ? (
				<div className="flex flex-col justify-center items-center min-h-full h-full">
					<LoadingIndicator />
					<p>{t("importLabels:preparingImport")}</p>
				</div>
			) : (
				<ImportView
					steps={IMPORT_STEPS}
					templates={templates}
					onCancel={onCancel}
					addToSystem={addToSystemFunction}
				/>
			)}
		</Page>
	)
}
