import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { isEmpty } from "lodash"
import { useForm } from "react-hook-form"
import { useGlobalAlert } from "States/globalAlert"
import FormInput from "components/Form/FormInput"
import { useTrans } from "translations"
import { useMemo } from "react"
import SelectInput from "components/Form/SelectInput"
import { trpc } from "Utils/trpc"
import { POINT_STATUSES } from "admin-client-server/src/coreApi/common/constants"

type DepotFormData = {
	name: string
	status: string
}

type Depot = {
	id: string
	name: string
	status: string | undefined
}

export const EditDepotModal: React.FC<{
	depot: Depot
	onSuccess: () => void
}> = ({ depot, onSuccess }) => {
	const { t } = useTrans()
	const { hideModal } = useModal()
	const { ref: modalRef } = useModal()
	const { setGlobalAlert } = useGlobalAlert()

	const { mutate: updateDepot, isLoading } = trpc.depots.update.useMutation()

	const formHandler = useForm<DepotFormData>({
		defaultValues: {
			name: depot.name,
			status: depot.status,
		},
	})

	const {
		register,
		control,
		formState: { errors, isValid, isDirty },
		handleSubmit,
	} = formHandler

	const statusOptions = useMemo(
		() =>
			POINT_STATUSES.map(value => ({
				label: t(`status:${value}`),
				value,
				translate: true,
			})),
		[t]
	)

	const onSubmit = handleSubmit(depotFormData => {
		const { name, status } = depotFormData

		updateDepot(
			{
				id: depot.id,
				name,
				status,
			},
			{
				onSuccess: () => {
					setGlobalAlert({
						type: "success",
						message: "systemMessages:groupUpdated",
					})
					onSuccess()
					hideModal()
				},
				onError: error => {
					setGlobalAlert({
						type: "error",
						message: error.message,
					})
				},
			}
		)
	})

	return (
		<ModalContainer
			title="actions:newGroup"
			onConfirmText={"actions:save"}
			onConfirm={() => onSubmit()}
			onCancel={hideModal}
			onConfirmLoading={isLoading}
			onConfirmDisabled={!isEmpty(errors) || !isValid}
			className="w-3/4 sm:w-1/2 lg:w-1/3"
			showDiscardModal={isDirty}
		>
			<div className="mb-6 flex flex-col gap-y-5">
				<FormInput
					name="name"
					label={t("formLabels:groupName")}
					register={register}
					error={errors?.name}
					required
				/>
				<SelectInput
					label={t("formLabels:status")}
					name="status"
					options={statusOptions}
					control={control}
					menuPortalTarget={modalRef.current}
					required
				/>
			</div>
		</ModalContainer>
	)
}
