import { useEffect, useMemo, useState } from "react"
import { BrowserRouter as Router } from "react-router-dom"

import { Spinner } from "./components"
import Main from "./Main"

import { useAuth0 } from "./Contexts"

import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { httpBatchLink } from "@trpc/client"
import { useApiStore } from "States/api"
import { useCommonEntitiesStore } from "States/commonEntities"
import { useTrans } from "translations"
import { requestSanity } from "Utils/api/sanity/request"
import {
	FetchedDownstreamHandlingTypes,
	FetchedHelpSections,
	FetchedLanguages,
	FetchedManufacturersTypes,
	FetchedTenantTypes,
	FetchedUpgradeTypes,
	FetchedWasteTypeClassificationSystems,
	FetchedWasteTypes,
	FetchedWasteTypeCategories,
	SmartIntegrations,
} from "Utils/api/sanity/types"
import "./App.css"
import "moment/min/locales"
import { GlobalAlert } from "./components/AlertLine/GlobalAlert"
import { ContextMenu } from "./components/ContextMenu"
import { ConfirmationDialog } from "./components/Overlays/ConfirmationDialog"
import { isLocalhost, isStaging } from "./Configs/config"
import { trpc } from "./Utils/trpc"
import { MultiSelectProvider } from "components/pageCards/multiListTable/multiSelectContext"
import { capitalize, orderBy } from "lodash"
import SuperJSON from "superjson"
import { useSelectedSite } from "api/hooks/useSelectedSite"
import SitePicker from "pages/sites"
import { isMultiTenant } from "./Configs/config"
import { usePostHog } from "posthog-js/react"
import { useTerminalsState } from "States/Terminals"
import { setMultiTenantHeaders } from "Utils/headerUtils"

export const Loading = () => {
	const { t, isTranslationLoaded } = useTrans()
	return (
		<div id="initapp">
			<Spinner />
			<h1>{isTranslationLoaded && t("hints:waitingForLoginServer")}</h1>
		</div>
	)
}

const reactQueryClientBase = new QueryClient({
	defaultOptions: {
		queries: {
			retry: 0, // No query retries
			staleTime: 1000 * 60 * 60, // 1 hour cache
			cacheTime: 1000 * 60 * 60, // 1 hour cache
		},
	},
})

const App = () => {
	const { loading, isAuthenticated, loginWithRedirect, getTokenSilently, user } = useAuth0()!
	const { currentTerminal } = useTerminalsState()
	const posthog = usePostHog()

	const { language, isTranslationLoaded } = useTrans()
	const { reactQueryClient, setReactQueryClient } = useApiStore()
	const {
		setWasteTypes,
		setUpgrades,
		setDownstreamHandling,
		setTenantCategories,
		setManufacturers,
		setLanguages,
		setSmartIntegrations,
		setWasteTypeClassificationSystems,
		setHelpSections,
		setWasteTypeCategories,
		languages,
	} = useCommonEntitiesStore()
	const { selectedSite, siteName } = useSelectedSite()

	useEffect(() => {
		if (!isStaging && user) {
			posthog.identify(user.sub, {
				name: user.name,
				email: user.email,
			})

			if (currentTerminal?.id) {
				posthog.group("building", currentTerminal.id, { name: currentTerminal.name })
			}

			if (siteName) {
				posthog.group("client", siteName, {
					name: capitalize(siteName),
				})
			}
		}
	}, [posthog, user, siteName, currentTerminal])

	useEffect(() => {
		setReactQueryClient(reactQueryClientBase)
	}, [setReactQueryClient])

	const showSitePicker = useMemo(() => !selectedSite?.siteName && isMultiTenant, [selectedSite])

	const serverPort = process.env.REACT_APP_SERVER_PORT ?? "4000"

	const [queryClient] = useState(() => new QueryClient())
	const trpcClient = useMemo(
		() =>
			trpc.createClient({
				transformer: SuperJSON,
				links: [
					httpBatchLink({
						url: isLocalhost ? `http://localhost:${serverPort}/api/trpc` : "/api/trpc",
						async headers() {
							let headers: { [key: string]: string | undefined } = {
								language,
								authorization: await getTokenSilently(),
							}
							setMultiTenantHeaders(selectedSite, headers)

							return headers
						},
					}),
				],
			}),
		[selectedSite, language, serverPort, getTokenSilently]
	)

	useEffect(() => {
		if (reactQueryClient) {
			requestSanity({ query: "languages" })
				.then(data => setLanguages(orderBy(data as FetchedLanguages, "code")))
				.catch(console.error)
		}
	}, [setLanguages, reactQueryClient])

	// Fetch and store container types
	useEffect(() => {
		if (reactQueryClient && languages) {
			requestSanity({ query: "wasteTypes", locale: language })
				.then(data => setWasteTypes(data as FetchedWasteTypes))
				.catch(console.error)
			requestSanity({ query: "upgradesTypes", locale: language })
				.then(data => setUpgrades(data as FetchedUpgradeTypes))
				.catch(console.error)
			requestSanity({ query: "downstreamHandlingTypes", locale: language })
				.then(data => setDownstreamHandling(data as FetchedDownstreamHandlingTypes))
				.catch(console.error)
			requestSanity({ query: "tenantTypes", locale: language })
				.then(data => setTenantCategories(data as FetchedTenantTypes))
				.catch(console.error)
			requestSanity({ query: "manufacturers", locale: language })
				.then(data => setManufacturers(data as FetchedManufacturersTypes))
				.catch(console.error)
			requestSanity({ query: "smartIntegrations", locale: language })
				.then(data => setSmartIntegrations(data as SmartIntegrations))
				.catch(console.error)
			requestSanity({ query: "wasteTypeClassificationSystems", locale: language })
				.then(data =>
					setWasteTypeClassificationSystems(data as FetchedWasteTypeClassificationSystems)
				)
				.catch(console.error)
			requestSanity({ query: "helpSections", locale: language })
				.then(data => setHelpSections(data as FetchedHelpSections))
				.catch(console.error)
			requestSanity({ query: "wasteTypeCategories", locale: language })
				.then(data => setWasteTypeCategories(data as FetchedWasteTypeCategories))
				.catch(console.error)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reactQueryClient, setWasteTypes, language, languages])

	const clientsInitialized = useMemo(
		() => Boolean(queryClient && trpcClient && reactQueryClient && isTranslationLoaded),
		[reactQueryClient, queryClient, trpcClient, isTranslationLoaded]
	)

	if (loading) {
		console.log("Waiting for auth0 to initialize")
		return <Loading />
	}

	if (!isAuthenticated) {
		console.log("Is not authenticated, need to log in")
		loginWithRedirect({
			appState: {
				targetUrl: `${window.location.pathname}${window.location.search}`,
			},
		})
		return <></>
	}

	if (!clientsInitialized) {
		console.log("Waiting for clients to initialize")
		console.log(`Query client: ${!!queryClient}`)
		console.log(`TRPC client: ${!!trpcClient}`)
		console.log(`React Query client: ${!!reactQueryClient}`)
		return <Loading />
	}

	return (
		<trpc.Provider client={trpcClient} queryClient={queryClient}>
			<QueryClientProvider client={queryClient}>
				<QueryClientProvider client={reactQueryClientBase}>
					<Router>
						<MultiSelectProvider>{showSitePicker ? <SitePicker /> : <Main />}</MultiSelectProvider>
						<ConfirmationDialog />
						<ContextMenu />
						<GlobalAlert />
					</Router>
				</QueryClientProvider>
			</QueryClientProvider>
		</trpc.Provider>
	)
}

export default App
