import { HeaderGroup } from "@tanstack/react-table"
import React from "react"
import { ColumnHeader } from "./ColumnHeader"
import { DataTableDataType } from ".."

type TableHeaderProps = {
	headers: HeaderGroup<DataTableDataType>[]
}

export const TableHeader: React.FC<TableHeaderProps> = ({ headers }) => {
	return (
		<thead className="sticky bg-white top-0 z-[9]">
			{headers.map(headerGroup => (
				<tr key={headerGroup.id} className="h-12 w-fit bg-white border-b border-grey3">
					{headerGroup.headers.map(header => (
						<ColumnHeader key={`${header.id}_${header.index}`} {...{ header }} />
					))}
				</tr>
			))}
		</thead>
	)
}
