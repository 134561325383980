import { QueryClient } from "@tanstack/react-query"
import create from "zustand"

export type UseApiStore = {
	reactQueryClient: QueryClient | null
	setReactQueryClient: (_reactQueryClient: QueryClient) => void
}

export const useApiStore = create<UseApiStore>(set => ({
	reactQueryClient: null,
	setReactQueryClient: _reactQueryClient => set(() => ({ reactQueryClient: _reactQueryClient })),
}))
