import { Header, flexRender } from "@tanstack/react-table"
import ArrowUp from "Icons/ArrowUp"
import classNames from "classnames"
import React, { useCallback, useRef } from "react"
import { twMerge } from "tailwind-merge"
import { ExpandedColumnDef } from ".."
import { v4 } from "uuid"
import { Tooltip } from "react-tooltip"
import getCommonPinningStyles from "../utils/getCommonPinningStyles"

type ColumnHeaderProps = {
	header: Header<any, unknown>
}

export const ColumnHeader: React.FC<ColumnHeaderProps> = ({ header }) => {
	const isCheckbox = header.id === "checkbox" || header.id === "expand"
	const ref = useRef<HTMLDivElement | null>(null)

	const sort = useCallback(() => {
		if (!header.column.columnDef.enableSorting) return
		const sortStatus = header.column.getIsSorted() !== "desc"
		header.column.toggleSorting(sortStatus)
	}, [header])

	const { rightAlign, extraPopupText } = header.column.columnDef as ExpandedColumnDef
	const isPinned = header.column.getIsPinned()

	const headerTitle = flexRender(header.column.columnDef.header, header.getContext())

	const headerTooltip = (
		<div className="text-center">
			{headerTitle}
			{!!extraPopupText && (
				<>
					<br />
					{extraPopupText}
				</>
			)}
		</div>
	)

	const tooltipUUID = v4()

	if (isCheckbox)
		return (
			<th className="w-8 sticky" style={getCommonPinningStyles(header.column)}>
				{headerTitle}
			</th>
		)

	return (
		<th
			className={twMerge("group text-sm", isPinned === "right" && "!bg-grey1")}
			style={getCommonPinningStyles(header.column)}
		>
			<div
				className={twMerge(
					"p-2.5 font-medium",
					header.column.getCanSort() && "cursor-pointer",
					rightAlign && "flex justify-end text-end"
				)}
				onClick={sort}
			>
				<div
					className="flex items-center whitespace-nowrap"
					{...{ ref }}
					data-tooltip-id={tooltipUUID}
				>
					{headerTitle}
					<Tooltip id={tooltipUUID} children={headerTooltip} className="z-[20]" />

					{header.column.getCanSort() && (
						<div className="ml-2 w-fit">
							<ArrowUp
								className={classNames(
									header.column.getIsSorted() ? "text-black" : "text-grey5",
									header.column.getIsSorted() === "desc" && "rotate-180"
								)}
							/>
						</div>
					)}
				</div>
			</div>
		</th>
	)
}
