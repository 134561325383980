import { Control, Controller } from "react-hook-form"
import Select from "react-select"
import { useTrans } from "translations"
import ErrorTooltip from "../ErrorTooltip"
import { StyledSelect } from "components/StyledSelect"

type ViewProps = Omit<React.ComponentProps<typeof Select>, "onChange" | "name">
type CustomProps = {
	name: string
	label?: string
	patternError?: string
	control?: Control<any, any>
	placeholder?: string
	validationFunction?: (value: string) => boolean | string
	onSelectChange?: (value: any, name: string) => void
}

/**
 * Select component with a built in form controller from react-hook-form.
 */
const SelectInput = (props: ViewProps & CustomProps) => {
	const {
		id,
		control,
		name,
		options,
		defaultValue,
		isDisabled,
		label,
		required,
		patternError,
		styles,
		placeholder,
		menuPortalTarget,
		validationFunction,
		onSelectChange,
	} = props

	const { t } = useTrans()

	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue}
			rules={{ required, validate: validationFunction }}
			render={({ field: { onChange, value, name }, fieldState: { error } }) => (
				<div>
					{label && (
						<label htmlFor={id} className="font-dmSans text-sm font-medium text-black empty:hidden">
							{label}
							{label && required && " *"}
						</label>
					)}
					<div className="relative flex-col">
						<StyledSelect
							required={required}
							defaultValue={defaultValue}
							isDisabled={isDisabled}
							styles={styles}
							options={options}
							placeholder={placeholder ?? t("formLabels:chooseAlternative")}
							value={options?.find((o: any) => o.value === value)}
							onChange={val => {
								onChange((val as any).value)
								if (onSelectChange) {
									onSelectChange(val, name)
								}
							}}
							menuPortalTarget={menuPortalTarget}
							menuPosition="fixed"
							showErrorState={!!error}
							{...props}
						/>
						<ErrorTooltip
							label={label}
							patternError={patternError}
							error={error}
							className="mr-4 text-wrap"
						/>
					</div>
				</div>
			)}
		/>
	)
}

export default SelectInput
