import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { isEmpty } from "lodash"
import { useMemo } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "Utils/useNavigate"
import { useCommonEntitiesStore } from "States/commonEntities"
import { ContainerForm, ContainerFormData } from "../manage/containerForm"
import { useSlackNotifications } from "api/hooks/useSlackNotifications"
import { useTerminalsState } from "States/Terminals"
import { getInfrastructureUrl } from "Utils/getInfrastructureUrl"
import { useGlobalAlert } from "States/globalAlert"
import { useConfig } from "api/hooks/useConfig"
import { useAccessParentsWithPoints } from "../manage/useAccessParentsWithPoints"
import { useAccessPoints } from "../manage/useAccessPoints"
import { trpc } from "Utils/trpc"
import { POINT_STATUSES } from "admin-client-server/src/coreApi/common/constants"
import { PointStatus } from "Utils/terminal"
import { useParams } from "react-router-dom"

export const AddContainerModal: React.FC<{
	accessParentId: string
	selectedDepotId?: string | null
}> = ({ accessParentId, selectedDepotId }) => {
	const { hideModal } = useModal()
	const navigate = useNavigate()
	const { terminalId: urlTerminalId } = useParams()
	const { getAccessParent } = useAccessParentsWithPoints()
	const { isLoadingAll, refetchAll } = useAccessPoints({ accessParentId })
	const { sendNewContainerSlackNotification } = useSlackNotifications()
	const { wasteTypes } = useCommonEntitiesStore()
	const { currentTerminal } = useTerminalsState()
	const { setGlobalAlert } = useGlobalAlert()
	const { config } = useConfig()

	const accessParent = getAccessParent(accessParentId)

	const { mutateAsync, isLoading } = trpc.accessPoints.create.useMutation({
		onSuccess: async modifiedPoint => {
			await refetchAll()

			setGlobalAlert({
				type: "success",
				message: "systemMessages:containerAdded",
			})
			const url = getInfrastructureUrl({
				terminalId: urlTerminalId,
				accessParentId: accessParent?.id,
				containerId: modifiedPoint.id,
			})
			navigate(url)

			sendNewContainerSlackNotification(
				modifiedPoint.name,
				selectedDepotId || accessParentId || "",
				modifiedPoint.id
			)
			hideModal()
		},
		onError: error => {
			setGlobalAlert({
				type: "error",
				message: "errors:failedSave",
				instructions: error.message,
			})
		},
	})

	const formHandler = useForm<ContainerFormData>({
		defaultValues: {
			status: POINT_STATUSES[0],
			wasteTypeClassificationSystem:
				currentTerminal?.fractionDiscriminator ??
				config?.wasteTypeClassificationSystemId ??
				undefined,
		},
	})

	const {
		formState: { errors, isValid, isDirty },
		handleSubmit,
	} = formHandler

	const onConfirmDisabled = useMemo(() => !isEmpty(errors) || !isValid, [errors, isValid])

	if (!accessParentId || !accessParent) return null

	const onSubmitSingle = async (containerFormData: ContainerFormData) => {
		const { containerName, wasteTypeClassificationSystem, wasteCode, status } = containerFormData

		const fractionDesc = wasteTypes?.find(({ id }) => id === wasteCode)?.name

		await mutateAsync({
			name: containerName,
			fraction: wasteCode,
			fractionDesc: fractionDesc,
			fractionDiscriminator: wasteTypeClassificationSystem,
			status: status ?? PointStatus.ACTIVE,
			parentId: selectedDepotId || accessParentId,
		})
	}

	const onSubmit = handleSubmit(containerFormData => {
		if (isLoading) return
		onSubmitSingle(containerFormData)
	})

	return (
		<ModalContainer
			title="actions:newContainer"
			onConfirmText={"actions:save"}
			onConfirm={() => onSubmit()}
			onCancel={hideModal}
			onConfirmLoading={isLoadingAll}
			onConfirmDisabled={onConfirmDisabled}
			className="w-3/4 sm:w-1/2 lg:w-1/3"
			showDiscardModal={isDirty}
		>
			<div className="flex-col gap-4">
				<ContainerForm {...{ formHandler }} />
			</div>
		</ModalContainer>
	)
}
