import { omitBy } from "lodash"
import { useMemo } from "react"
import { useNavigate } from "Utils/useNavigate"

/**
 * Turns key value where array is an array to an url friendly format
 *
 * Example:
 * key: commercialTerminalCategorys
 * value: ["office", "mall"]
 *
 * result: commercialTerminalCategories=office&commercialTerminalCategories=mall
 * @deprecated
 * */
const keyValueArrayObjToUrlParams = (key: string, value: string[]) =>
	value.reduce((prev, v) => (prev ? `${prev}&` : "") + `${key}=${v}`, "")

/** Converts one layered object to url params. Supports object properties of strings and arrays. */
export const urlParamsFormat = <T extends object>(urlParams: T): string =>
	Object.entries(urlParams)
		.filter(([_, value]) => value !== undefined)
		.map(([key, value]) =>
			Array.isArray(value) ? keyValueArrayObjToUrlParams(key, value) : `${key}=${value}`
		)
		.join("&")

/**
 * Hook to get and set URL search params.
 * Mostly used for filters
 */
export const useUrlSearchParams = () => {
	const searchParamString = window.location.search.replace(/^\?/g, "")
	const navigate = useNavigate()
	const searchParams = useMemo(() => new URLSearchParams(searchParamString), [searchParamString])

	const returnObject = useMemo(
		() => ({
			searchParamString,
			getSearchParam: <T = any>(key?: string | null): { [key: string]: T } | null => {
				if (!key) return null
				try {
					const params = searchParams.get(key)
					return params ? JSON.parse(params) : null
				} catch {
					return null
				}
			},
			setSearchParam: (object: {
				[cardId: string]: {
					[filterId: string]: object | string
				}
			}) => {
				Object.entries(object).forEach(([key, value]) => {
					const filteredValue = omitBy(value, value => !value)
					if (Object.entries(filteredValue).length)
						searchParams.set(key, JSON.stringify(filteredValue))
					else searchParams.delete(key)
				})
				navigate({ search: searchParams.toString() })
			},
		}),
		[navigate, searchParamString, searchParams]
	)

	return returnObject
}
