import { FetchedDownstreamHandlingTypes } from "./api/sanity/types"

export type FlatDownstreamObject = {
	name: string
	parentIds: string[]
}

export type FlatDownstreamData = {
	[k: string]: FlatDownstreamObject
}

export const getFlatDownstreamData = (data: FetchedDownstreamHandlingTypes) => {
	return data.reduce((acc, d) => {
		acc[d._id] = {
			name: d.name,
			parentIds: [],
		}

		if (d.children) {
			const childrenData = getFlatDownstreamData(d.children)

			Object.keys(childrenData).forEach(cKey => {
				acc[cKey] = {
					name: childrenData[cKey].name,
					parentIds: [d._id],
				}

				if (childrenData[cKey].parentIds.length) {
					acc[cKey].parentIds.push(...childrenData[cKey].parentIds)
				}
			})
		}

		return acc
	}, {} as FlatDownstreamData)
}
