import moment from "moment"
import { diff } from "deep-diff"
import { keyValueArrayToObject } from "Utils/objArr"
import { TableHistoryEntry } from "./types"
import { translate } from "translations/functions/hook"
import { TRANSLATIONS } from "./constants"
import { IDENTITIES_KEY, POINTS_HISTORY_KEY, PROPERTIES_KEY } from "constants/general"
import { HistoricObject, HistoryEntry } from "admin-client-server/src/coreApi/history/get/models"

const getTransformedProp = (obj: any, prop: string) => {
	if (!obj?.[prop]) return {}

	return {
		[prop]: obj?.[prop]?.reduce((acc: any, el: any) => ({ ...acc, [el.id]: "" }), {}),
	}
}

const prepareHistData = (histEntry: HistoricObject) => ({
	...histEntry,
	properties: keyValueArrayToObject(histEntry?.properties),
	...getTransformedProp(histEntry, "points"),
	...getTransformedProp(histEntry, "identities"),
})

const formatChange = (change: any): string => {
	const origin = change.path[0]
	const key = change.path[change.path.length - 1]
	const lhs = change.lhs
	const rhs = change.rhs

	let changeKind = change.kind
	let defaultTranslation = "historyLabels:changedProperty"
	if (lhs === null) {
		changeKind = "N"
		defaultTranslation = "historyLabels:addedProperty"
	}
	if (rhs === null) {
		changeKind = "D"
		defaultTranslation = "historyLabels:deletedProperty"
	}

	let propertyTranslation = translate(`propertyLabels:${key}`)
	if (propertyTranslation.includes("propertyLabels")) {
		propertyTranslation = key
	}
	const translationKey =
		TRANSLATIONS[key]?.[changeKind] || TRANSLATIONS[origin]?.[changeKind] || defaultTranslation

	const translation = translate(translationKey || "", {
		property: propertyTranslation,
		from: lhs,
		to: rhs,
		value: key,
	})

	return translationKey ? translation : "Unknown"
}

const getChanges = (current: HistoricObject, previous?: HistoricObject): string[] => {
	if (!previous) return [translate("historyLabels:created")]

	const changes = diff<any>(prepareHistData(previous), prepareHistData(current), {
		normalize: (_, key, lhs, rhs) => {
			if ([PROPERTIES_KEY, POINTS_HISTORY_KEY, IDENTITIES_KEY].some(k => k === key)) {
				if (lhs === null) lhs = {}
				if (rhs === null) rhs = {}
			}
			return [lhs, rhs]
		},
	})
	return changes?.map(change => formatChange(change)) || []
}

export const getHistoryEntries = (history: HistoryEntry[] = []): TableHistoryEntry[] => {
	return history.reduce((acc: TableHistoryEntry[], entry) => {
		const userName = entry.command.command?.user?.name
		const userId = entry.command.command?.user?.id
		const changes = getChanges(entry.current, entry.previous)

		const changesWithUserAndDate = changes.map(change => ({
			change,
			user: userName || userId || "",
			date: moment(entry.timestamp).format("MMM D, YYYY h:mm A"),
		}))

		acc.push(...changesWithUserAndDate)

		return acc
	}, [])
}
