import { Button } from "components/button/index"
import { Card, CardComponentVariant } from "components/pageCards/card"
import { CardInfo } from "components/pageCards/cardInfo"
import { useModal } from "Contexts"
import { useAccessParentsWithPoints } from "pages/infrastructure/manage/useAccessParentsWithPoints"
import { DeletePointModal } from "pages/infrastructure/modals/deletePointModal"
import { EditWasteRoomModal } from "pages/infrastructure/modals/editWasteRoomModal"
import { FC, useMemo } from "react"
import { useNavigate } from "Utils/useNavigate"
import { useTrans } from "translations"
import { MultiListDrawer } from "../../multiListDrawer"
import { useConfig } from "api/hooks/useConfig"
import { EditStationModal } from "pages/infrastructure/modals/editStationModal"
import { PointChangeHistory } from "./drawerCards/PointChangeHistory"
import { PreFilteringCard } from "./drawerCards/PreFilteringCard"
import { InvalidDeletePointModal } from "pages/infrastructure/modals/invalidDeletePointModal"
import { useGlobalAlert } from "States/globalAlert"
import { getInfrastructureUrl } from "Utils/getInfrastructureUrl"
import { trpc } from "Utils/trpc"
import { useParams } from "react-router-dom"

export const AccessParentDrawer: FC<{
	accessParentId: string
}> = ({ accessParentId }) => {
	const navigate = useNavigate()
	const { t } = useTrans()
	const { showModal } = useModal()

	const { terminalId } = useParams()

	const {
		isLoading: isLoadingAccessParent,
		isError: isErrorFetchingAccessParent,
		getAccessParent,
	} = useAccessParentsWithPoints(terminalId)
	const accessParent = getAccessParent(accessParentId)

	const { mutateAsync: deleteAccessParent } = trpc.accessParents.delete.useMutation()

	const {
		data: customers,
		isLoading: isLoadingCustomers,
		isError: isErrorFetchingCustomers,
	} = trpc.customers.getAll.useQuery({ pointIds: [accessParentId], limit: 1 })

	const { setGlobalAlert } = useGlobalAlert()

	const { isRealEstate, isMWM } = useConfig()

	const openDeletionModal = () => {
		const invalidDelete =
			customers?.items.length || accessParent?.containers?.length || accessParent?.depots?.length

		if (invalidDelete) {
			showModal(
				<InvalidDeletePointModal
					{...{
						modalTitle: "actions:deleteAccessParent",
						invalidDeleteHint: "hints:deleteAccessParentWarning",
					}}
				/>
			)
		} else {
			showModal(
				<DeletePointModal
					{...{
						accessParentId,
						pointId: accessParent?.id || "",
						pointName: accessParent?.name,
						modalTitle: "actions:deleteAccessParent",
						deleteFunction: deleteAccessParent,
						onSuccess: () => {
							setGlobalAlert({
								type: "success",
								message: "systemMessages:accessParentDeleted",
							})

							const url = getInfrastructureUrl({ terminalId })
							navigate(url)
						},
					}}
				/>
			)
		}
	}

	const isLoading = useMemo(
		() => isLoadingAccessParent || isLoadingCustomers,
		[isLoadingAccessParent, isLoadingCustomers]
	)

	const isError = useMemo(
		() => isErrorFetchingAccessParent || isErrorFetchingCustomers,
		[isErrorFetchingAccessParent, isErrorFetchingCustomers]
	)

	return (
		<MultiListDrawer {...{ isLoading, isError }}>
			{accessParent?.name && (
				<h4 className="font-signifier text-3xl font-light text-black">{accessParent?.name}</h4>
			)}
			<Card variant={CardComponentVariant.DRAWER} title="drawerLabels:accessParentDetails">
				<div className="flex-col gap-4">
					<CardInfo heading={t("importLabels:accessParentName")} value={accessParent?.name} />
					{isMWM && (
						<>
							<CardInfo heading={t("formLabels:coordinates")} value={accessParent?.pos?.dd} />
							<CardInfo
								heading={t("formLabels:status")}
								value={t(`status:${accessParent?.status}`)}
							/>
							<CardInfo
								heading={t("formLabels:municipalityNumber")}
								value={accessParent?.municipality}
							/>
							<CardInfo heading={t("formLabels:client")} value={accessParent?.client} />
						</>
					)}
					<CardInfo heading={t("drawerLabels:carrotAccessParentID")} value={accessParent?.id} />
				</div>
				<Button
					label={"actions:edit"}
					onClick={() =>
						showModal(
							isRealEstate ? (
								<EditWasteRoomModal {...{ wasteRoomId: accessParentId }} />
							) : (
								<EditStationModal {...{ stationId: accessParentId }} />
							)
						)
					}
					color="secondary"
					className="float-right mt-10"
				/>
			</Card>
			<PreFilteringCard
				entityKey="accessParent"
				eventFilter={encodeURIComponent(JSON.stringify({ accessParentId: accessParent?.id }))}
				customersFilter={encodeURIComponent(JSON.stringify({ accessParentId: accessParent?.id }))}
			/>
			{isMWM && (
				<PointChangeHistory
					id={accessParentId}
					name={accessParent?.name || ""}
					entityKey="accessParent"
				/>
			)}
			<Card title={"drawerLabels:admin"} variant={CardComponentVariant.DRAWER}>
				<div className="mb-6 text-sm">{t("hints:deleteAccessParentWarning")}</div>
				<Button
					label={"actions:deleteAccessParent"}
					onClick={openDeletionModal}
					color="black"
					className="float-right"
				/>
			</Card>
		</MultiListDrawer>
	)
}
