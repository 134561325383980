import React from "react"
import { PerformanceRateInterface } from "components/PerformanceRate/PerformanceRate.interface"
import { orderBy } from "lodash"
import LoadingTable from "components/LoadingTable"
import { Table } from "components/Table"

export const PerformanceRate: React.FC<PerformanceRateInterface> = ({
	data,
	isLoading,
	sort,
	bestHeading,
	bestCaption,
	worstHeading,
	worstCaption,
	bestColumns,
	worstColumns,
}) => {
	const sortedData = orderBy(data, [row => row.rate], sort)
	const firstDataBatch = sortedData.slice(0, 5)
	const lastDataBatch = sortedData.reverse().slice(0, 5)

	return (
		<div className="grid 2xl:grid-cols-2 2xl:divide-x-2 h-full">
			<div className="mb-10 2xl:mb-0 2xl:pr-4 h-full">
				<h6 className="mb-1 font-dmSans text-base font-bold text-black">{bestHeading}</h6>
				<p className="mb-4 text-sm text-grey6">{bestCaption}</p>
				{isLoading ? (
					<LoadingTable rows={6} />
				) : (
					<Table
						columns={bestColumns}
						data={lastDataBatch}
						sortable={false}
						rightAlign
						emptyViewType="noData"
						showHeadersWhenEmpty={false}
						className="grow"
						emptyViewClassName="mt-16"
					/>
				)}
			</div>
			<div className="2xl:pl-4 h-full">
				<h6 className="mb-1 font-dmSans text-base font-bold text-black">{worstHeading}</h6>
				<p className="mb-4 text-sm text-grey6">{worstCaption}</p>
				{isLoading ? (
					<LoadingTable rows={6} />
				) : (
					<Table
						columns={worstColumns}
						data={firstDataBatch}
						sortable={false}
						rightAlign
						emptyViewType="noData"
						showHeadersWhenEmpty={false}
						className="grow"
						emptyViewClassName="mt-16"
					/>
				)}
			</div>
		</div>
	)
}

export default PerformanceRate
