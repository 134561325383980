import { ImportStep } from "components/ImportComponents/types"
import {
	DOWNLOAD_STEP,
	UPLOAD_STEP,
	MAPPING_STEP,
	VALIDATE_STEP,
	ADDING_STEP,
	DOWNLOAD_KEY,
	UPLOAD_KEY,
	MAP_KEY,
	VALIDATE_KEY,
	ADDING_KEY,
} from "pages/infrastructure/import/importSteps"

export const getImportSteps = ({ isMWM }: { isMWM: boolean }): ImportStep[] => {
	const customerKey = isMWM ? "customers" : "tenants"

	return [
		{
			...DOWNLOAD_STEP,
			key: `${DOWNLOAD_KEY}-${customerKey}`,
			helpSectionKey: `${DOWNLOAD_KEY}-${customerKey}`,
		},
		{ ...UPLOAD_STEP, key: UPLOAD_KEY, helpSectionKey: `${UPLOAD_KEY}-${customerKey}` },
		{ ...MAPPING_STEP, key: MAP_KEY, helpSectionKey: `${MAP_KEY}-${customerKey}` },
		{ ...VALIDATE_STEP, key: VALIDATE_KEY, helpSectionKey: `${VALIDATE_KEY}-${customerKey}` },
		{ ...ADDING_STEP, key: ADDING_KEY, helpSectionKey: `${ADDING_KEY}-${customerKey}` },
	]
}
