import TrashIcon from "Icons/Trash"
import classNames from "classnames"
import { useMemo } from "react"
import { AccessTokenColumn, AccessTokenRow, FieldType } from "./types"
import { IdentityKey } from "admin-client-server/src/coreApi/identities/types"
import { useModal } from "Contexts"
import { SelectWithAutocomplete } from "./SelectWithAutocomplete"
import { StyledSelect } from "components/StyledSelect"

type CommonProps = {
	getAutocompleteOptions: (key: IdentityKey, value: string) => Promise<any>
	onAutocompleteOptionSelected: (rowId: string, option: any) => void
	editCell: (
		rowId: string,
		isNewRow: boolean,
		columnKey: FieldType,
		value: string,
		error?: string
	) => void
	setCellError: (isNewRow: boolean, rowId: string, columnKey: FieldType, error: string) => void
}

type Props = CommonProps & {
	columns: AccessTokenColumn[]
	emptyMessage: string
	data: AccessTokenRow[]
	removeRow: (row: AccessTokenRow) => void
}

export const TableWithAutocomplete: React.FC<Props> = ({
	columns,
	emptyMessage,
	data,
	getAutocompleteOptions,
	onAutocompleteOptionSelected,
	editCell,
	setCellError,
	removeRow,
}) => {
	const rows = useMemo(
		() =>
			data?.map((row: AccessTokenRow) => (
				<div key={row.id} className="flex w-full">
					{columns.map(column => (
						<div
							key={`${row.id}-${column.name}`}
							className="border-t border-grey3 py-3 pr-3"
							style={{ width: `${95 / columns.length}%` }}
						>
							<Row
								column={column}
								row={row}
								editCell={editCell}
								setCellError={setCellError}
								getAutocompleteOptions={getAutocompleteOptions}
								onAutocompleteOptionSelected={onAutocompleteOptionSelected}
							/>
						</div>
					))}
					<div className="border-t border-gray pt-5" style={{ width: "5%" }}>
						<button onClick={() => removeRow(row)} className="ml-2 h-8" type="button">
							<TrashIcon />
						</button>
					</div>
				</div>
			)),
		[
			data,
			columns,
			getAutocompleteOptions,
			editCell,
			setCellError,
			onAutocompleteOptionSelected,
			removeRow,
		]
	)

	return (
		<div className="w-full table-auto border-separate border-spacing-y-3 min-h-[150px] overflow-y-scroll max-h-[400px]">
			<div className="flex sticky top-0 z-10 bg-white p-2.5">
				{columns.map(({ label, required }, colIndex) => (
					<div key={colIndex} style={{ width: `${95 / columns.length}%` }}>
						<span
							className={classNames(
								colIndex === 0 ? "mr-1" : "m-1",
								"font-dmSans text-sm font-medium text-black empty:hidden"
							)}
						>
							{label} {required && "*"}
						</span>
					</div>
				))}
				<div style={{ width: "5%" }}></div>
			</div>
			<div>
				{!data?.length && emptyMessage && (
					<div>
						<div className="border-t border-grey3">
							<p className="text-center bg-grey1 py-8 text-sm">{emptyMessage}</p>
						</div>
					</div>
				)}
				{rows}
			</div>
		</div>
	)
}

type RowProps = CommonProps & {
	column: AccessTokenColumn
	row: AccessTokenRow
}

const Row = ({
	column,
	row,
	editCell,
	setCellError,
	getAutocompleteOptions,
	onAutocompleteOptionSelected,
}: RowProps) => {
	const { ref: modalRef } = useModal()

	const { name, isAutocomplete, canBeDisabled, options, defaultValue } = column
	const { id, isNew, printed, iso } = row

	const cellData = row[name]
	const { value, error } = cellData

	if (isAutocomplete) {
		return (
			<SelectWithAutocomplete
				value={value}
				columnId={name}
				getAutocompleteOptions={getAutocompleteOptions}
				onAutocompleteOptionSelected={(option: any) => onAutocompleteOptionSelected(id, option)}
				setCellError={cellError => setCellError(!!isNew, id, name, cellError)}
				errorMessage={error}
				disabled={!!canBeDisabled && !isNew}
				hidePlaceholder={!!printed.value || !!iso.value}
			/>
		)
	}

	const defaultOption = {
		value: defaultValue,
		label: options?.find(o => o.value === defaultValue)?.label,
	}

	const selectedOption = options?.find(o => o.value === value)

	return (
		<StyledSelect
			options={options}
			onChange={option => editCell(id, !!isNew, name, option.value)}
			defaultValue={defaultOption}
			value={selectedOption}
			menuPortalTarget={modalRef?.current}
			className="m-1"
		/>
	)
}
