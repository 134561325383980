import { useTerminals } from "api/hooks/useTerminals"
import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { isEmpty } from "lodash"
import { FC, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { TerminalForm, TerminalFormData } from "./TerminalForm"
import { useConfig } from "api/hooks/useConfig"
import { BuildingForm } from "./BuildingForm"
import { useGlobalAlert } from "States/globalAlert"
import { RealEstateCategory } from "api/types"
import { RealEstate } from "admin-client-server/src/coreApi/terminals/types"
import { useEmailJob } from "pages/configuration/components/useEmailJob"
import { EmailJobType } from "admin-client-server/src/config-api"
import {
	UpdateMWMTerminal,
	UpdateRETerminal,
	UpdateTerminal,
} from "admin-client-server/src/coreApi/terminals/update/models"

export type Terminal = RealEstate & {
	id: string
}

interface EditTerminalModalProps {
	terminal: Terminal
}

const terminalToFormData = (terminal: Terminal): TerminalFormData => ({
	...terminal,
	name: terminal?.name ?? "",
	category: terminal?.category ?? RealEstateCategory.Office,
	contactEmail: terminal?.operations.email ?? "",
	address: terminal?.address ?? "",
	area: terminal?.area ?? "",
})

export const EditTerminalModal: FC<EditTerminalModalProps> = ({ terminal }) => {
	const { refetchTerminals, isLoadingTerminals, updateTerminal } = useTerminals()

	const { hideModal } = useModal()
	const { isMWM } = useConfig()
	const { setGlobalAlert } = useGlobalAlert()
	const { addEmailJob } = useEmailJob()
	const [isSubmitAttempted, setIsSubmitAttempted] = useState(false)

	const formData = terminal && terminalToFormData(terminal)

	const formHandler = useForm<TerminalFormData>({
		defaultValues: formData,
	})

	const {
		formState: { errors, isValid, isDirty },
		handleSubmit,
	} = formHandler

	const handleUpdate = (terminalFormData: TerminalFormData) => {
		if (!terminal || !formData) return

		const basePayload = {
			id: terminal.id,
			name: terminalFormData?.name || terminal.name,
		}

		const payload: UpdateTerminal = !isMWM
			? ({
					...basePayload,
					category: terminalFormData?.category,
					email: terminalFormData?.contactEmail,
					area: terminalFormData?.area,
				} as UpdateMWMTerminal)
			: (basePayload as UpdateRETerminal)

		updateTerminal(payload).then(async () => {
			if (!isMWM) {
				const isUpdatedEmail = terminalFormData.contactEmail !== terminal.operations.email
				if (isUpdatedEmail) {
					await addEmailJob({
						terminalId: terminal.id,
						email: terminalFormData.contactEmail,
						type: EmailJobType.ActionReport,
					})
				}
			}

			await refetchTerminals()
			setGlobalAlert({
				type: "success",
				message: "systemMessages:changesSaved",
			})
			hideModal()
		})
	}

	const onSubmit = handleSubmit(terminalFormData => {
		setIsSubmitAttempted(true)
		handleUpdate(terminalFormData)
	})

	const isConfirmDisabled = useMemo(
		() => isSubmitAttempted && (!isEmpty(errors) || !isValid),
		[isSubmitAttempted, errors, isValid]
	)

	return (
		<ModalContainer
			title="drawerLabels:terminalDetails"
			onConfirm={() => onSubmit()}
			onCancel={hideModal}
			onConfirmLoading={isLoadingTerminals}
			onConfirmDisabled={isConfirmDisabled}
			className="w-3/4 sm:w-1/2 lg:w-1/3"
			showDiscardModal={isDirty}
		>
			{isMWM ? (
				<TerminalForm formHandler={formHandler} />
			) : (
				<BuildingForm formHandler={formHandler} />
			)}
		</ModalContainer>
	)
}
