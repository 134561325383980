import useCustomers from "api/hooks/useCustomers"
import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { isEmpty } from "lodash"
import { FC, useEffect } from "react"
import { useForm } from "react-hook-form"
import { CustomerWithChain } from "../../types"
import { updatedDiff } from "deep-object-diff"
import { TenantDetailsFormSection } from "./TenantDetailsFormSection"
import { useCommonEntitiesStore } from "States/commonEntities"
import { useGlobalAlert } from "States/globalAlert"
import { CustomerWithDetailsAndCode } from "admin-client-server/src/coreApi/customers/types"

interface EditTenantProps {
	customer: CustomerWithDetailsAndCode
	onUpdate?: () => void
}

export const EditTenantModal: FC<EditTenantProps> = ({ customer, onUpdate }) => {
	const { hideModal } = useModal()
	const { setGlobalAlert } = useGlobalAlert()
	const { updateCustomer, isUpdatingCustomer, refetchCustomers } = useCustomers()
	const { tenantCategories } = useCommonEntitiesStore()

	const formHandler = useForm<CustomerWithChain>({
		defaultValues: {
			...customer,
			throwDelegate: customer.throwDelegate || "false",
			businessChainId: customer.businessChain?.id,
		},
	})

	const {
		handleSubmit,
		formState: { errors, isDirty },
	} = formHandler

	useEffect(() => {
		formHandler.setValue("categoryId", customer.categoryId)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tenantCategories])

	const onSave = handleSubmit(customerWithAccessParents => {
		const customerWithChain = { throwDelegate: undefined, businessChainId: undefined, ...customer }
		// We want to reduce amount of entries created with "throwDelegate" property created in database
		// But also we want to have "false" as default value for this property in UI
		// Therefore, we check if property exists for customer and fake value if it doesn't
		const throwDelegate =
			customerWithChain.throwDelegate || customerWithAccessParents.throwDelegate === "true"
				? (customerWithAccessParents.throwDelegate as string)
				: undefined
		const area = customerWithAccessParents.area || null
		const cleaned = Object.fromEntries(
			Object.entries({ ...customerWithAccessParents, throwDelegate, area }).filter(
				([_, v]) => v !== "" && v !== undefined
			)
		)
		const differences = updatedDiff(customerWithChain, cleaned) as Partial<typeof customerWithChain>

		if (differences) {
			const updatedCustomer = { id: customerWithChain.id, ...differences }

			updateCustomer(
				{ customer: updatedCustomer },
				{
					onSuccess: () => {
						setGlobalAlert({
							type: "success",
							message: "systemMessages:changesSaved",
						})
						onUpdate?.()
						refetchCustomers()
						hideModal()
					},
				}
			)
		}
	})

	return (
		<ModalContainer
			title="drawerLabels:tenantDetails"
			onConfirm={onSave}
			onCancel={hideModal}
			onConfirmLoading={isUpdatingCustomer}
			onConfirmDisabled={!isEmpty(errors)}
			className="w-[450px]"
			showDiscardModal={isDirty}
		>
			<form className="grid grid-cols-1 md:flex-nowrap">
				<TenantDetailsFormSection {...{ formHandler, isEdit: true }} />
			</form>
		</ModalContainer>
	)
}
