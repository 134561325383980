/* eslint import/no-webpack-loader-syntax:0 */
import "./wdyr"
import { createRoot } from "react-dom/client"
import App from "./App"
import { configName, isLocalhost, isStaging } from "./Configs/config"
import { Auth0Provider } from "./Contexts/Auth"
import "./Fonts/fonts.css"
import "./index.css"
import "react-day-picker/style.css"
import "react-phone-number-input/style.css"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import posthog from "posthog-js"
import { PostHogProvider } from "posthog-js/react"
import { SiteProvider } from "api/hooks/useSelectedSite"

Sentry.init({
	enabled: !isLocalhost,
	dsn: "https://5ff71ac4b04e473daa6950838b890eef@o1366084.ingest.sentry.io/4504338564579328",
	integrations: [new BrowserTracing()],
	release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
	environment: isStaging ? "development" : "production",
	debug: false,
	tracesSampleRate: 1.0,
	ignoreErrors: ["ResizeObserver loop limit exceeded"],
})

if (!isStaging) {
	posthog.init("phc_cv2TFh1qn4dvvzMb5N7AFLxFM9CznIE4oYvEUDlFghW", {
		api_host: "https://eu.posthog.com",
	})
}

const onRedirectCallback = (appState: any) => {
	window.history.replaceState(
		{},
		document.title,
		appState && appState.targetUrl ? appState.targetUrl : window.location.pathname
	)
}

const domain = window.location.origin

const auth0Config =
	domain.includes("bir") ||
	domain.includes("kvam") ||
	(window.location.origin.includes("localhost") && (configName === "kvam" || configName === "bir")) // for dev
		? {
				audience: "https://wasteiq-bir.no",
				client_id: "LQF3D6RuW06ZTGgRespFJa8PWrqPtdDB",
				domain: "wasteiq-bir.eu.auth0.com",
			}
		: {
				audience: "https://adminapp.wasteiq.no",
				client_id: "5LnyptHmnvCblLbGKMBQtD5rzp0w6l60",
				domain: "wasteiq.eu.auth0.com",
			}

const container = document.getElementById("root")
const root = createRoot(container!)

root.render(
	<Auth0Provider
		clientId={auth0Config.client_id}
		domain={auth0Config.domain}
		authorizationParams={{
			redirect_uri: window.location.origin,
			audience: auth0Config.audience,
		}}
		cacheLocation="localstorage"
		onRedirectCallback={onRedirectCallback}
	>
		<PostHogProvider client={posthog}>
			<SiteProvider>
				<App />
			</SiteProvider>
		</PostHogProvider>
	</Auth0Provider>
)
