import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { isEmpty, uniqBy } from "lodash"
import { useForm } from "react-hook-form"
import FormInput from "components/Form/FormInput"
import { useTrans } from "translations"
import SelectInput from "components/Form/SelectInput"
import { useAccessPoints } from "pages/infrastructure/manage/useAccessPoints"
import { useConfigService } from "pages/configuration/useConfigService"
import { useConfig } from "api/hooks/useConfig"
import { useMemo } from "react"

const WEIGHT_REGEX = /^(?:\d*(?:\.0{1,2})?|(?:\d{1,2}|1[0-4]\d)(?:\.\d{1,2})?)$/

export const EditEventModal: React.FC<{
	eventId: string
	initialEventWeightKg: number
	initialEventPointId: string
	eventAccessParentId: string
	editEvent: (payload: {
		id: string
		weightKg?: number | undefined
		pointId?: string | undefined
	}) => Promise<void>
}> = ({ eventId, initialEventWeightKg, initialEventPointId, eventAccessParentId, editEvent }) => {
	const { t } = useTrans()
	const { hideModal } = useModal()
	const { ref: modalRef } = useModal()
	const { config: clientConfig } = useConfig()
	const { terminalConfig } = useConfigService()

	const { containers } = useAccessPoints({
		accessParentId: eventAccessParentId,
	})

	const maxWeight =
		terminalConfig?.maxWeightPerThrow ||
		clientConfig?.maxWeightPerThrow ||
		t("common:defaultMaxWeightPerThrow")

	const formHandler = useForm({
		defaultValues: {
			weightKg: initialEventWeightKg,
			pointId: initialEventPointId,
		},
		mode: "all",
	})

	const {
		register,
		control,
		formState: { errors, isValid, isDirty },
		handleSubmit,
	} = formHandler

	const onSubmit = handleSubmit(eventFormData => {
		const { weightKg, pointId } = eventFormData

		const updateObject: any = {
			id: eventId,
		}

		if (Number(weightKg) !== initialEventWeightKg) {
			updateObject.weightKg = Number(weightKg)
		}

		if (pointId !== initialEventPointId) {
			updateObject.pointId = pointId
		}

		if (weightKg !== initialEventWeightKg || pointId !== initialEventPointId) {
			editEvent(updateObject)
		}

		hideModal()
	})

	const accessPointOptions = useMemo(
		() =>
			uniqBy(containers, "wasteCode").map(c => ({
				label: `${c.wasteType} (${c.wasteCode})`,
				value: c.id,
			})),
		[containers]
	)

	const validWeightError = t("errors:mustBeValidEventWeight", { maxWeight })

	return (
		<ModalContainer
			title="actions:editRow"
			onConfirmText={"actions:save"}
			onConfirm={() => onSubmit()}
			onCancel={hideModal}
			onConfirmDisabled={!isEmpty(errors) || !isValid}
			className="w-3/4 sm:w-1/2 lg:w-1/3"
			showDiscardModal={isDirty}
		>
			<div className="mb-6 flex flex-col gap-y-5">
				<FormInput
					name="weightKg"
					label={t("formLabels:weight")}
					register={register}
					error={errors?.weightKg}
					regexPattern={WEIGHT_REGEX}
					min={0}
					max={Number(maxWeight)}
					minMaxError={validWeightError}
					patternError={validWeightError}
					showErrorInTooltip
				/>
				<SelectInput
					label={t("formLabels:wasteStream")}
					name="pointId"
					options={accessPointOptions}
					control={control}
					menuPortalTarget={modalRef.current}
				/>
			</div>
		</ModalContainer>
	)
}
