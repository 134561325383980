import { ACCESS_PARENT_WITH_TERMINAL_ID } from "admin-client-server/src/coreApi/accessParents/types"
import {
	getAccessParentColumns,
	getAccessParentSelection,
	getContainerColumns,
	getTerminalColumns,
	getTerminalParentSelection,
} from "./columnFunctions"
import {
	downloadForAccessParents,
	downloadForContainers,
	downloadForTerminals,
} from "./downloadFunctions"
import { ACCESS_PARENT_KEY, CONTAINER_KEY, DEPOT_KEY, TERMINAL_KEY } from "./constants"
import { FetchedWasteTypeClassificationSystems, FetchedWasteTypes } from "Utils/api/sanity/types"
import {
	MappingSection,
	ParentSelection,
	SuccessTextProps,
	Template,
	TemplateColumn,
} from "components/ImportComponents/types"
import { NoTerminalsModal } from "pages/customer/manage/components/NoTerminalsModal"
import { NoAccessParentsForContainersModal } from "pages/customer/manage/components/NoAccessParentsForContainers"
import { getTemplatesWithDepots } from "./depotTemplates"
import { Terminal } from "admin-client-server/src/coreApi/terminals/types"

export const SUCCESS_PARAMETERS: SuccessTextProps = {
	addedText: "importLabels:infrastructureAdded",
	findResultsText: "importLabels:infrastructureFindResults",
	findResultsLink: "/infrastructure/manage",
}

export type SectionProps = {
	columns?: TemplateColumn[]
	selectionParams?: ParentSelection
	isPreSelected?: boolean
	isOptional?: boolean
}
export const getTerminalSection = ({
	columns,
	selectionParams,
	isPreSelected,
}: SectionProps): MappingSection => ({
	key: TERMINAL_KEY,
	title: "entities:terminals",
	sheetSelectLabel: "importLabels:selectTerminalSheet",
	isPreSelected: isPreSelected || false,
	columns: columns || [],
	columnSelectLabel: "importLabels:selectTerminalProperties",
	selectionParams,
})

export const getAccessParentSection = ({
	columns,
	isPreSelected,
	selectionParams,
}: SectionProps): MappingSection => ({
	key: ACCESS_PARENT_KEY,
	title: "entities:accessParents",
	isOptional: false,
	dependents: [CONTAINER_KEY],
	sheetSelectLabel: "importLabels:selectAccessParentSheet",
	columns: columns || [],
	columnSelectLabel: "importLabels:selectAccessParentProperties",
	isPreSelected: isPreSelected || false,
	selectionParams,
})

export const getDepotSection = ({
	columns,
	isPreSelected,
	selectionParams,
}: SectionProps): MappingSection => ({
	key: DEPOT_KEY,
	title: "entities:depots",
	isOptional: false,
	sheetSelectLabel: "importLabels:selectDepotSheet",
	columns: columns || [],
	columnSelectLabel: "importLabels:selectDepotProperties",
	isPreSelected: isPreSelected || false,
	selectionParams,
	dependsOn: ACCESS_PARENT_KEY,
})

export const getContainerSection = ({ columns }: SectionProps): MappingSection => ({
	key: CONTAINER_KEY,
	title: "entities:containers",
	isOptional: false,
	sheetSelectLabel: "importLabels:selectContainerSheet",
	columns: columns || [],
	columnSelectLabel: "importLabels:selectContainerProperties",
	dependsOn: ACCESS_PARENT_KEY,
})

export type TemplateProps = {
	terminals: Terminal[]
	wasteTypeClassificationSystems: FetchedWasteTypeClassificationSystems | null
	wasteTypes: FetchedWasteTypes | null
	accessParents?: ACCESS_PARENT_WITH_TERMINAL_ID[]
	depots?: any[]
	defaultWTCS?: string | null
	isMWM: boolean
	showModal: (_: any) => void
	useWasteSuctionSystem: boolean
}

export const getGenericTemplates = ({
	terminals,
	wasteTypeClassificationSystems,
	wasteTypes,
	accessParents,
	defaultWTCS,
	isMWM,
	showModal,
}: TemplateProps): Template[] => {
	const terminalColumns = getTerminalColumns({ terminals, isMWM })
	const accessParentColumns = getAccessParentColumns({ isMWM })
	const containerColumns = getContainerColumns({
		wasteTypeClassificationSystems,
		wasteTypes,
		defaultWTCS,
		isMWM,
	})

	const terminalParentSelection = getTerminalParentSelection(terminals)
	const accessParentSelection = getAccessParentSelection(accessParents)

	const terminalSection = getTerminalSection({
		columns: terminalColumns,
		selectionParams: terminalParentSelection,
	})
	const preSelectedTerminalSection = {
		...terminalSection,
		isPreSelected: true,
	}

	const accessParentSection = getAccessParentSection({
		columns: accessParentColumns,
		selectionParams: accessParentSelection,
	})
	const optionalAccessParentSection = { ...accessParentSection, isOptional: true }

	const containersSection = getContainerSection({ columns: containerColumns })
	const optionalContainersSection = { ...containersSection, isOptional: true }

	const terminalSections: MappingSection[] = [
		terminalSection,
		optionalAccessParentSection,
		optionalContainersSection,
	]

	const accessParentSections: MappingSection[] = [
		preSelectedTerminalSection,
		{
			...accessParentSection,
			columns: accessParentSection.columns?.filter(c => !c.hasLinkedParent),
		},
		optionalContainersSection,
	]

	const containerSections: MappingSection[] = [
		preSelectedTerminalSection,
		{
			...accessParentSection,
			isPreSelected: true,
		},
		{
			...containersSection,
			columns: containersSection.columns?.filter(c => !c.hasLinkedParent),
		},
	]

	return [
		{
			key: "terminals",
			title: "importLabels:infraTemplateTerminals",
			downloadFile: downloadForTerminals,
			mappingSections: terminalSections,
			checkCanBeUsed: () => true,
			...SUCCESS_PARAMETERS,
		},
		{
			key: "accessParents",
			title: "importLabels:infraTemplateAccessParents",
			downloadFile: downloadForAccessParents,
			parentSelections: [terminalParentSelection],
			mappingSections: accessParentSections,
			checkCanBeUsed: () => terminals?.length > 0,
			showCantBeUsed: () => showModal(<NoTerminalsModal />),
			...SUCCESS_PARAMETERS,
		},
		{
			key: "containers",
			title: "importLabels:infraTemplateContainers",
			downloadFile: downloadForContainers,
			parentSelections: [terminalParentSelection, accessParentSelection],
			mappingSections: containerSections,
			checkCanBeUsed: () => accessParents?.length! > 0,
			showCantBeUsed: () => showModal(<NoAccessParentsForContainersModal />),
			...SUCCESS_PARAMETERS,
		},
	]
}

export const getTemplates = (props: TemplateProps): Template[] => {
	if (props.useWasteSuctionSystem) {
		return getTemplatesWithDepots(props)
	} else {
		return getGenericTemplates(props)
	}
}
