import { useTerminalsState } from "States/Terminals"
import { Button } from "components/button/index"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { trpc } from "Utils/trpc"
import { SingleValue } from "react-select"
import { Translate, useTrans } from "translations"
import { useCommonEntitiesStore } from "States/commonEntities"
import { useTerminals } from "api/hooks/useTerminals"
import { useModal } from "Contexts"
import { DiscardModal } from "components/modalContainer"
import { useGlobalAlert } from "States/globalAlert"
import { Toggle } from "components/Toggle"
import { StyledSelect } from "components/StyledSelect"
import { sortAlphabeticallyByProperty } from "Utils/sorting"
import { useConfigService } from "./useConfigService"
import { remove, sortBy, uniqBy } from "lodash"
import { useAccessParents } from "pages/infrastructure/functions"
import { getWasteTypeName } from "Utils/commonExportFunctions"
import Skeleton from "react-loading-skeleton"
import timezones from "timezones-list"
import { LanguageType } from "admin-client-server/src/config-api"
import { PointStatus } from "Utils/terminal"
import { DeleteTerminalModal } from "pages/infrastructure/terminals/DeleteTerminalModal"
import { ACCESS_POINT } from "admin-client-server/src/coreApi/accessPoints/types"
import { MaxWeightPerThrowInput, getIsValidMaxWeight } from "./components/maxWeightPerThrowInput"
import { Payments, PricingType } from "./payments"
import { useConfig } from "api/hooks/useConfig"

type Props = {
	hasUnsavedChanges: boolean
	setHasUnsavedChanges: (hasChanges: boolean) => void
}

export const Building: FC<Props> = ({ hasUnsavedChanges, setHasUnsavedChanges }) => {
	const { allTerminals: terminals, setCurrentTerminal } = useTerminalsState()
	const [selectedTerminalId, setSelectedTerminalId] = useState<string>()
	const { updateCoreTerminalConfig, isUpdatingCoreTerminalConfig } = useTerminals()
	const { wasteTypes: sanityWasteTypes, wasteTypeClassificationSystems } = useCommonEntitiesStore()
	const { showModal, hideModal } = useModal()
	const { setGlobalAlert } = useGlobalAlert()
	const { config: clientConfig } = useConfig()

	const { accessParents, isLoading: isLoadingAccessParents } = useAccessParents(selectedTerminalId)
	const {
		terminalWasteTypeConfig,
		terminalConfig,
		refetchTerminalConfig,
		refetchTerminalWasteTypeConfig,
		isLoadingTerminalConfig,
	} = useConfigService(selectedTerminalId)

	const [permanentLogin, setPermanentLogin] = useState<boolean>(false)
	const [language, setLanguage] = useState<LanguageType | null>(null)
	const [wasteTypeClassificationSystemId, setWasteTypeClassificationSystemId] = useState<
		string | null
	>(null)
	const [terminalStatus, setTerminalStatus] = useState<PointStatus | undefined>(
		terminals.find(t => t.id === selectedTerminalId)?.status
	)
	const [updatedHandledBySmartInfra, setUpdatedHandledBySmartInfra] = useState<
		{ code: string; handledBySmartInfra: boolean }[]
	>([])
	const [timezone, setTimezone] = useState<string | null>(null)
	const [maxWeightPerThrow, setMaxWeightPerThrow] = useState<number | null>(null)
	const [hasChangedPricing, setHasChangedPricing] = useState(false)
	const [pricing, setPricing] = useState<PricingType>({ currency: "", price: "" })

	const selectedTerminal = useMemo(() => {
		return terminals.find(t => t.id === selectedTerminalId)
	}, [terminals, selectedTerminalId])

	const hasChangedHandledBySmartInfra = useMemo(() => {
		return updatedHandledBySmartInfra.some(
			({ code, handledBySmartInfra }) =>
				(terminalWasteTypeConfig.find(twtc => twtc.wasteTypeCode === code)?.handledBySmartInfra ||
					false) !== handledBySmartInfra
		)
	}, [updatedHandledBySmartInfra, terminalWasteTypeConfig])

	useEffect(() => {
		const selectedTerminal = terminals.find(t => t.id === selectedTerminalId)
		if (!selectedTerminal) {
			setHasUnsavedChanges(false)
			return
		}

		const hasChangedWTCS =
			selectedTerminal?.fractionDiscriminator !== wasteTypeClassificationSystemId
		const hasChangedPermanentLogin = terminalConfig?.allowAppPermanentLogin !== permanentLogin
		const hasChangedTimezone = terminalConfig?.timezone !== timezone
		const hasChangedLanguage = terminalConfig?.language !== language
		const hasChangedTerminalStatus = selectedTerminal?.status !== terminalStatus
		const hasChangedMaxWeightPerThrow = terminalConfig?.maxWeightPerThrow !== maxWeightPerThrow

		const hasChanges =
			hasChangedWTCS ||
			hasChangedPermanentLogin ||
			hasChangedTimezone ||
			hasChangedLanguage ||
			hasChangedHandledBySmartInfra ||
			hasChangedTerminalStatus ||
			hasChangedMaxWeightPerThrow ||
			hasChangedPricing
		setHasUnsavedChanges(hasChanges)
	}, [
		selectedTerminalId,
		setHasUnsavedChanges,
		wasteTypeClassificationSystemId,
		terminals,
		terminalConfig,
		permanentLogin,
		timezone,
		updatedHandledBySmartInfra,
		terminalWasteTypeConfig,
		language,
		hasChangedHandledBySmartInfra,
		terminalStatus,
		maxWeightPerThrow,
		hasChangedPricing,
	])

	const { mutateAsync: updateTerminalConfig, isLoading: isUpdatingAppConfig } =
		trpc.config.updateTerminalConfig.useMutation({
			onSuccess: () => {
				refetchTerminalConfig()
			},
		})

	const {
		mutateAsync: updateTerminalWasteTypeConfig,
		isLoading: isUpdatingTerminalWasteTypeConfig,
	} = trpc.config.updateTerminalWasteTypeConfig.useMutation({
		onSuccess: () => {
			refetchTerminalWasteTypeConfig()
		},
	})

	const { mutateAsync: mutatePricing, isLoading: isUpdatingPricing } =
		trpc.payments.setPricing.useMutation()

	useEffect(() => {
		if (
			selectedTerminal &&
			terminalConfig &&
			selectedTerminal.id === terminalConfig.terminalUuid &&
			!terminalConfig.wasteTypeClassificationSystemId &&
			!!selectedTerminal.fractionDiscriminator
		) {
			updateTerminalConfig({
				terminalId: selectedTerminal.id,
				wasteTypeClassificationSystemId: selectedTerminal.fractionDiscriminator,
			})
		}
	}, [selectedTerminal, terminalConfig, updateTerminalConfig])

	const { t } = useTrans()

	const terminalOptions = terminals
		.map(terminal => ({
			label:
				terminal.status === PointStatus.INACTIVE
					? `${terminal.name} (${t("common:deactivated")})`
					: terminal.name,
			value: terminal.id,
		}))
		.sort((a, b) => sortAlphabeticallyByProperty(a, b, "label"))

	const resetOption = useMemo(
		() => ({
			label: t("configLabels:sameAsClient"),
			value: "",
		}),
		[t]
	)

	const wasteTypeClassificationSystemOptions = useMemo(() => {
		const options =
			wasteTypeClassificationSystems?.map(w => ({
				label: w.name,
				value: w.id,
			})) || []

		options.unshift(resetOption)

		return options
	}, [wasteTypeClassificationSystems, resetOption])

	const timezoneOptions = useMemo(() => {
		const options = timezones
			.map(t => ({
				label: t.label,
				value: t.tzCode,
			}))
			.sort((a, b) => a.label.localeCompare(b.label))

		options.unshift(resetOption)

		return options
	}, [resetOption])

	const languageOptions = useMemo(() => {
		const options: {
			label: string
			value: string
		}[] = Object.values(LanguageType)
			.map(code => ({
				label: t(`languages:${code}`),
				value: code,
			}))
			.sort((a, b) => a.label.localeCompare(b.label))

		options.unshift(resetOption)

		return options
	}, [t, resetOption])

	useEffect(() => {
		if (terminalConfig) {
			setPermanentLogin(terminalConfig.allowAppPermanentLogin)
			setTimezone(terminalConfig.timezone)
			setLanguage(terminalConfig.language)
			setMaxWeightPerThrow(terminalConfig.maxWeightPerThrow)
		}
	}, [terminalConfig])

	const containers = useMemo(() => {
		return sortBy(
			uniqBy(
				accessParents.flatMap(ap => ap.containers as ACCESS_POINT[]),
				"wasteType.code"
			),
			"wasteType.code"
		)
	}, [accessParents])

	const onWasteTypeHandledBySmartInfraChange = useCallback(
		(wasteTypeCode: string, checked: boolean) => {
			setUpdatedHandledBySmartInfra(prev => [
				...remove(prev, ({ code }) => code !== wasteTypeCode),
				{
					code: wasteTypeCode,
					handledBySmartInfra: checked,
				},
			])
			setHasUnsavedChanges(true)
		},
		[setUpdatedHandledBySmartInfra, setHasUnsavedChanges]
	)

	const onTerminalChange = useCallback(
		(change: SingleValue<(typeof terminalOptions)[number]>) => {
			const changeTerminal = () => {
				setSelectedTerminalId(change?.value as string)
				const terminal = terminals.find(t => t.id === change?.value)
				setWasteTypeClassificationSystemId(terminal?.fractionDiscriminator || null)
				setTerminalStatus(terminal?.status)
				setUpdatedHandledBySmartInfra([])
			}

			if (hasUnsavedChanges) {
				showModal(
					<DiscardModal
						onCancel={() => hideModal()}
						onConfirm={() => {
							changeTerminal()
							hideModal()
						}}
					/>
				)
			} else {
				changeTerminal()
			}
		},
		[hasUnsavedChanges, setSelectedTerminalId, terminals, showModal, hideModal]
	)

	const onWasteTypeClassificationSystemIdChange = useCallback(
		(change: SingleValue<(typeof wasteTypeClassificationSystemOptions)[number]>) => {
			setWasteTypeClassificationSystemId(change?.value ?? null)
		},
		[setWasteTypeClassificationSystemId]
	)

	const onTimezoneChange = useCallback(
		(change: SingleValue<(typeof timezoneOptions)[number]>) => {
			setTimezone(change?.value as string)
		},
		[setTimezone]
	)

	const onLanguageChange = useCallback(
		(change: SingleValue<(typeof languageOptions)[number]>) => {
			setLanguage(change?.value as LanguageType)
		},
		[setLanguage]
	)

	const onTerminalStatusToggle = useCallback(() => {
		setTerminalStatus(status =>
			status === PointStatus.ACTIVE ? PointStatus.INACTIVE : PointStatus.ACTIVE
		)
	}, [setTerminalStatus])

	const findHandledBySmartInfra = useCallback(
		(wasteTypeCode: string) => {
			return (
				updatedHandledBySmartInfra.find(h => h.code === wasteTypeCode) ||
				terminalWasteTypeConfig.find(twtc => twtc.wasteTypeCode === wasteTypeCode)
			)
		},
		[updatedHandledBySmartInfra, terminalWasteTypeConfig]
	)

	const onSave = useCallback(async () => {
		if (!selectedTerminalId) return
		try {
			await updateCoreTerminalConfig(
				{
					id: selectedTerminalId,
					status: terminalStatus,
					fractionDiscriminator: wasteTypeClassificationSystemId ?? undefined,
				},
				{
					onSuccess: async () => {
						// Change the current terminal if the status is changed to inactive
						if (terminalStatus === PointStatus.INACTIVE) {
							// Find the first terminal that is active and not the current one
							const newTerminal = terminals.find(
								t => t.status === PointStatus.ACTIVE && t.id !== selectedTerminalId
							)
							if (!newTerminal) return
							setCurrentTerminal(newTerminal)
						}
					},
				}
			)
			await updateTerminalConfig({
				terminalId: selectedTerminalId,
				allowAppPermanentLogin: permanentLogin,
				wasteTypeClassificationSystemId: wasteTypeClassificationSystemId ?? undefined,
				timezone: timezone ?? undefined,
				language: language || null,
				maxWeightPerThrow: maxWeightPerThrow ?? null,
			})

			if (hasChangedHandledBySmartInfra) {
				await updateTerminalWasteTypeConfig({
					terminalId: selectedTerminalId,
					updates: updatedHandledBySmartInfra.map(({ code, handledBySmartInfra }) => ({
						wasteTypeCode: code,
						handledBySmartInfra,
						names: [],
					})),
				})
			}

			if (hasChangedPricing) {
				await mutatePricing({
					terminalId: selectedTerminalId,
					price_cents: Number(pricing.price) * 100,
					currency: pricing.currency,
				})
			}

			setHasUnsavedChanges(false)
			setGlobalAlert({
				type: "success",
				message: "systemMessages:changesSaved",
			})
		} catch (error: Error | any) {
			console.error(error)
			setGlobalAlert({
				type: "error",
				message: "errors:failedSave",
				instructions: error?.message,
			})
		}
	}, [
		selectedTerminalId,
		terminalStatus,
		updateCoreTerminalConfig,
		wasteTypeClassificationSystemId,
		updateTerminalConfig,
		permanentLogin,
		timezone,
		language,
		hasChangedHandledBySmartInfra,
		setHasUnsavedChanges,
		setGlobalAlert,
		setCurrentTerminal,
		terminals,
		updateTerminalWasteTypeConfig,
		updatedHandledBySmartInfra,
		maxWeightPerThrow,
		hasChangedPricing,
		mutatePricing,
		pricing,
	])

	const openDeletionModal = () => {
		if (!selectedTerminal) return

		showModal(
			<DeleteTerminalModal
				terminalId={selectedTerminal.id}
				terminalName={selectedTerminal.name}
				onSuccess={() => {
					setSelectedTerminalId(undefined)
				}}
			/>
		)
	}

	const isActiveTerminal = useMemo(() => terminalStatus === PointStatus.ACTIVE, [terminalStatus])
	const canDeleteTerminal = selectedTerminal?.children?.length === 0
	const isValidMaxWeight = getIsValidMaxWeight(maxWeightPerThrow)

	const isSaving =
		isUpdatingAppConfig ||
		isUpdatingCoreTerminalConfig ||
		isUpdatingTerminalWasteTypeConfig ||
		isUpdatingPricing

	return (
		<div className="space-y-4">
			<ul className="list-disc list-inside ml-2">
				<Translate i18nKey="hints:configureBuildings" children={[<li />]} />
			</ul>
			<div className="max-w-[380px]">
				<label className="select-none">{t("configLabels:selectBuilding")}</label>
				<StyledSelect
					className="mb-4 mt-1"
					options={terminalOptions}
					value={terminalOptions.find(o => o.value === selectedTerminalId) || null}
					onChange={onTerminalChange}
					optionClassName={value =>
						terminals.find(t => t.id === value)?.status === PointStatus.INACTIVE ? "text-grey6" : ""
					}
				/>
			</div>
			{selectedTerminalId && (
				<>
					<h2 className="text-xl font-signifier pt-2">{t("configLabels:buildingState")}</h2>
					<Toggle
						label={t("configLabels:buildingActiveToggleLabel")}
						checked={terminalStatus === PointStatus.ACTIVE}
						onChange={onTerminalStatusToggle}
					/>
					{isActiveTerminal && (
						<>
							<h2 className="text-xl font-signifier pt-2">
								{t("configLabels:buildingParameters")}
							</h2>
							<div className="max-w-[380px]">
								<label className="select-none">{t("formLabels:defaultWTCSTerminal")}</label>
								<StyledSelect
									className="mt-1"
									options={wasteTypeClassificationSystemOptions}
									value={
										wasteTypeClassificationSystemOptions.find(
											o => o.value === wasteTypeClassificationSystemId
										) ?? resetOption
									}
									onChange={onWasteTypeClassificationSystemIdChange}
								/>
							</div>
						</>
					)}
					{isLoadingTerminalConfig ? (
						<div>
							<Skeleton count={3} height={38} className="mb-2" />
						</div>
					) : isActiveTerminal ? (
						<div className="max-w-[380px]">
							<label className="select-none">{t("formLabels:defaultTimezoneTerminal")}</label>
							<StyledSelect
								className="mt-1 mb-4"
								options={timezoneOptions}
								value={timezoneOptions.find(o => o.value === timezone) ?? resetOption}
								onChange={onTimezoneChange}
							/>
							<label className="select-none">{t("configLabels:buildingLanguage")}</label>
							<StyledSelect
								className="mt-1 mb-4"
								options={languageOptions}
								value={languageOptions.find(o => o.value === language) ?? resetOption}
								onChange={onLanguageChange}
							/>
							<MaxWeightPerThrowInput
								value={maxWeightPerThrow}
								onChange={setMaxWeightPerThrow}
								error={!isValidMaxWeight}
								label={t("configLabels:buildingMaxWeight")}
								placeholder={t("formLabels:buildingMaxWeightPlaceholder", {
									weight: clientConfig?.maxWeightPerThrow || t("common:defaultMaxWeightPerThrow"),
								})}
							/>
							<Toggle
								label={t("configLabels:permanentLogin")}
								checked={permanentLogin}
								onChange={() => setPermanentLogin(!permanentLogin)}
							/>
							<h2 className="mt-4 text-xl font-signifier pt-2">
								{t("configLabels:buildingPaymentSettings")}
							</h2>
							<Payments
								setHasUnsavedChanges={setHasChangedPricing}
								setPricing={setPricing}
								updatedPricing={pricing}
								currentTerminalId={selectedTerminalId}
								isSaving={isUpdatingPricing}
							/>
						</div>
					) : null}
					{isActiveTerminal && (
						<div className="pt-4">
							<h3 className="text-xl font-signifier mb-2">
								{t("configLabels:wasteTypesHandledBySmartInfra")}
							</h3>
							<Translate i18nKey="hints:configureTwtHandledBySmartInfra" children={[<li />]} />
							<div className="mt-4">
								{isLoadingAccessParents ? (
									<Skeleton count={5} height={30} borderRadius={0} />
								) : (
									containers.map(container => {
										const {
											wasteType: { code, name },
										} = container

										const containerName = getWasteTypeName(sanityWasteTypes || [], code) || name
										const existing = findHandledBySmartInfra(code)

										return (
											<div key={code} className="my-2 flex">
												<Toggle
													checked={existing?.handledBySmartInfra ?? false}
													onChange={checked => onWasteTypeHandledBySmartInfraChange(code, checked)}
												/>
												<span className="pl-4">
													{containerName} ({code})
												</span>
											</div>
										)
									})
								)}
							</div>
						</div>
					)}
					<div className="sticky bottom-0 w-full bg-white">
						<hr className="-mx-4" />
						<div className="p-4 flex gap-16">
							<Button
								label="actions:save"
								disabled={!hasUnsavedChanges || !isValidMaxWeight}
								onClick={onSave}
								loading={isSaving}
							/>
							{isActiveTerminal && (
								<div className="flex gap-4 items-center">
									<Button
										label={"actions:deleteTerminal"}
										color="black"
										className="float-right"
										disabled={!canDeleteTerminal}
										onClick={openDeletionModal}
									/>
									<div className="text-xs leading-4">
										<Translate
											i18nKey={
												canDeleteTerminal
													? "hints:deleteBuildingEnabled"
													: "hints:deleteBuildingDisabled"
											}
											components={[
												// eslint-disable-next-line
												<a
													href={`/infrastructure/manage/${selectedTerminalId}/`}
													className="underline"
												/>,
											]}
										/>
									</div>
								</div>
							)}
						</div>
					</div>
				</>
			)}
		</div>
	)
}
