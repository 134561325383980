import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { isEmpty } from "lodash"
import { useForm } from "react-hook-form"
import { WasteRoomForm, WasteRoomFormData } from "../manage/wasteRoomForm"
import { useGlobalAlert } from "States/globalAlert"
import { useAccessParentsWithPoints } from "../manage/useAccessParentsWithPoints"

export const EditWasteRoomModal: React.FC<{
	wasteRoomId: string
}> = ({ wasteRoomId }) => {
	const { hideModal } = useModal()
	const {
		isLoading: isLoadingWasteRooms,
		refetch,
		getAccessParent,
		updateAccessParent,
	} = useAccessParentsWithPoints()
	const { setGlobalAlert } = useGlobalAlert()

	const station = getAccessParent(wasteRoomId)

	const formHandler = useForm<WasteRoomFormData>({
		defaultValues: { ...station },
	})

	const {
		formState: { errors, isValid },
		handleSubmit,
	} = formHandler

	const onSubmit = handleSubmit(roomFormData => {
		const { name } = roomFormData

		updateAccessParent({
			id: wasteRoomId,
			name,
		}).then(() => {
			setGlobalAlert({
				type: "success",
				message: "systemMessages:changesSaved",
			})
			refetch().then(hideModal)
		})
	})

	return (
		<ModalContainer
			title="drawerLabels:accessParentDetails"
			onConfirmText={"actions:save"}
			onConfirm={() => onSubmit()}
			onCancel={hideModal}
			onConfirmLoading={isLoadingWasteRooms}
			onConfirmDisabled={!isEmpty(errors) || !isValid}
			className="w-3/4 sm:w-1/2 lg:w-1/3"
		>
			<WasteRoomForm {...{ formHandler, onSubmit }} />
		</ModalContainer>
	)
}
