import { Button } from "components/button/index"
import TrashIcon from "Icons/Trash"
import { FC } from "react"
import { useFieldArray, UseFormReturn } from "react-hook-form"
import { useTrans } from "translations"
import { CreateTenantFormValues, CustomerContactPerson } from "../../types"
import { TenantContactInfoForm } from "./TenantContactInfoForm"

const defaultContactPerson: CustomerContactPerson = {
	name: "",
	email: "",
	phone: "",
}

interface TenantContactInfoFormProps {
	formHandler: UseFormReturn<CreateTenantFormValues>
	useNumberForHint?: boolean
}

export const TenantContactInfoFormSection: FC<TenantContactInfoFormProps> = ({
	formHandler,
	useNumberForHint = true,
}) => {
	const { t } = useTrans()
	const { control } = formHandler
	const {
		fields: contactPeople,
		append,
		remove: removeRow,
	} = useFieldArray({
		control,
		name: "contactPeople",
	})

	const addRow = () => append(defaultContactPerson, { shouldFocus: false })

	return (
		<div className="md:ml-3">
			<h2 className="mb-2 font-dmSans text-base font-medium">{`${useNumberForHint ? "2. " : ""}${t(
				"hints:tenantContactInfo"
			)}`}</h2>
			<p className="mb-2 font-dmSans text-xs font-normal text-black">
				{t("hints:tenantContactInfoDetails")}
			</p>
			{contactPeople.map((row, index) => (
				<div key={row.id} className={`flex ${index > 0 ? "mt-2" : ""}`}>
					<TenantContactInfoForm formHandler={formHandler} index={index} />
					<button
						onClick={() => removeRow(index)}
						className={"ml-3 mt-2 transition duration-300"}
						type="button"
						style={{ visibility: contactPeople.length > 1 ? "visible" : "hidden" }}
					>
						<TrashIcon />
					</button>
				</div>
			))}
			<Button
				className="mt-2"
				label="actions:addRow"
				onClick={addRow}
				type="button"
				color="secondary"
			/>
		</div>
	)
}
