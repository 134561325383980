import { COORDINATES_REGEX } from "Utils/isValidCoordinatesValue"
import FormInput from "components/Form/FormInput"
import SearchInput from "components/Form/SearchInput"
import { FC, useMemo, useRef } from "react"
import { UseFormReturn } from "react-hook-form"
import { useTrans } from "translations"
import { POINT_STATUSES } from "admin-client-server/src/coreApi/common/constants"
import { PointStatus } from "Utils/terminal"

export type StationFormData = {
	name: string
	coordinates: string
	municipality: string
	client: string
	status: PointStatus
}

export const StationForm: FC<{
	formHandler: UseFormReturn<StationFormData>
}> = ({ formHandler }) => {
	const parentElementRef = useRef(null)
	const { t } = useTrans()
	const {
		register,
		formState: { errors },
		control,
		setValue,
	} = formHandler

	const stationOptions = useMemo(
		() =>
			POINT_STATUSES.map(value => ({
				option: t(`status:${value}`),
				value,
			})),
		[t]
	)

	return (
		<form>
			<div className="mb-6">
				<FormInput
					name="name"
					label={t("formLabels:name")}
					register={register}
					error={errors?.name}
					required
				/>
			</div>
			<div className="mb-6">
				<FormInput
					name="coordinates"
					label={t("formLabels:coordinates")}
					register={register}
					error={errors?.coordinates}
					regexPattern={COORDINATES_REGEX}
					patternError="errors:mustBeValidCoordinates"
				/>
			</div>
			<div className="mb-6">
				<SearchInput
					label={t("formLabels:status")}
					name={"status"}
					data={stationOptions}
					initialValue={formHandler.getValues("status")}
					required
					{...{ control, setValue, parentElementRef }}
				/>
			</div>
			<div className="mb-6">
				<FormInput
					name="municipality"
					label={t("formLabels:municipalityNumber")}
					register={register}
					error={errors?.municipality}
				/>
			</div>
			<div className="mb-6">
				<FormInput
					name="client"
					label={t("formLabels:client")}
					register={register}
					error={errors?.client}
				/>
			</div>
		</form>
	)
}
