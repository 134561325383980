import { Cell, flexRender } from "@tanstack/react-table"
import { twMerge } from "tailwind-merge"
import { DataTableDataType, ExpandedColumnDef } from ".."
import getCommonPinningStyles from "../utils/getCommonPinningStyles"

type TableCellProps = {
	cell: Cell<any, DataTableDataType>
	isSelected: boolean
}

export const TableCell: React.FC<TableCellProps> = ({ cell, isSelected }) => {
	const { rightAlign } = cell.column.columnDef as ExpandedColumnDef

	const isActions = cell.column.id === "actions"
	const isPinned = cell.column.getIsPinned()

	return (
		<td
			key={cell.id}
			className={twMerge(
				"overflow-hidden text-ellipsis whitespace-nowrap px-2.5 py-2.5",
				rightAlign && "justify-end text-end pr-8",
				isPinned === "right" && "font-medium !bg-grey1",
				isSelected && "!bg-selectedColor",
				"group-hover:!bg-hoverColor",
				isActions && "overflow-visible"
			)}
			style={getCommonPinningStyles(cell.column)}
		>
			{flexRender(cell.column.columnDef.cell, cell.getContext())}
		</td>
	)
}
