import { Popover } from "@headlessui/react"
import ArrowDown from "Icons/ArrowDown"
import classNames from "classnames"
import { FilterSort } from "./types"
import { useTrans } from "translations"
import { FilterSortMenu } from "./FilterSortMenu"
import { useFilterSort } from "./FilterSortContext"
import { useMemo } from "react"
import { Card, CardComponentFilterLocation } from "../card"
import { OPTION_ALL } from "./constants"
import { FilterTimeFrameValue } from "admin-client-server/src/config-api"

type CollapsedFilterSortProps = {
	filterSorts: FilterSort[]
} & Pick<React.ComponentProps<typeof Card>, "filterLocation">

const valuesIgnoredForActiveCount = ["", OPTION_ALL, FilterTimeFrameValue.SinceBeginning]

export const CollapsedFilterSortButton: React.FC<CollapsedFilterSortProps> = ({
	filterSorts,
	filterLocation,
}) => {
	const { t } = useTrans()
	const { activeOptions } = useFilterSort()

	const activeCount = useMemo(() => {
		const activeOptionsLength = Object.keys(activeOptions).filter(id => {
			const filterSort = filterSorts.find(filterSort => filterSort.id === id)
			return (
				filterSort?.type === "filter" &&
				!activeOptions[id].find(option => valuesIgnoredForActiveCount.includes(option.value))
			)
		}).length
		return activeOptionsLength > 0
			? t("filterLabels:activeCount", { count: activeOptionsLength })
			: ""
	}, [activeOptions, t, filterSorts])

	return (
		<Popover className="relative">
			{({ open, close }) => (
				<>
					<Popover.Button
						className={classNames(
							"flex items-center gap-2 text-lg bg-grey1 py-1 px-2 text-sm h-8",
							open && "bg-grey2"
						)}
					>
						<span className="whitespace-nowrap">
							{t("filterLabels:filters")}
							<span className="pl-1">{activeCount}</span>
						</span>
						<ArrowDown
							className={classNames("overflow-visible", open && "rotate-180 transition-transform")}
						/>
					</Popover.Button>
					<Popover.Panel
						className={classNames(
							"absolute z-20",
							filterLocation === CardComponentFilterLocation.RIGHT && "right-0"
						)}
					>
						<FilterSortMenu close={close} filterSorts={filterSorts} collapsable />
					</Popover.Panel>
				</>
			)}
		</Popover>
	)
}
