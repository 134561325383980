import { useTerminalsState } from "States/Terminals"
import { trpc } from "Utils/trpc"
import { orderBy } from "lodash"
import { useMemo } from "react"
import { useParams } from "react-router-dom"

export const useAccessParentsWithPoints = (overrideTerminalId?: string) => {
	const { terminalId: urlTerminalId } = useParams()
	const { currentTerminal } = useTerminalsState()

	const terminalId = overrideTerminalId || urlTerminalId || currentTerminal?.id

	const {
		data: unsortedAccessParents,
		isLoading,
		isError,
		refetch,
	} = trpc.accessParents.getAllWithPointsByParentId.useQuery(
		{
			terminalId: terminalId!,
		},
		{ enabled: !!terminalId }
	)

	const accessParents = useMemo(
		() => orderBy(unsortedAccessParents, "name"),
		[unsortedAccessParents]
	)

	const getAccessParent = (accessParentId?: string) =>
		accessParents?.find(({ id }) => id === accessParentId)

	const { mutateAsync: updateAccessParent } = trpc.accessParents.update.useMutation()

	return {
		accessParents,
		isLoading,
		isError,
		getAccessParent,
		refetch,
		updateAccessParent,
	}
}
