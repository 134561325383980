import useEvents from "api/hooks/useEvents"
import { EmptyView } from "components/EmptyView/EmptyView"
import { Page } from "components/Page"
import { TableWithSelect } from "components/TableWithSelect"
import { Card } from "components/pageCards/card"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { useConfig } from "api/hooks/useConfig"
import moment from "moment"
import { MWMEventTableHeaders, REEventTableHeaders } from "./eventColumns"
import { useModal } from "Contexts"
import { DeleteEventModal } from "./modals/DeleteEventModal"
import { EditEventModal } from "./modals/EditEventModal"
import { get } from "lodash"
import { isCarrotAdmin } from "Utils/authUtils"

export const Events: FC<{}> = () => {
	const {
		data,
		filters,
		isLoading,
		isError,
		error,
		hasNextPage,
		fetchNextPage,
		isFetchingNextPage,
		setSortingState,
		selectedEventIds,
		setSelectedEventIds,
		setFilterState,
		setAllSelected,
		exportFile,
		startTimeISO,
		endTimeISO,
		areAllSelected,
		deleteEvent,
		editEvent,
		isRefetchingEvents,
	} = useEvents()

	const isAdmin = isCarrotAdmin()
	const { isMWM, config } = useConfig()

	const { showModal } = useModal()

	const [loadingCSVData, setLoadingCSVData] = useState(false)
	const [loadingExcelData, setLoadingExcelData] = useState(false)

	const onRowClick = useCallback(
		(row: any) => {
			const rowId = row["id"] as string
			if (selectedEventIds.includes(rowId)) {
				setSelectedEventIds(selectedEventIds.filter((id: string) => id !== rowId))
			} else {
				setSelectedEventIds([...selectedEventIds, rowId])
			}
		},
		[selectedEventIds, setSelectedEventIds]
	)

	const showError = useMemo(() => !isLoading && isError, [isLoading, isError])
	const showEmpty = useMemo(
		() => !isLoading && !isError && !data.length,
		[isLoading, isError, data]
	)

	const showTable = useMemo(
		() => isLoading || (data.length > 0 && !isError),
		[isLoading, data, isError]
	)

	const eventTableHeaders = useMemo(
		() =>
			isMWM
				? MWMEventTableHeaders({
						useWasteSuctionSystem: config.useWasteSuctionSystem,
						usesCRM: config.useExternalCRM,
					})
				: REEventTableHeaders,
		[isMWM, config]
	)

	useEffect(() => {
		if (loadingExcelData) {
			exportFile("excel").then(() => setLoadingExcelData(false))
		}
	}, [loadingExcelData, exportFile])

	useEffect(() => {
		if (loadingCSVData) {
			exportFile("csv").then(() => setLoadingCSVData(false))
		}
	}, [loadingCSVData, exportFile])

	const showConfirmationDialog = useMemo(() => {
		const start = moment(startTimeISO)
		const end = endTimeISO ? moment(endTimeISO) : moment()

		const difference = end.diff(start, "days")
		return difference > (isMWM ? 31 : 365) // limit is 1 month for MWM and 1 year for RE
	}, [startTimeISO, endTimeISO, isMWM])

	const onEdit = useCallback(
		(row: any) => {
			const eventId = row.id
			const initialEventPointId = get(row, "point.id", "")
			const initialEventWeightKg = get(row, "weight.quantity", 0)
			const eventAccessParentId = get(row, "accessParent.id", "")

			showModal(
				<EditEventModal
					eventId={eventId}
					initialEventPointId={initialEventPointId}
					initialEventWeightKg={initialEventWeightKg}
					eventAccessParentId={eventAccessParentId}
					editEvent={editEvent}
				/>
			)
		},
		[showModal, editEvent]
	)

	const onDelete = useCallback(
		(eventId: string) =>
			showModal(<DeleteEventModal eventId={eventId} deleteEvent={deleteEvent} />),
		[showModal, deleteEvent]
	)

	return (
		<Page fullHeight title="sidebarLabels:events">
			<Card
				filters={filters}
				onFilterOptionChange={setFilterState}
				hasXContentPadding={false}
				hasYContentPadding={false}
				overflow="auto"
				useSearchParams
				exportButton={{
					nrSelected: selectedEventIds.length,
					areAllSelected,
					isLoading: loadingExcelData || loadingCSVData,
					exportExcelFunction: () => setLoadingExcelData(true),
					exportCsvFunction: () => setLoadingCSVData(true),
					showConfirmationDialog,
				}}
			>
				{showError && <EmptyView type="unableToFetchEvents" error={error} />}
				{showEmpty && <EmptyView type="noEvents" />}
				{showTable && (
					<TableWithSelect
						selectedIds={selectedEventIds}
						setSelectedIds={setSelectedEventIds}
						headers={eventTableHeaders}
						defaultSortingHeader={{ id: "timestamp", desc: true }}
						updateSorting={setSortingState}
						labelSingle="entities:event"
						labelMultiple="sidebarLabels:events"
						hasExportText={false}
						showExportButton={false}
						pagination={{
							isFetchingNextPage,
							hasNextPage: hasNextPage,
							fetchNextPage,
							isRefetching: isRefetchingEvents,
						}}
						useActions={isAdmin && !isMWM}
						{...{ data, isLoading, onRowClick, onAllSelected: setAllSelected, onEdit, onDelete }}
					/>
				)}
			</Card>
		</Page>
	)
}
