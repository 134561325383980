import { sortAlphabeticallyByProperty } from "Utils/sorting"
import { FilterSort, FilterSortMenuType } from "components/pageCards/filterSort/types"
import { OPTION_ALL } from "components/pageCards/filterSort/constants"
import { uniqBy } from "lodash"
import { MinimalCustomer } from "admin-client-server/src/coreApi/customers/types"
import { Terminal } from "admin-client-server/src/coreApi/terminals/types"
import { GROUP } from "admin-client-server/src/coreApi/accessPoints/types"
import { ACCESS_PARENT } from "admin-client-server/src/coreApi/accessParents/types"

export const createCustomerFilter = (customerList: MinimalCustomer[] | undefined): FilterSort => ({
	type: "filter",
	menuType: FilterSortMenuType.Select,
	options: [
		{ option: "filterLabels:allCustomers", value: OPTION_ALL, translate: true },
		...(
			uniqBy(customerList, "id")?.map(
				({ id: value, name: option }: { id: string; name: string }) => ({
					option,
					value,
				})
			) ?? []
		)
			.filter(el => !!el.option)
			.sort((a, b) => sortAlphabeticallyByProperty(a, b, "option")),
	],
	title: "filterLabels:customers",
	id: "customerId",
	defaultValue: OPTION_ALL,
})

export const createEventTypeFilter = (): FilterSort => ({
	type: "filter",
	menuType: FilterSortMenuType.Select,
	options: [
		{ option: "filterLabels:allEventTypes", value: OPTION_ALL, translate: true },
		...(["use", "empty"].map(value => ({
			value,
			option: `eventTypes:${value.toUpperCase()}`,
			translate: true,
		})) ?? []),
	],
	title: "filterLabels:eventTypes",
	id: "eventType",
	defaultValue: OPTION_ALL,
})

export const createAccessParentFilter = (elements: ACCESS_PARENT[] | undefined): FilterSort => ({
	type: "filter",
	menuType: FilterSortMenuType.Select,
	options: [
		{ option: "filterLabels:allAccessParents", value: OPTION_ALL, translate: true },
		...(
			elements?.map(({ id: value, name: option }: { id: string; name: string }) => ({
				option,
				value,
			})) ?? []
		)
			.filter(el => !!el.option)
			.sort((a, b) => sortAlphabeticallyByProperty(a, b, "option")),
	],
	title: "entities:accessParents",
	id: "accessParentId",
	unsetsFilters: ["depotId"],
	defaultValue: OPTION_ALL,
})

export const createDepotFilter = (elements: GROUP[] | undefined): FilterSort => ({
	type: "filter",
	menuType: FilterSortMenuType.Select,
	options: [
		{ option: "filterLabels:allDepots", value: OPTION_ALL, translate: true },
		...(
			elements?.map(({ id: value, name: option }: { id: string; name?: string }) => ({
				option: option ?? "",
				value,
			})) ?? []
		)
			.filter(el => !!el.option)
			.sort((a, b) => sortAlphabeticallyByProperty(a, b, "option")),
	],
	title: "entities:depots",
	id: "depotId",
	defaultValue: OPTION_ALL,
})

export const createTerminalFilter = (elements: Terminal[] | undefined): FilterSort => ({
	type: "filter",
	menuType: FilterSortMenuType.Select,
	options: [
		{ option: "filterLabels:allTerminals", value: OPTION_ALL, translate: true },
		...(
			elements?.map(({ id: value, name: option }: { id: string; name: string }) => ({
				option,
				value,
			})) ?? []
		)
			.filter(el => !!el.option)
			.sort((a, b) => sortAlphabeticallyByProperty(a, b, "option")),
	],
	title: "entities:terminals",
	id: "filterTerminalId",
	defaultValue: OPTION_ALL,
	unsetsFilters: ["accessParentId", "depotId"],
})

export const createResultCodeFilter = (): FilterSort => ({
	type: "filter",
	menuType: FilterSortMenuType.Select,
	options: [
		{ option: "filterLabels:allResultCodes", value: OPTION_ALL, translate: true },
		{ option: "genericLabels:success", value: "accepted", translate: true },
		{ option: "genericLabels:fail", value: "rejected", translate: true },
	],
	title: "entities:resultCodes",
	id: "resultCode",
	defaultValue: OPTION_ALL,
})
