import { trpc } from "Utils/trpc"
import { FC, useCallback, useEffect, useState } from "react"
import { LoadingIndicator } from "Icons/loadingIndicator"
import { Page } from "components/Page"
import { useTrans } from "translations"
import { Button } from "components/button"
import { useSites } from "api/hooks/useSites"

type GlobalSettingsProps = {}
type SiteData = {
	id: string
	namespace: string
	siteName: string | null
	displayName: string | null
}

export const GlobalSettings: FC<GlobalSettingsProps> = () => {
	const { t } = useTrans()
	const { sites, isLoading } = useSites()

	const { mutate, isLoading: isUpdating } = trpc.config.updateSites.useMutation()
	const [siteData, setSiteData] = useState<SiteData[]>([])

	useEffect(() => {
		if (sites) {
			setSiteData(sites)
		}
	}, [sites])

	const handleDisplayNameChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>, siteId: string) => {
			const updatedSiteData = siteData.map(site => {
				if (site.id === siteId) {
					return {
						...site,
						displayName: e.target.value,
					}
				}
				return site
			})
			setSiteData(updatedSiteData)
		},
		[siteData, setSiteData]
	)

	const onSave = useCallback(() => {
		const sitesWithDisplayName = siteData.filter(site => !!site.displayName) as {
			id: string
			displayName: string
		}[]
		mutate({ sites: sitesWithDisplayName.map(site => ({ ...site, id: Number(site.id) })) })
	}, [siteData, mutate])

	if (isLoading) {
		return (
			<div className="flex justify-center items-center min-h-[300px]">
				<LoadingIndicator />
			</div>
		)
	}

	return (
		<Page title="configLabels:settings">
			<div className={"bg-white py-4"}>
				<div className="px-4">
					<table className="table-auto w-full text-sm">
						<thead>
							<tr className="border-b h-12 text-left">
								<th>{t("configLabels:namespace")}</th>
								<th>{t("configLabels:siteName")}</th>
								<th>{t("configLabels:displayName")}</th>
							</tr>
						</thead>
						<tbody>
							{siteData?.map(site => (
								<tr key={site.id} className="border-b last-of-type:border-none h-12">
									<td>{site.namespace}</td>
									<td>{site.siteName}</td>
									<td>
										<input
											value={site.displayName || ""}
											onChange={e => handleDisplayNameChange(e, site.id)}
											data-1p-ignore
										/>
									</td>
								</tr>
							))}
						</tbody>
					</table>
					<div className="mt-8">
						<Button label={t("actions:save")} onClick={onSave} loading={isUpdating} />
					</div>
				</div>
			</div>
		</Page>
	)
}
