import { Page } from "components/Page"
import { InactiveTenantsCard } from "./components/inactiveTenantsCard"
import { MixedWastePerformance } from "./components/mixedWastePerformance"
import { PercentagePerformance } from "./components/percentagePerformance"
import { PieWasteContribution } from "./components/pieWasteContribution"
import { TenantNumberCard } from "./components/tenantNumberCard"
import { useTerminalsState } from "States/Terminals"
import { LoadingIndicator } from "Icons/loadingIndicator"

export const TenantDashboard = () => {
	const { currentTerminal } = useTerminalsState()

	return (
		<Page title="sidebarLabels:tenantFollowUp">
			<div className="grid grid-cols-1 gap-2 lg:grid-cols-2">
				{!currentTerminal?.id ? (
					<div className="flex justify-center">
						<LoadingIndicator />
					</div>
				) : (
					<>
						<PercentagePerformance />
						<MixedWastePerformance />
						<PieWasteContribution />
						<TenantNumberCard />
						<InactiveTenantsCard />
					</>
				)}
			</div>
		</Page>
	)
}

export default TenantDashboard
