import { UseFormReturn, useFieldArray, useWatch } from "react-hook-form"
import { MWMCustomerFormValues } from "../../types"
import { FC, useCallback, useEffect, useMemo } from "react"
import { useTrans } from "translations"
import SelectInput from "components/Form/SelectInput"
import { useAccessParents } from "pages/infrastructure/functions"
import { Button } from "components/button"
import { CustomerDetailsForm } from "./CustomerDetailsForm"
import { RemoveButton } from "components/RemoveButton"
import { isEmpty, xor } from "lodash"

interface CustomerDetailsFormSectionProps {
	formHandler: UseFormReturn<MWMCustomerFormValues>
}

const uniques = (arr: any[]) => xor(...arr.map(a => [a]))
const duplicates = (arr: any[]) => xor(arr, uniques(arr))

export const CreateCustomerForm: FC<CustomerDetailsFormSectionProps> = ({ formHandler }) => {
	const { accessParents } = useAccessParents()
	const { t } = useTrans()
	const {
		control,
		getValues,
		trigger,
		formState: { errors },
	} = formHandler

	const selectedAccessParents = useWatch({ control, name: "accessParents" })

	const {
		fields: accessParentFields,
		append,
		remove: removeRow,
	} = useFieldArray({
		control,
		name: "accessParents",
	})

	useEffect(() => {
		selectedAccessParents?.forEach((el: { id: string }, index) => {
			if (accessParents.find(({ id }) => id === el.id)) {
				trigger(`accessParents.${index}.id`)
			}
		})
	}, [accessParents, selectedAccessParents, trigger])

	const isValidUnique = useCallback(
		(value: string) => {
			const allValues = getValues()["accessParents"]?.map((el: { id: string }) => el.id)
			if (!allValues) return true
			return duplicates(allValues).includes(value) ? t("errors:duplicateStationAdded") : true
		},
		[getValues, t]
	)

	const addRow = () => append({ id: "" }, { shouldFocus: false })

	const accessParentOptions = useMemo(
		() => accessParents.map(({ id, name }) => ({ label: name, value: id })),
		[accessParents]
	)

	return (
		<form className="flex flex-col gap-4 md:flex-row 2xl:justify-between">
			<CustomerDetailsForm formHandler={formHandler} isEdit={false} />
			<div className="md:ml-3 flex-1">
				<h2 className="mb-2 font-dmSans text-base font-medium">{`2. ${t(
					"entities:accessParents"
				)}`}</h2>
				{accessParentFields.map((row, index) => (
					<div key={row.id} className="flex my-3">
						<div className="flex-1">
							<SelectInput
								name={`accessParents.${index}.id`}
								control={control}
								options={accessParentOptions}
								validationFunction={val => isValidUnique(val)}
							/>
						</div>
						<RemoveButton onClick={() => removeRow(index)} className="ml-3" type="button" />
					</div>
				))}
				<Button
					className="mt-2"
					label="actions:addRow"
					onClick={addRow}
					type="button"
					color="secondary"
					disabled={accessParentFields.length === accessParents.length || !isEmpty(errors)}
				/>
			</div>
		</form>
	)
}
