import { FC } from "react"
import ModalContainer from "components/modalContainer"
import ErrorNotice from "components/ErrorNotice"
import { useModal } from "Contexts"
import { useForm } from "react-hook-form"
import { useTrans } from "translations"
import { trpc } from "Utils/trpc"
import { AllocationForm, AllocationFormData } from "./allocationForm"
import { useGlobalAlert } from "States/globalAlert"
import { useNavigate } from "Utils/useNavigate"

type Props = {
	onSuccess: () => void
}

export const CreateAllocationModal: FC<Props> = ({ onSuccess }) => {
	const { mutate, isLoading, isError } = trpc.allocations.create.useMutation()
	const { setGlobalAlert } = useGlobalAlert()
	const navigate = useNavigate()
	const { t } = useTrans()
	const { hideModal } = useModal()
	const formHandler = useForm<AllocationFormData>()

	const { handleSubmit } = formHandler

	const onConfirm = handleSubmit(fieldData => {
		const geoLocationCode = `${fieldData.municipalityNumber}.${fieldData.streetCode}.${fieldData.houseNumber}`
		const allocationData = {
			geoLocationCode,
			geoLocationName: fieldData.name,
		}
		mutate(allocationData, {
			onSuccess: id => {
				hideModal()
				setGlobalAlert({
					type: "success",
					message: "systemMessages:allocationAdded",
				})
				onSuccess()
				navigate(`/allocations/${id}`)
			},
			onError: () => {
				setGlobalAlert({
					type: "error",
					message: "errors:failedSave",
				})
			},
		})
	})

	return (
		<ModalContainer
			title="actions:createAllocation"
			onConfirm={onConfirm}
			onCancel={hideModal}
			onConfirmLoading={isLoading}
			className="w-1/2 lg:w-1/3"
		>
			{isError ? (
				<ErrorNotice />
			) : (
				<AllocationForm title={t("drawerLabels:allocation")} formHandler={formHandler} />
			)}
		</ModalContainer>
	)
}
