import { FC, useEffect, useMemo } from "react"
import ModalContainer from "components/modalContainer"
import ErrorNotice from "components/ErrorNotice"
import { Translate, useTrans } from "translations"
import { useModal } from "Contexts"
import { trpc } from "Utils/trpc"
import { useForm } from "react-hook-form"
import { FormColumn, FormFieldType, GridForm } from "components/GridForm"
import { LoadingIndicator } from "Icons/loadingIndicator"
import { useGlobalAlert } from "States/globalAlert"
import { ReadOnlyRedundancyPoints } from "pages/customer/manage/components/CustomerAccessParentsFormSection"
import { sortBy } from "lodash"
import { AccessParentWithRedundancyPoints } from "admin-client-server/src/coreApi/accessParents/types"

type Props = {
	allocationId: string
	pointsWithRedundancyPoints?: AccessParentWithRedundancyPoints[]
	onSuccess: () => void
}

type Station = {
	id: string
	name: string
}

type StationForm = {
	stations: Station[]
}

export const EditAllocationStationsModal: FC<Props> = ({
	allocationId,
	pointsWithRedundancyPoints,
	onSuccess,
}) => {
	const { t } = useTrans()
	const { hideModal, ref: modalRef } = useModal()
	const { setGlobalAlert } = useGlobalAlert()

	const formHandler = useForm<StationForm>({
		defaultValues: {
			stations: pointsWithRedundancyPoints,
		},
	})
	const {
		handleSubmit,
		getValues,
		watch,
		setValue,
		formState: { isDirty },
	} = formHandler

	const { data, isLoading: isLoadingAccessParents } = trpc.accessParents.getAll.useQuery({
		withRedundancyPoints: true,
	})

	const accessParents = useMemo(() => sortBy(data ?? [], "name"), [data])

	useEffect(() => {
		const watchFields = watch((_, { name, type }) => {
			if (type === "change" && name?.split(".").pop() === "id") {
				const index = Number(name.split(".")[1])
				const id = getValues(`stations.${index}.id`)
				const accessParent = accessParents.find(s => s.id === id)!
				setValue(`stations.${index}`, accessParent)
			}
		})
		return () => watchFields.unsubscribe()
	}, [watch, getValues, setValue, accessParents])

	const {
		isLoading: isSaving,
		isError,
		mutate,
	} = trpc.allocations.setStations.useMutation({
		onSuccess: () => {
			hideModal()
			setGlobalAlert({
				type: "success",
				message: "systemMessages:changesSaved",
			})
			onSuccess()
		},
		onError: () => {
			setGlobalAlert({
				type: "error",
				message: "errors:failedSave",
			})
		},
	})

	const onConfirm = handleSubmit(fieldData => {
		mutate({ id: allocationId, stations: fieldData.stations.filter(s => !!s.id) })
	})

	const columns = useMemo(
		() =>
			[
				{
					enabled: true,
					label: t("entities:accessParent"),
					fieldType: FormFieldType.SELECT,
					options: accessParents.map(station => ({
						label: station.name,
						value: station.id,
					})),
					name: "id",
					required: true,
					unique: true,
				},
				{
					name: "redundancyPoints",
					label: t("entities:redundancyPoints"),
					headerTooltipComponent: (
						<Translate
							i18nKey="hints:redundancyPointDescription"
							components={[
								// Anchor content is added by the translation
								// eslint-disable-next-line jsx-a11y/anchor-has-content
								<a href="mailto:support@carrot.tech" className="underline text-white" />,
							]}
						/>
					),
					fieldType: FormFieldType.READ_ONLY,
					readOnlyContent: rowIndex => {
						const accessParent = getValues("stations")[rowIndex]
						return <ReadOnlyRedundancyPoints accessParent={accessParent} />
					},
				},
			] as FormColumn[],
		[accessParents, getValues, t]
	)

	return (
		<ModalContainer
			title="actions:editAllocation"
			onCancel={hideModal}
			onConfirm={onConfirm}
			onConfirmLoading={isSaving}
			showDiscardModal={isDirty}
			className="w-1/2 lg:max-w-3xl"
		>
			<div className="mb-10">
				<div className="bg-grey1 p-5 my-4">
					<h2 className="mb-2 font-dmSans text-base font-medium">
						{t("formLabels:changeAllocationStations")}
					</h2>
					<p className="font-dmSans text-sm font-normal text-black">
						<Translate
							i18nKey={"hints:changeAllocationStations"}
							values={{
								link: t("sidebarLabels:manageInfrastructure"),
							}}
							components={{
								p: <p className="text-sm" />,
								a: (
									// eslint-disable-next-line jsx-a11y/anchor-has-content
									<a
										href="/infrastructure/manage"
										style={{ textDecoration: "underline" }}
										target="_blank"
										rel="noreferrer"
									/>
								),
							}}
						/>
					</p>
				</div>
				{isError && <ErrorNotice />}
				{isLoadingAccessParents ? (
					<div className="flex justify-center">
						<LoadingIndicator />
					</div>
				) : (
					<GridForm<Station[]>
						enabled={!isSaving}
						formHandler={formHandler}
						columns={columns}
						enableDeleteColumn
						enableAddRowButton
						allowDeletingLastRow={true}
						withColSpacing
						selectInputMenuPortalTarget={modalRef.current}
						emptyMessage={t("systemMessages:noAccessParentsAdded")}
					/>
				)}
			</div>
		</ModalContainer>
	)
}
