import { TemplateColumn, ValidateFunctionProps } from "components/ImportComponents/types"
import { CATEGORY_KEY, CHAIN_KEY, CUSTOMER_REFERENCE_KEY, CUSTOMER_NAME_KEY } from "./constants"
import {
	anythingAllowed,
	contactEmailValidation,
	linkedValueValidation,
	numberValidation,
	phoneNumberValidation,
	requiredFieldValidation,
	validate,
	valueInArrayValidation,
	carrotAccessParentIDValidation,
} from "Utils/importCommon/validationFunctions"
import { IInputOption } from "components/GenericComponents/input"
import { linkStoreNameToChain } from "Utils/linkStoreNameToChain"
import { FetchedTenantTypes } from "Utils/api/sanity/types"
import { translate } from "translations/functions/hook"
import {
	MWM_SPACE_NAME,
	REAL_ESTATE_SPACE_NAME,
	dropdownValueTransform,
	filterBySpaceName,
} from "Utils/importCommon/common"
import {
	CUSTOMER_ADDRESS_KEY,
	CUSTOMER_POSTAL_CODE_KEY,
	CUSTOMER_POSTAL_REGION_KEY,
} from "constants/general"
import { addCountryCodeToPhone } from "Utils/phoneCountryCode"
import { CountryCode } from "libphonenumber-js"
import { MINIMAL_ACCESS_PARENT } from "admin-client-server/src/coreApi/accessParents/types"

export const getCustomerColumns = ({
	chains,
	tenantCategories,
	isMWM,
}: {
	chains?: any[]
	tenantCategories?: FetchedTenantTypes
	isMWM: boolean
}): TemplateColumn[] =>
	[
		{
			key: CUSTOMER_NAME_KEY,
			title: "exportLabels:nameOfCustomer",
			hasLinkedChild: true,
			isRequired: true,
			generatingDependents: [CHAIN_KEY, CATEGORY_KEY],
			uniqueInColumn: true,
			validate: ({ value }: ValidateFunctionProps) =>
				validate([requiredFieldValidation], { value }),
			transformValue: (value: string) => value.toString(),
			contextName: REAL_ESTATE_SPACE_NAME,
		},
		{
			key: CUSTOMER_REFERENCE_KEY,
			title: "entities:customerReference",
			hasLinkedChild: true,
			isRequired: true,
			uniqueInColumn: true,
			validate: ({ value }: ValidateFunctionProps) =>
				validate([requiredFieldValidation], { value }),
			transformValue: (value: string) => value.toString(),
			contextName: MWM_SPACE_NAME,
		},
		{
			key: CHAIN_KEY,
			title: "entities:chain",
			isGenerated: true,
			generatingDependents: [CATEGORY_KEY],
			isDropdown: true,
			dropdownOptions: [
				{
					option: "",
					value: "",
				},
				...(chains?.map(({ id: value, name: option }) => ({ option, value }) as IInputOption) ||
					[]),
			],
			getGeneratedValue: (storeName: any): string => {
				let chainId = ""

				if (storeName) {
					const chainName = linkStoreNameToChain(storeName, chains?.map(el => el.name) || [])

					chainId = chains?.find(el => el.name === chainName)?.id || ""
				}

				return chainId
			},
			transformValue: dropdownValueTransform,
			validate: ({ value }: ValidateFunctionProps) =>
				validate([valueInArrayValidation], {
					value,
					arrayOfValues: chains,
				}),
			contextName: REAL_ESTATE_SPACE_NAME,
		},
		{
			key: CATEGORY_KEY,
			title: "formLabels:category",
			isGenerated: true,
			isDropdown: true,
			dropdownOptions: [
				{
					option: "",
					value: "",
				},
				...(tenantCategories || []).map(el => ({
					option: el.name,
					value: el.id,
				})),
			],
			getGeneratedValue: (dependent: string): string => {
				const isChainId = chains?.find(el => el.id === dependent)

				let chain

				if (isChainId) {
					chain = chains?.find(el => el.id === dependent)
				} else {
					const chainName = linkStoreNameToChain(dependent, chains?.map(el => el.name) || [])

					chain = chains?.find(el => el.name === chainName)
				}

				if (chain) {
					return chain?.categoryId
				}

				return ""
			},
			transformValue: dropdownValueTransform,
			validate: ({ value }: ValidateFunctionProps) =>
				validate([valueInArrayValidation], {
					value,
					arrayOfValues: tenantCategories,
				}),
			contextName: REAL_ESTATE_SPACE_NAME,
		},
		{
			key: "area",
			title: "importLabels:area",
			isRequired: true,
			validate: ({ value }: ValidateFunctionProps) =>
				validate([requiredFieldValidation, numberValidation], { value }),
			contextName: REAL_ESTATE_SPACE_NAME,
		},
		{
			key: "cleanerPermissions",
			title: "exportLabels:cleanerPermissions",
			isRequired: true,
			isDropdown: true,
			dropdownOptions: [
				{ option: translate("common:yes"), value: "yes" },
				{ option: translate("common:no"), value: "no" },
			],
			transformValue: dropdownValueTransform,
			validate: ({ value }: ValidateFunctionProps) =>
				validate([requiredFieldValidation, valueInArrayValidation], {
					value,
					arrayOfValues: [{ id: "yes" }, { id: "no" }],
				}),
			contextName: REAL_ESTATE_SPACE_NAME,
		},
		{
			key: "customerType",
			title: "statisticsLabels:customerType",
			isRequired: true,
			isDropdown: true,
			dropdownOptions: [
				{ option: translate("entities:customerTypeBUSINESS"), value: "business" },
				{ option: translate("entities:customerTypeHOUSEHOLD"), value: "household" },
			],
			transformValue: dropdownValueTransform,
			validate: ({ value }: ValidateFunctionProps) =>
				validate([requiredFieldValidation, valueInArrayValidation], {
					value,
					arrayOfValues: [{ id: "business" }, { id: "household" }],
				}),
			contextName: MWM_SPACE_NAME,
		},
		{
			key: CUSTOMER_ADDRESS_KEY,
			title: "formLabels:address",
			validate: anythingAllowed,
			contextName: MWM_SPACE_NAME,
		},
		{
			key: CUSTOMER_POSTAL_CODE_KEY,
			title: "formLabels:postalCode",
			validate: anythingAllowed,
			contextName: MWM_SPACE_NAME,
		},
		{
			key: CUSTOMER_POSTAL_REGION_KEY,
			title: "formLabels:postalRegion",
			validate: anythingAllowed,
			contextName: MWM_SPACE_NAME,
		},
	].filter(c => filterBySpaceName(c, isMWM))

export const getContactsColumns = (countryCode: CountryCode = "NO"): TemplateColumn[] => [
	{
		key: CUSTOMER_NAME_KEY,
		title: "exportLabels:nameOfCustomer",
		hasLinkedParent: true,
		isRequired: true,
		transformValue: (value: string) => value.toString(),
		validate: ({ value, linkingValues }: ValidateFunctionProps) =>
			validate([requiredFieldValidation, linkedValueValidation], { value, linkingValues }),
	},
	{
		key: "contactPersonName",
		title: "exportLabels:nameOfContactPerson",
		isRequired: true,
		validate: ({ value }: ValidateFunctionProps) => validate([requiredFieldValidation], { value }),
	},
	{
		key: "contactPersonEmail",
		title: "exportLabels:emailOfContactPerson",
		isRequired: true,
		validate: ({ value }: ValidateFunctionProps) =>
			validate([requiredFieldValidation, contactEmailValidation], { value }),
	},
	{
		key: "contactPersonPhone",
		title: "exportLabels:phoneOfContactPerson",
		validate: ({ value }: ValidateFunctionProps) => validate([phoneNumberValidation], { value }),
		transformValue: (value: string) => addCountryCodeToPhone(value, countryCode),
	},
]

export const getStationsColumns = (
	allAccessParents: MINIMAL_ACCESS_PARENT[] = []
): TemplateColumn[] => [
	{
		key: CUSTOMER_REFERENCE_KEY,
		title: "entities:customerReference",
		hasLinkedParent: true,
		isRequired: true,
		transformValue: (value: string) => value.toString(),
		validate: ({ value, linkingValues }: ValidateFunctionProps) =>
			validate([requiredFieldValidation, linkedValueValidation], { value, linkingValues }),
	},
	{
		key: "stationId",
		title: "drawerLabels:carrotAccessParentID",
		isRequired: true,
		validate: ({ value }: ValidateFunctionProps) =>
			validate([requiredFieldValidation, carrotAccessParentIDValidation], {
				value,
				arrayOfValues: allAccessParents,
			}),
	},
]
