import LoadingTable from "components/LoadingTable"
import { Card, CardComponentFilterLocation } from "components/pageCards/card"
import {
	createTimeFilters,
	getTenantCategoryOptions,
} from "components/pageCards/filterSort/filterCreators"
import { FilterTimeFrameValue } from "admin-client-server/src/config-api"
import { getTimeFrame } from "components/pageCards/filterSort/filterTimeFrame"
import { useMemo, useState } from "react"
import { useCommonEntitiesStore } from "States/commonEntities"
import { useTerminalsStateRE } from "States/Terminals"
import { useTrans } from "translations"
import { useDataWareHouse } from "Utils/api/datawarehouse/request"
import { CustomersResponse } from "Utils/api/datawarehouse/responseTypes"
import { Table } from "components/Table"
import { inactiveTenantsColumns } from "Utils/tableColumns/inactiveTenantsColumns"
import { ActiveOptions } from "components/pageCards/filterSort/FilterSortContext"
import { FilterSort, FilterSortMenuType } from "components/pageCards/filterSort/types"
import { OPTION_ALL, hasNonAllOptionActive } from "components/pageCards/filterSort/constants"
import { MediaQuery, useMediaQuery } from "Utils/useMediaQuery"

const defaultTimeframe = FilterTimeFrameValue.Last30Days

export const InactiveTenantsCard = () => {
	const { t } = useTrans()
	const [{ timeframe: [selectedTimeframe] = [], tenantCategory }, setActiveOptions] = useState<
		ActiveOptions<"timeframe" | "tenantCategory">
	>({} as any)
	const matches = useMediaQuery(MediaQuery.ExtraSmall)

	const { startTimeISO, endTimeISO } = useMemo(() => {
		return getTimeFrame((selectedTimeframe?.value as FilterTimeFrameValue) ?? defaultTimeframe)
	}, [selectedTimeframe])

	const { tenantCategories } = useCommonEntitiesStore()

	const { currentTerminal } = useTerminalsStateRE()

	const { data: currentPeriod, isLoading: isCurrentDataLoading } = useDataWareHouse({
		endpoint: "terminal/customers",
		pathParams: { id: currentTerminal.id },
		filters: {
			startTimeISO,
			endTimeISO,
			commercialCustomerCategory: hasNonAllOptionActive(tenantCategory)
				? tenantCategory[0].value
				: undefined,
		},
	})

	const { data: sinceBeginningData, isLoading: isSinceBeginningDataLoading } = useDataWareHouse({
		endpoint: "terminal/customers",
		pathParams: { id: currentTerminal.id },
		filters: { ...getTimeFrame(FilterTimeFrameValue.SinceBeginning) },
	})

	const getRegistrationsForCustomer = (data: CustomersResponse | undefined, customerId: any) => {
		return (
			data?.customers.find((el: { customerId: any }) => el.customerId === customerId)
				?.totalOccurrences ?? 0
		)
	}

	const totalRegistrationsSinceBeginning = (customerId: string) => {
		return getRegistrationsForCustomer(sinceBeginningData, customerId)
	}

	const inactiveTenants =
		currentPeriod?.customers.filter(el => (el.totalWasteWeight.quantity ?? 0) === 0) ?? []

	const filters: FilterSort[] = [
		createTimeFilters({ id: "timeframe", defaultValue: defaultTimeframe }, [
			FilterTimeFrameValue.Today,
			FilterTimeFrameValue.LastWeek,
			FilterTimeFrameValue.Last30Days,
			FilterTimeFrameValue.YearToDate,
			FilterTimeFrameValue.Last6FullMonths,
			FilterTimeFrameValue.LastFullYear,
		]),
		{
			options: getTenantCategoryOptions(tenantCategories),
			title: "statisticsLabels:tenantCategory",
			id: "tenantCategory",
			type: "filter",
			menuType: FilterSortMenuType.Select,
			defaultValue: OPTION_ALL,
		},
	]

	const isLoading = isCurrentDataLoading || isSinceBeginningDataLoading

	const dataWithTotalRegistrations = inactiveTenants.map(el => ({
		...el,
		previousRegistrations: t(
			totalRegistrationsSinceBeginning(el.customerId) ? "common:yes" : "common:no"
		),
	}))

	return (
		<Card
			title={"statisticsLabels:inactiveTenants"}
			filters={filters}
			onFilterOptionChange={setActiveOptions}
			filterLocation={CardComponentFilterLocation.RIGHT}
			className="xl:col-start-2"
		>
			<p className="mb-8">{t("hints:inactiveTenantsInfo")}</p>

			{isLoading ? (
				<LoadingTable rows={10} />
			) : (
				<Table
					columns={inactiveTenantsColumns(matches)}
					data={dataWithTotalRegistrations}
					className="max-h-96 overflow-auto"
					sortingHeader="previousRegistrations"
					emptyViewType="noInactiveTenants"
				/>
			)}
		</Card>
	)
}
