import ErrorNotice from "components/ErrorNotice"
import { FC, ReactElement } from "react"
import Skeleton from "react-loading-skeleton"
import { Card, CardComponentVariant } from "../card"

export type MultiListDrawerConfig = {
	content?: ReactElement<any, any> | null
}

export type MultiListDrawerProps = {
	isLoading?: boolean
	isError?: boolean
	children?: React.ReactNode
}

export const MultiListDrawer: FC<MultiListDrawerProps> = ({ children, isLoading, isError }) => {
	if (isLoading) return <MultiListDrawerSkeleton />
	if (isError)
		return (
			<MultiListDrawerContainer>
				<Card title="errors:error" variant={CardComponentVariant.DRAWER}>
					<ErrorNotice />
				</Card>
			</MultiListDrawerContainer>
		)
	return <MultiListDrawerContainer>{children}</MultiListDrawerContainer>
}

export const MultiListDrawerSkeleton: FC<{}> = () => {
	return (
		<MultiListDrawerContainer>
			<Skeleton count={3} borderRadius={0} height={"200px"} />
		</MultiListDrawerContainer>
	)
}

const MultiListDrawerContainer: FC<{ children?: React.ReactNode }> = ({ children }) => {
	return (
		<div className="flex h-full w-full max-w-2xl flex-col gap-[inherit] overflow-auto">
			{children}
		</div>
	)
}
