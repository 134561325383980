import { Cell, Row } from "@tanstack/react-table"
import { TableContext } from "components/TableWithSelect/TableContext"
import React, { useContext, useMemo } from "react"
import { twMerge } from "tailwind-merge"
import { TableCell } from "./TableCell"

type TableRowProps = {
	rowKey: number
	row: Row<any>
}

export const TableRow: React.FC<TableRowProps> = ({ rowKey, row }) => {
	const { selectedIds, onRowClick } = useContext(TableContext)

	const isSelected = useMemo(() => selectedIds.includes(row.original.id), [row, selectedIds])

	return (
		<tr
			key={rowKey}
			className={twMerge(
				`bg-white group h-[48px] border-b border-grey2 text-sm last:border-b-0 cursor-pointer hover:bg-hoverColor`
			)}
			onClick={() => onRowClick(row)}
		>
			{row.getVisibleCells().map((cell: Cell<any, any>, i) => (
				<TableCell cell={cell} key={`${cell.id}_${i}`} isSelected={isSelected} />
			))}
		</tr>
	)
}
