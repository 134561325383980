import { ChangeEvent, useCallback } from "react"
import { useTrans } from "translations"

export const getIsValidMaxWeight = (maxWeightPerThrow?: number | null) => {
	return maxWeightPerThrow != null ? maxWeightPerThrow > 0 : true
}

type Props = {
	value?: number | null
	error: boolean
	onChange: (value: number | null) => void
	label: string
	placeholder: string
}

export const MaxWeightPerThrowInput: React.FC<Props> = ({
	value,
	onChange,
	error,
	label,
	placeholder,
}) => {
	const { t } = useTrans()

	const onMaxWeightPerThrowChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			e.preventDefault()
			onChange(e.target.value ? parseFloat(e.target.value) : null)
		},
		[onChange]
	)
	return (
		<>
			<label className="select-none">{label}</label>
			<input
				className="flex mt-1 mb-4 px-3 py-2 w-full border border-black text-sm [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
				type="number"
				value={value ?? ""}
				onChange={onMaxWeightPerThrowChange}
				placeholder={placeholder}
				onKeyDown={e => (e.key === "e" || e.key === "-") && e.preventDefault()}
				onWheel={e => e.currentTarget.blur()}
				data-1p-ignore
			/>
			{error && (
				<p className="text-carrotRed text-sm mb-4 -mt-3">{t("errors:invalidMaxWeightPerThrow")}</p>
			)}
		</>
	)
}
