import React from "react"

export const Checkbox: React.FC<{ className?: string }> = ({ className }) => (
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" className={className}>
		<rect x="0.5" y="0.5" width="17" height="17" fill="black" />
		<path
			d="M13.9268 4.93134L7.49991 11.7371L4.07253 8.10803L3.99982 8.03105L3.92712 8.10803L2.9273 9.16668L2.86245 9.23534L2.9273 9.304L7.42721 14.0687L7.49991 14.1456L7.57261 14.0687L15.0727 6.12731L15.1376 6.05862L15.0727 5.98996L14.0721 4.93131L13.9994 4.85438L13.9268 4.93134Z"
			fill="white"
			stroke="white"
			strokeWidth="0.2"
		/>
		<rect x="0.5" y="0.5" width="17" height="17" stroke="black" />
	</svg>
)

export default Checkbox
