import { Cell, Row, Table } from "@tanstack/react-table"
import classNames from "classnames"
import { get } from "lodash"
import { useCommonEntitiesStore } from "States/commonEntities"
import { TranslateFunction } from "translations/types"
import { formatNumberForTable } from "Utils/formatFunctions"
import { wasteTypeConfigStore } from "pages/configuration/useConfigService"

export type TableCellRenderFunctionsType = {
	[key: string]: (props: {
		cell: Cell<any, unknown>
		table: Table<any>
		row: Row<any>
		data: { [k: string]: any }[]
		t: TranslateFunction
	}) => string | number | JSX.Element | null
}

export const weightRender = ({ cell }: { cell: Cell<any, unknown> }) => {
	const weight = cell.getValue() as string | number | null
	return weight ? formatNumberForTable(weight) : null
}

export const weightRenderWithZero = ({ cell }: { cell: Cell<any, unknown> }) => {
	const weight = cell.getValue() as string | number | null
	return weight ? formatNumberForTable(weight) : 0
}

export const wasteTypeRender = ({
	cell,
	t,
}: {
	cell: Cell<any, unknown>
	t: TranslateFunction
}) => {
	const wasteCode = cell.getValue()
	const { wasteTypes } = useCommonEntitiesStore.getState()
	const { wasteTypeConfig } = wasteTypeConfigStore.getState()
	const wasteType =
		wasteTypeConfig.find(wtc => wtc.wasteTypeCode === wasteCode)?.name ||
		wasteTypes?.find(({ id }) => id === wasteCode)?.name ||
		t("common:unknown")
	return `${wasteType} (${wasteCode})`
}

export const cellRenderFunctions: TableCellRenderFunctionsType = {
	timestamp: ({ cell }) => {
		const ts = cell.getValue()
		if (typeof ts !== "string") return null
		return ts
	},
	"event.result.reason.code": ({ row: { original: row }, t }) => {
		const rejectReason = get(row, "result.reason.code", null) as string
		const rejectReasonCode = rejectReason?.replace(/\[/, "")?.replace(/\].*/, "")
		return row.result.code === "ACCEPTED"
			? t("genericLabels:accepted")
			: t(rejectReasonCode ? `rejectReasons:${rejectReasonCode}` : "common:unknown")
	},
	"result.code": ({ cell, t }) => {
		const result = cell.getValue()
		return (
			<div className="flex items-center">
				<div
					className={classNames(
						"h-[15px] w-[15px] rounded-half border mr-2",
						result === "ACCEPTED" ? "border-carrotGreen bg-carrotGreen" : "border-tomato bg-tomato"
					)}
				/>
				{t(`genericLabels:${result === "ACCEPTED" ? "success" : "fail"}`)}
			</div>
		)
	},
	"wasteType.code": wasteTypeRender,
	wasteType: wasteTypeRender,
	type: ({ cell, t }) => {
		const eventTypeCode = cell.getValue()
		const eventType = [t(`eventTypes:${eventTypeCode}`)].map(eventType =>
			eventType.includes("eventTypes:") ? t("common:unknown") : eventType
		)[0]
		return eventType
	},
	"weight.quantity": weightRenderWithZero,
	"weight.unit": ({ cell }) => {
		const unit = cell.getValue() as string | number | null
		return typeof unit === "string" ? unit.toLocaleLowerCase() : (unit ?? null)
	},
	commercialRealEstateCategory: ({ cell, t }) => {
		return cell.getValue<string>() ? t(`entities:${cell.getValue<string>()}`) : t("common:unknown")
	},
	category: ({ cell, t }) => {
		const { tenantCategories } = useCommonEntitiesStore.getState()

		const category = cell.getValue<{ id: string }>()
		if (!category) return <div className="text-carrotRed">{t("genericLabels:unassigned")}</div>
		return tenantCategories?.find(c => c.id === category.id)?.name || ""
	},
}
