import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { isEmpty } from "lodash"
import { useForm } from "react-hook-form"
import { useNavigate } from "Utils/useNavigate"
import { useTerminalsStateRE } from "States/Terminals"
import { WasteRoomForm, WasteRoomFormData } from "../manage/wasteRoomForm"
import { useSlackNotifications } from "api/hooks/useSlackNotifications"
import { useMultiSelectContext } from "components/pageCards/multiListTable/multiSelectContext"
import { useGlobalAlert } from "States/globalAlert"
import { getInfrastructureUrl } from "Utils/getInfrastructureUrl"
import { useAccessParentsWithPoints } from "../manage/useAccessParentsWithPoints"
import { trpc } from "Utils/trpc"

export const AddWasteRoomModal: React.FC = () => {
	const { hideModal } = useModal()
	const navigate = useNavigate()
	const { isLoading: isLoadingWasteRooms, refetch } = useAccessParentsWithPoints()
	const { setSelectedContainers } = useMultiSelectContext()
	const { setGlobalAlert } = useGlobalAlert()

	const {
		currentTerminal: { id: terminalId },
	} = useTerminalsStateRE()
	const { sendNewAccessParentSlackNotification } = useSlackNotifications()

	const formHandler = useForm<WasteRoomFormData>()

	const {
		formState: { errors, isValid, isDirty },
		handleSubmit,
	} = formHandler

	const { mutateAsync, isLoading } = trpc.accessParents.create.useMutation({
		onSuccess: async modifiedPoint => {
			await refetch()

			setGlobalAlert({
				type: "success",
				message: "systemMessages:accessParentAdded",
			})
			sendNewAccessParentSlackNotification(modifiedPoint.name, modifiedPoint.id)
			setSelectedContainers([])

			const url = getInfrastructureUrl({
				terminalId,
				accessParentId: modifiedPoint.id,
			})
			navigate(url)
			hideModal()
		},
		onError: error => {
			setGlobalAlert({
				type: "error",
				message: "errors:failedSave",
				instructions: error.message,
			})
		},
	})

	const onSubmit = handleSubmit(roomFormData => {
		if (isLoading) return
		const { name } = roomFormData

		mutateAsync({
			name,
			parentId: terminalId!,
			status: "ACTIVE",
		})
	})

	return (
		<ModalContainer
			title="actions:newAccessParent"
			onConfirmText={"actions:save"}
			onConfirm={() => onSubmit()}
			onCancel={hideModal}
			onConfirmLoading={isLoadingWasteRooms}
			onConfirmDisabled={!isEmpty(errors) || !isValid}
			className="w-3/4 sm:w-1/2 lg:w-1/3"
			showDiscardModal={isDirty}
		>
			<WasteRoomForm {...{ formHandler }} />
		</ModalContainer>
	)
}
