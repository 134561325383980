import { twMerge } from "tailwind-merge"
import { useTrans } from "translations"

const CLIPPED_TILE_STYLE =
	"[clip-path:polygon(20px_0%,0%_20px,15%_calc(100%-20px),50%_95%,85%_calc(100%-20px),100%_20px,calc(100%-20px)_0%);]"

type Props = {
	id: string
	icon: string
	name: string
	isRecommended?: boolean
}

export const WasteTypeItem: React.FC<Props> = ({
	id,
	icon,
	name,
	isRecommended = false,
}: Props) => {
	const { t } = useTrans()
	return (
		<div
			key={id}
			className={twMerge(
				"w-20 relative",
				name.includes("WEEE") && "hover:animate-spin-y transition-transform"
			)}
		>
			<div
				className={twMerge(
					"mb-2 h-10 w-10 px-5 py-8 w-fit h-fit",
					isRecommended ? "bg-grey1" : "bg-lime",
					CLIPPED_TILE_STYLE
				)}
			>
				<div
					className={twMerge("mb-2 h-10 w-10", isRecommended && "opacity-50")}
					style={{
						backgroundImage: `url(${icon})`,
						backgroundSize: "contain",
						backgroundRepeat: "no-repeat",
						backgroundPosition: "center center",
					}}
				/>
			</div>

			{isRecommended && (
				<div className="absolute top-20 left-0 h-4 w-20 bg-lime text-2xs text-center">
					{t("genericLabels:recommended")}
				</div>
			)}

			<div
				className={twMerge(
					"font-dmSans text-xs leading-4 text-black font-medium break-words text-center",
					isRecommended && "opacity-50"
				)}
			>
				{name}
			</div>
		</div>
	)
}
