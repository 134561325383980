import { Outlet, useLocation, useParams } from "react-router-dom"
import { useEffect } from "react"
import { useSelectedSite } from "api/hooks/useSelectedSite"
import { useNavigate } from "Utils/useNavigate"

export default function ClientWrapper() {
	const { client } = useParams()
	const { selectedSite } = useSelectedSite()
	const navigate = useNavigate()
	const location = useLocation()

	useEffect(() => {
		if (client) {
			return
		}

		if (selectedSite) {
			// Redirect to client-specific route
			navigate(location.pathname, { replace: true })
		}
	}, [client, navigate, location, selectedSite])

	return <Outlet />
}
