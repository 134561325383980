import FormInput from "components/Form/FormInput"
import PhoneInput from "components/Form/PhoneInput"
import { FC } from "react"
import { UseFormReturn } from "react-hook-form"
import { useTrans } from "translations"
import { EMAIL_REGEX } from "Utils/isValidEmail"
import { isValidPhoneNumberAllowEmpty } from "Utils/isValidPhoneNumber"
import { CreateTenantFormValues } from "../../types"

interface TenantContactFormProps {
	formHandler: UseFormReturn<CreateTenantFormValues>
	index: number
}

export const TenantContactInfoForm: FC<TenantContactFormProps> = ({ formHandler, index }) => {
	const { t } = useTrans()

	const {
		register,
		control,
		formState: { errors },
		watch,
	} = formHandler

	const watchName = watch(`contactPeople.${index}.name`)
	const watchEmail = watch(`contactPeople.${index}.email`)
	const watchPhone = watch(`contactPeople.${index}.phone`)

	return (
		<div className="mb-2 flex flex-1 flex-col space-y-2 2xl:flex-row 2xl:space-x-2 2xl:space-y-0">
			<FormInput
				name={`contactPeople.${index}.name`}
				label={t("formLabels:name")}
				register={register}
				error={errors.contactPeople?.[index]?.name}
				validationFunction={value => {
					if ((value || watchEmail || watchPhone) && !value) {
						return t("errors:requiredField", { name: t("formLabels:name") })
					}
					return true
				}}
			/>
			<FormInput
				name={`contactPeople.${index}.email`}
				label={t("formLabels:email")}
				regexPattern={EMAIL_REGEX}
				register={register}
				error={errors.contactPeople?.[index]?.email}
				patternError="errors:mustBeValidEmail"
				validationFunction={value => {
					if ((value || watchName || watchPhone) && !value) {
						return t("errors:requiredField", { name: t("formLabels:email") })
					}
					return true
				}}
			/>
			<PhoneInput
				name={`contactPeople.${index}.phone`}
				label={t("formLabels:phoneNumber")}
				validationFunction={isValidPhoneNumberAllowEmpty}
				minLength={7}
				control={control}
				error={errors.contactPeople?.[index]?.phone}
				patternError="errors:mustBeValidPhoneNumber"
			/>
		</div>
	)
}
