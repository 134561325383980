import SelectInput from "components/Form/SelectInput"
import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { useForm } from "react-hook-form"
import { useTrans } from "translations"

type Props = {
	options?: { value: string; label: string }[]
	onSave: (newWasteCategory: string) => void
	onConfirmText?: string
}

export const AddWasteCategoryModal: React.FC<Props> = ({
	options,
	onSave,
	onConfirmText = "actions:save",
}) => {
	const { t } = useTrans()
	const { hideModal, ref: modalRef } = useModal()

	const formHandler = useForm()
	const { control, formState, handleSubmit } = formHandler

	const onSubmit = handleSubmit(eventFormData => {
		const { wasteCategory } = eventFormData
		onSave(wasteCategory)
		hideModal()
	})

	return (
		<ModalContainer
			title="formLabels:addWasteCategory"
			onConfirmText={onConfirmText}
			onConfirm={() => onSubmit()}
			onConfirmDisabled={!formState.isValid}
			onCancel={hideModal}
			className="w-3/4 sm:w-1/2 lg:w-1/3"
			showDiscardModal={formState.isDirty}
		>
			<div className="mb-6 flex flex-col gap-y-5">
				<SelectInput
					label={t("formLabels:selectWasteCategory")}
					placeholder={t("formLabels:selectWasteCategory")}
					name="wasteCategory"
					control={control}
					options={options}
					menuPortalTarget={modalRef.current}
					required
				/>
			</div>
		</ModalContainer>
	)
}
