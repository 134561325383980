import { IImportContext } from "components/ImportComponents/ImportContext"
import {
	CONTACT_KEY,
	CUSTOMER_REFERENCE_KEY,
	STATION_KEY,
	CUSTOMER_KEY,
	CUSTOMER_NAME_KEY,
} from "./constants"
import { ERROR_STATE, FINALIZED_STATE } from "pages/infrastructure/import/addToSystem"
import { FetchedTenantTypes } from "Utils/api/sanity/types"
import {
	CreateMWMCustomerWithAccessParents,
	CreateRETenantWithAccessParents,
	CreateCustomerWithAccessParents,
	BulkCreateCustomerResponse,
} from "admin-client-server/src/coreApi/customers/create/models"

const CLEANER_PERMISSION_MAPPING: { [k: string]: string } = {
	yes: "true",
	no: "false",
}

type AddToSystemProps = {
	context: IImportContext
	accessParentIds: string[]
	tenantCategories: FetchedTenantTypes | null
	bulkCreateCustomers: (input: {
		customers: CreateCustomerWithAccessParents[]
	}) => Promise<BulkCreateCustomerResponse[]>
	refetchCustomers: () => Promise<any>
	isMWM: boolean
}

const getCustomersToUpload = ({
	context: { validationState },
	accessParentIds,
	isMWM,
}: AddToSystemProps): CreateCustomerWithAccessParents[] => {
	const customerPropertyData = validationState[CUSTOMER_KEY]!

	if (isMWM) {
		const customerReferenceRows = customerPropertyData[CUSTOMER_REFERENCE_KEY]!
		const stationsPropertyData = validationState[STATION_KEY]!
		const { customerReference, stationId } = stationsPropertyData
		const { customerType, geoLocationName, postalCode, postal } = customerPropertyData

		return customerReferenceRows.map((referenceCell, i: number) => {
			const stationIds: string[] = customerReference.reduce(
				(acc: any, customerReferenceCell, i) => {
					if (customerReferenceCell.value === referenceCell.value) {
						acc.push(stationId[i].value)
					}

					return acc
				},
				[]
			)

			const mwmCustomer: CreateMWMCustomerWithAccessParents = {
				name: referenceCell.value,
				type: customerType[i].value.toUpperCase(),
				address: geoLocationName?.[i]?.value?.toString() || "",
				postalCode: postalCode?.[i]?.value?.toString() || "",
				postalRegion: postal?.[i]?.value?.toString() || "",
				accessParents: stationIds.map(id => ({ id })),
			}

			return mwmCustomer
		})
	} else {
		const customerNameRows = customerPropertyData[CUSTOMER_NAME_KEY]
		const contactsPropertyData = validationState[CONTACT_KEY]!

		const { chain, category, area, cleanerPermissions } = customerPropertyData
		const { customerName, contactPersonName, contactPersonEmail, contactPersonPhone } =
			contactsPropertyData

		return customerNameRows.map((nameCell, i: number) => {
			const contacts = customerName.reduce((acc: any, customerNameCell, i) => {
				if (customerNameCell.value === nameCell.value) {
					acc.push({
						name: contactPersonName[i].value,
						email: contactPersonEmail[i].value,
						phone: contactPersonPhone?.[i].value?.toString() || "",
					})
				}

				return acc
			}, [])

			const firstContact = contacts[0]
			const reCustomer: CreateRETenantWithAccessParents = {
				name: nameCell.value,
				type: "BUSINESS",
				categoryId: category[i].value?.toString(),
				area: area[i].value.toString(),
				contactEmail: firstContact?.email,
				contactMobile: firstContact?.phone,
				contactPerson: firstContact?.name,
				throwDelegate: CLEANER_PERMISSION_MAPPING[cleanerPermissions[i].value] || "false",
				accessParents: accessParentIds.map(id => ({ id })),
			}

			if (contacts.length > 1) {
				reCustomer.contactPeople = JSON.stringify(contacts.slice(1))
			}

			if (chain[i].value) {
				reCustomer.parentChainId = chain[i].value
			}

			return reCustomer
		})
	}
}

const addCustomers = async (props: AddToSystemProps) => {
	const { setLoadingState, fullReset } = props.context

	const customers = getCustomersToUpload(props)

	setLoadingState({
		importing: "importLabels:importingCustomers",
		finalized: false,
	})
	props
		.bulkCreateCustomers({ customers })
		.then(() => {
			props.refetchCustomers().then(() => {
				setLoadingState(FINALIZED_STATE)
				fullReset()
			})
		})
		.catch(() => {
			setLoadingState(ERROR_STATE)
		})
}

export const addToSystem = (props: AddToSystemProps) => {
	const { selectedTemplate } = props.context

	switch (selectedTemplate?.key) {
		case "customers":
			addCustomers(props)
			break

		default:
			break
	}
}
