import { Translate } from "translations"

export const NoTerminals = () => {
	return (
		<div className="w-full h-full z-10 flex items-center justify-center bg-grey1">
			<h1 className="text-center font-signifier text-3xl">
				<Translate i18nKey={"errors:noTerminals"} components={[<span className="text-carrot" />]} />
			</h1>
		</div>
	)
}

export default NoTerminals
