import Magnifier from "Icons/Magnifier"
import classNames from "classnames"
import React, { useCallback, useMemo, useState } from "react"
import { useTrans } from "translations"
import { useFilterSort } from "./FilterSortContext"
import { FilterSort, Option } from "./types"

type FilterSortSelectProps = {
	filterSort: FilterSort
	close: () => void
}

const DEFAULT_LIMIT_FOR_SEARCH = 6

export const FilterSortSelect: React.FC<FilterSortSelectProps> = ({ filterSort, close }) => {
	const { t } = useTrans()
	const [searchValue, setSearchValue] = useState("")
	const { activeOptions, onOptionSelect } = useFilterSort()
	const { options, isMultiselect, searchEnabled = true } = filterSort

	const filteredOptions = useMemo(
		() =>
			options
				? options.filter(({ option: _option, translate }) => {
						const option = (translate ? t(_option) : _option)?.toLocaleLowerCase()
						return option?.includes(searchValue.toLocaleLowerCase())
					})
				: options,
		[options, searchValue, t]
	)

	const isOptionActive = useCallback(
		(value: string) => {
			return activeOptions[filterSort.id]?.some(option => option.value === value)
		},
		[activeOptions, filterSort.id]
	)

	const handleToggleOption = useCallback(
		(filterSort: FilterSort, option: Option) => {
			onOptionSelect(filterSort, option)

			if (!isMultiselect) close()
		},
		[close, isMultiselect, onOptionSelect]
	)

	const hasSearch = useMemo(
		() =>
			filterSort.type === "filter" &&
			searchEnabled &&
			options.length >= (filterSort.minOptionsForSearch ?? DEFAULT_LIMIT_FOR_SEARCH),
		[filterSort, options, searchEnabled]
	)

	return (
		<div>
			{hasSearch && (
				<div className="flex h-7 items-center gap-2 border border-grey3 px-2 py-5 mb-3">
					<div className="h-4 w-4">
						<Magnifier className="h-full w-full" />
					</div>
					<input
						autoFocus
						className="w-full border-none bg-transparent placeholder:text-grey6"
						placeholder={t("genericLabels:searchbarPlaceholder")}
						value={searchValue}
						onChange={e => setSearchValue(e.target.value)}
						data-1p-ignore
					/>
				</div>
			)}
			<ul className="divide-y max-h-64 overflow-auto">
				{filteredOptions.map(option => (
					<li
						key={option.value}
						className={classNames(
							isOptionActive(option.value) && "bg-grey2",
							"hover:bg-grey1 active:bg-grey3"
						)}
					>
						<button
							className="p-3 w-full text-left text-sm"
							onClick={() => handleToggleOption(filterSort, option)}
						>
							{option.translate ? t(option.option) : option.option}
						</button>
					</li>
				))}
				{!filteredOptions.length && <div className="p-3">{t("errors:noResults")}</div>}
			</ul>
		</div>
	)
}
