import {
	Auth0Client,
	Auth0ClientOptions,
	GetTokenSilentlyOptions,
	LogoutOptions,
	RedirectLoginOptions,
	RedirectLoginResult,
	createAuth0Client,
} from "@auth0/auth0-spa-js"
import { Loading } from "App"
import React, { useContext, useEffect, useState } from "react"
import { useGeneralConfig } from "../States/generalConfig"
import { IAuthJwt } from "../types"
import { parseJwtToken } from "../Utils/authUtils"

interface IAuth0Context {
	isAuthenticated: boolean
	user: any
	loading: boolean
	token: string | undefined
	handleRedirectCallback(): Promise<RedirectLoginResult>
	loginWithRedirect(o: RedirectLoginOptions): Promise<void>
	getTokenSilently(o?: GetTokenSilentlyOptions): Promise<string | undefined>
	logout(o?: LogoutOptions): void
}

interface Auth0ProviderOptions {
	children: React.ReactElement
	onRedirectCallback?(result: RedirectLoginResult): void
}

const DEFAULT_REDIRECT_CALLBACK = () => {
	window.history.replaceState({}, document.title, window.location.pathname)
}

export const Auth0Context = React.createContext<IAuth0Context | null>(null)
export const useAuth0 = () => useContext(Auth0Context)
let cookieToken = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, "$1")

const Auth0ProviderImpl = ({
	children,
	onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
	...initOptions
}: Auth0ProviderOptions & Auth0ClientOptions) => {
	const [isAuthenticated, setIsAuthenticated] = useState(false)
	const [user, setUser] = useState<any>()
	const [auth0Client, setAuth0] = useState<Auth0Client>()
	const [loading, setLoading] = useState(true)
	const [token, setToken] = useState("")

	const auth0enticated = document.cookie
		.split("; ")
		.some(c => c.includes("auth0.") && c.includes("authenticated=true"))

	const { setAuthJwt } = useGeneralConfig()

	useEffect(() => {
		const initAuth0 = async () => {
			try {
				const auth0FromHook = await createAuth0Client(initOptions)
				setAuth0(auth0FromHook)

				if (window.location.search.includes("code=") && window.location.search.includes("state=")) {
					const { appState } = await auth0FromHook.handleRedirectCallback()
					onRedirectCallback(appState)
				}

				const isAuthenticated = await auth0FromHook.isAuthenticated()
				setIsAuthenticated(isAuthenticated)

				if (isAuthenticated) {
					const user = await auth0FromHook.getUser()
					setUser(user)

					const accessToken = await auth0FromHook.getTokenSilently()
					const parsedToken = parseJwtToken(accessToken) as IAuthJwt

					setAuthJwt(parsedToken)
					setToken(accessToken)
					setTokenCookie(accessToken)
				}
			} catch (error: any) {
				console.log(error)

				if (error.error === "login_required") {
					const auth0FromHook = await createAuth0Client(initOptions)
					auth0FromHook.loginWithRedirect({
						appState: {
							targetUrl: `${window.location.pathname}${window.location.search}`,
						},
					})
				}
			} finally {
				setLoading(false)
			}
		}

		initAuth0()
		// eslint-disable-next-line
	}, [])

	const handleRedirectCallback = async () => {
		setLoading(true)
		console.log("redirecting")
		const result = await auth0Client!.handleRedirectCallback()
		const user = await auth0Client!.getUser()
		setLoading(false)
		setIsAuthenticated(true)
		setUser(user)
		return result
	}

	const logout = (o?: LogoutOptions) => {
		localStorage.removeItem("selectedSite")
		expireTokenCookie()
		return auth0Client!.logout(o)
	}

	if (!auth0Client) return <Loading />

	if (cookieToken && !auth0enticated) {
		logout({
			logoutParams: {
				returnTo: window.location.origin,
			},
		})
	}

	return (
		<Auth0Context.Provider
			value={{
				isAuthenticated,
				user,
				loading,
				token,
				handleRedirectCallback,
				loginWithRedirect: (o: RedirectLoginOptions) => auth0Client!.loginWithRedirect(o),
				getTokenSilently: (o?: GetTokenSilentlyOptions) => auth0Client!.getTokenSilently(o),
				logout,
			}}
		>
			{children}
		</Auth0Context.Provider>
	)
}

function setTokenCookie(token: any) {
	const parsedToken = parseJwtToken(token)
	if (parsedToken) useGeneralConfig.getState().setAuthJwt(parsedToken)
	const now = new Date()
	const time = now.getTime()
	var expireTime = time + 1000 * 60 * 60 * 24
	now.setTime(expireTime)
	document.cookie = `token=${token};expires=${now.toUTCString()};Secure;SameSite=Strict;path=/`
}

function expireTokenCookie() {
	document.cookie = `token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;Secure;SameSite=Strict;path=/`
}

export const Auth0Provider = ({
	children,
	...options
}: Auth0ProviderOptions & Auth0ClientOptions) => {
	return <Auth0ProviderImpl {...options}>{children}</Auth0ProviderImpl>
}
