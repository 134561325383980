import { Page } from "components/Page"
import MultiListTable from "components/pageCards/multiListTable"
import { MultiListSectionConfig } from "components/pageCards/multiListTable/multiListSection"
import { Allocation, AllocationDrawer } from "./allocationDrawer"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { trpc } from "Utils/trpc"
import { useParams } from "react-router-dom"
import { useMultiListInitialState } from "components/pageCards/multiListTable/multiListState"
import { useModal } from "Contexts"
import { CreateAllocationModal } from "./createAllocationModal"
import { FilterSort, FilterSortMenuType } from "components/pageCards/filterSort/types"
import { OPTION_ALL, SORTING_KEY } from "components/pageCards/filterSort/constants"
import { ActiveOptions } from "components/pageCards/filterSort/FilterSortContext"
import { orderBy } from "lodash"
import { Cell } from "@tanstack/react-table"
import { useMultiSelectContext } from "components/pageCards/multiListTable/multiSelectContext"
import { useNavigate } from "Utils/useNavigate"

type AllocationFilterData = {
	municipality: string
	streetCode: string
}

type AllocationFilters = "municipality" | "streetCode" | typeof SORTING_KEY

const DEFAULT_SORT_BY = "geoLocationName|asc"

// Sorts the allocations by the given segment of the geoLocationCode, split by ".".
const sortBySegment =
	(index: number, direction: "asc" | "desc") => (a: Allocation, b: Allocation) => {
		const segmentA = a.geoLocationCode.split(".")[index]
		const segmentB = b.geoLocationCode.split(".")[index]
		if (direction === "asc") {
			return parseInt(segmentA) - parseInt(segmentB)
		} else {
			return parseInt(segmentB) - parseInt(segmentA)
		}
	}

export const Allocations: FC = () => {
	const { data: allAllocations, isLoading, refetch } = trpc.allocations.getAll.useQuery()
	const navigate = useNavigate()
	const { id: urlId } = useParams<{ id: string }>()
	const { showModal } = useModal()
	const [filterState, setFilterState] = useState<ActiveOptions<AllocationFilters>>(
		{} as ActiveOptions<AllocationFilters>
	)
	const [selectedAllocation, setSelectedAllocation] = useState<Allocation | undefined>(
		allAllocations?.find((allocation: Allocation) => allocation.id === urlId)
	)

	// Used for row highlighting and selection
	const { setSelectedIds } = useMultiSelectContext()

	const filteredAllocations = useMemo(() => {
		if (!filterState) return allAllocations

		const municipalityFilterValue = filterState.municipality?.[0]?.value
		const streetCodeFilterValue = filterState.streetCode?.[0]?.value
		const sortByValue = filterState?.[SORTING_KEY]?.[0]?.value ?? DEFAULT_SORT_BY
		const [sortKey, sortDirection] = sortByValue.split("|") as [string, "asc" | "desc"]
		const filtered =
			allAllocations?.filter((allocation: Allocation) => {
				const [municipality, streetCode] = allocation.geoLocationCode.split(".")
				if (
					municipalityFilterValue &&
					municipalityFilterValue !== OPTION_ALL &&
					municipalityFilterValue !== municipality
				) {
					return false
				}
				if (
					streetCodeFilterValue &&
					streetCodeFilterValue !== OPTION_ALL &&
					streetCodeFilterValue !== streetCode
				) {
					return false
				}
				return true
			}) ?? []
		if (sortKey === "municipality") {
			return filtered.sort(sortBySegment(0, sortDirection))
		}
		if (sortKey === "streetCode") {
			return filtered.sort(sortBySegment(1, sortDirection))
		}
		return orderBy(filtered, sortKey, sortDirection)
	}, [allAllocations, filterState])

	const filterOptionData: AllocationFilterData[] = useMemo(() => {
		return (
			allAllocations?.map((allocation: Allocation) => {
				const [municipality, streetCode] = allocation.geoLocationCode.split(".")
				return { municipality, streetCode }
			}) ?? []
		)
	}, [allAllocations])

	const filters: FilterSort[] = useMemo(() => {
		const uniqueMunicipalities = [
			...new Set(filterOptionData?.map(({ municipality }) => municipality)),
		].sort()
		const uniqueStreetCodes = [
			...new Set(filterOptionData?.map(({ streetCode }) => streetCode)),
		].sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))

		return [
			{
				type: "filter",
				menuType: FilterSortMenuType.Select,
				options: [
					{ option: "filterLabels:allMunicipalityNumbers", value: OPTION_ALL, translate: true },
					...uniqueMunicipalities.map(municipality => ({
						option: municipality,
						value: municipality,
					})),
				],
				title: "formLabels:municipalityNumber",
				id: "municipality",
				defaultValue: OPTION_ALL,
			},
			{
				type: "filter",
				menuType: FilterSortMenuType.Select,
				options: [
					{ option: "formLabels:allStreetCodes", value: OPTION_ALL, translate: true },
					...uniqueStreetCodes?.map(streetCode => ({
						option: streetCode,
						value: streetCode,
					})),
				],
				title: "formLabels:streetCode",
				id: "streetCode",
				defaultValue: OPTION_ALL,
			},
			{
				type: "sort",
				menuType: FilterSortMenuType.Select,
				options: [
					{
						option: "filterLabels:nameAscending",
						value: "geoLocationName|asc",
						translate: true,
					},
					{
						option: "filterLabels:nameDescending",
						value: "geoLocationName|desc",
						translate: true,
					},
					{
						option: "filterLabels:municipalityAscending",
						value: "municipality|asc",
						translate: true,
					},
					{
						option: "filterLabels:municipalityDescending",
						value: "municipality|desc",
						translate: true,
					},
					{
						option: "filterLabels:streetCodeAscending",
						value: "streetCode|asc",
						translate: true,
					},
					{
						option: "filterLabels:streetCodeDescending",
						value: "streetCode|desc",
						translate: true,
					},
				],
				title: "filterLabels:sortBy",
				id: SORTING_KEY,
				defaultValue: "geoLocationName|asc",
			},
		]
	}, [filterOptionData])

	useEffect(() => {
		if (urlId) {
			const allocation = allAllocations?.find((allocation: Allocation) => allocation.id === urlId)
			setSelectedAllocation(allocation)
			setSelectedIds(allocation ? [allocation.id] : [])
		}
	}, [urlId, allAllocations, setSelectedIds])

	const createAllocation = useCallback(() => {
		showModal(<CreateAllocationModal onSuccess={refetch} />)
	}, [showModal, refetch])

	const allocationsSection: MultiListSectionConfig<Allocation[]> = useMemo(
		() => ({
			type: "table",
			isLoading,
			filters,
			emptyViewType: "noData",
			onFilterOptionChange: setFilterState,
			button: {
				label: "actions:createAllocation",
				color: "secondary",
				onClick: createAllocation,
			},
			defaultSortingHeader: { id: "geoLocationName", desc: false },
			headers: [
				{ title: "formLabels:allocationName", key: "geoLocationName", sortable: false },
				{
					title: "formLabels:municipalityNumber",
					key: "geoLocationCode",
					renderFunction: ({ cell }: { cell: Cell<any, unknown> }) => {
						const value = cell.getValue() as string
						return value.split(".")[0]
					},
					sortable: false,
				},
				{
					title: "formLabels:streetCode",
					key: "geoLocationCode",
					renderFunction: ({ cell }: { cell: Cell<any, unknown> }) => {
						const value = cell.getValue() as string
						return value.split(".")[1]
					},
					sortable: false,
				},
			],
			onRowClick: ({ id }) => {
				setSelectedAllocation(
					allAllocations?.find((allocation: Allocation) => allocation.id === id)
				)
				setSelectedIds([`${id}`])
				navigate(`/allocations/${id}`)
			},
			data: filteredAllocations,
			drawer: {
				content: <AllocationDrawer allocation={selectedAllocation} refetch={refetch} />,
			},
			labelSingle: "drawerLabels:allocation",
			labelMultiple: "sidebarLabels:allocations",
			hasExportText: false,
			hasMultipleSelectFunctionality: false,
			showExportButton: false,
			useCheckboxes: false,
		}),
		[
			isLoading,
			filters,
			filteredAllocations,
			selectedAllocation,
			allAllocations,
			refetch,
			navigate,
			createAllocation,
			setSelectedIds,
		]
	)

	const initialState: useMultiListInitialState = {
		isDrawerOpen: selectedAllocation !== undefined || !!urlId,
	}

	return (
		<Page fullHeight title="sidebarLabels:allocations">
			<MultiListTable {...{ sections: [allocationsSection], initialState }} />
		</Page>
	)
}
