import { FilterTimeFrameValue } from "admin-client-server/src/config-api"
import { getTimeFrame } from "components/pageCards/filterSort/filterTimeFrame"
import { NumberCard } from "components/pageCards/numberCard"
import { useMemo } from "react"
import { useTerminalsStateRE } from "States/Terminals"
import { useTrans } from "translations"
import { useDataWareHouse } from "Utils/api/datawarehouse/request"

export const TenantNumberCard = () => {
	const { t } = useTrans()

	const { startTimeISO, endTimeISO } = useMemo(() => {
		return getTimeFrame(FilterTimeFrameValue.Last30Days)
	}, [])

	const { currentTerminal } = useTerminalsStateRE()

	const { data: currentPeriod, isLoading } = useDataWareHouse({
		endpoint: "terminal/customers",
		pathParams: { id: currentTerminal.id },
		filters: { startTimeISO, endTimeISO },
	})

	const registeredTenants = currentPeriod?.customers.length
	const activeTenants = currentPeriod?.customers.filter(
		el => (el.totalWasteWeight.quantity ?? 0) !== 0
	).length

	const numbers = [
		{ heading: t("statisticsLabels:registeredTenants"), data: registeredTenants ?? "-" },
		{ heading: t("statisticsLabels:activeThisMonth"), data: activeTenants ?? "-" },
	]

	return <NumberCard title="entities:customers" numbers={numbers} isLoading={isLoading} />
}
